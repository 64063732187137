import React,{useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import { Button,Col, Row, Form, Stack, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../config/Config';
import { TitleBar } from 'react-desktop/windows';
import { useDispatch } from 'react-redux';
import { fetchProjectList } from '../redux/projectReducer';
import { fetchUserList } from '../redux/userReducer';





function Login() {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    auth.signInWithEmailAndPassword(email, password).then(() => {
      dispatch(fetchUserList());
        setEmail('');
        setPassword('');
        setMessage('');
        navigate('/dashboard', { replace: true });
        
    }).catch(err => setMessage(err.message));
}

  // const handleLogin =async(e)=>{
  //   e.preventDefault();
  //  signInWithEmailAndPassword(email,password).then(()=>{
  //     setMessage('signin Succesfull. You will now automatically get redirected to Home page');
  //   }).catch(error=>setMessage(error.message));
  // };

  return (
   
    <Container fluid>
    
     <Row style={{height:745}}>
      <Col sm={3}  style={{backgroundColor:'#ffe678'}}>
      <Stack gap={5} className="d-flex align-items-center justify-content-center">
      <img src={require('../assests/sitelogo.png')} alt='Dashboard' style={{width:'70%'}}/>
     
      </Stack>
      
      </Col>
      <Col  sm={9} style={{backgroundColor:'#FFFFFF'}} className="h-100 d-inline-block">
        <p style={{fontSize:28,fontWeight:'400',color:'#0e2f41'}}>Already have an account? <span style={{fontSize:28,fontWeight:'500',color:'#0e2f41'}}>Sign In</span></p>
        <Form className='form-group' autoComplete="off" onSubmit={handleLogin}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label style={{fontSize:16,fontWeight:'500'}}>Email ID</Form.Label>
        <Form.Control onChange={(e)=>setEmail(e.target.value)} type="email" placeholder="Enter email" className='w-25'/>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label style={{fontSize:16,fontWeight:'500'}}>Enter password</Form.Label>
        <Form.Control onChange={(e)=>setPassword(e.target.value)} type="password" placeholder="Password"  className='w-25'/>
      </Form.Group>
      <Form.Group className="mb-3" >      <Form.Text  style={{fontSize:18,fontWeight:'600'}}>Forgot password?</Form.Text>
</Form.Group>
<Form.Group  className="mb-3" >
<Form.Label style={{fontSize:16,fontWeight:'500'}}>{message}</Form.Label>
      <Button variant="primary" type="submit" className='mb-3' >
        Sign In
      </Button>
      </Form.Group>
    </Form>
      </Col>
     </Row> 
     </Container>
  );
}

export default Login;
