import React from 'react';
import { Button } from 'react-bootstrap';
import { utils as XLSXUtils, writeFile } from 'xlsx';

const ExportToExcel = ({e}) => {
  console.error('exporting to Excel:', e);
  const exportToExcel = async () => {
    try {
      // Fetch the JSON data from your JSON file (data.json in this example)
    //   const response = await fetch('data.json');
    //   const data = await response.json();
      const data =  [
        {
          name: 'John Doe',
          age: 25,
          city: 'New York',
        },
        {
          name: 'Jane Smith',
          age: 30,
          city: 'San Francisco',
        },
        // Add more data as needed
      ];

      // Create a worksheet from the JSON data
      const ws = XLSXUtils.json_to_sheet(data);

      // Create a workbook and add the worksheet to it
      const wb = XLSXUtils.book_new();
      XLSXUtils.book_append_sheet(wb, ws, 'Sheet1');

      // Generate a Blob object containing the Excel data
      const blob = writeFile(wb, { bookType: 'xlsx', type: 'blob' });

      // Create a URL for the Blob and trigger a download
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'data.xlsx';
      a.click();

      // Clean up
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting to Excel:', error);
    }
  };

  return (
    <div>
    <Button onClick={exportToExcel}>D/L Xlsx</Button>
      {/* <button onClick={exportToExcel}>D/L Xlsx</button> */}
    </div>
  );
};

export default ExportToExcel;
