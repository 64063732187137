import React,{ useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Nav, Container, Tab, Button, Form} from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { auth } from "../config/Config";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createMaterials } from '../redux/addMaterialReducer';

export default function Render() {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  
  const handleLogOut =()=>{
    auth.signOut();
    // navigation('/login');
  }
// useEffect(()=>console.log('ffff', data), []);
  useEffect(()=>{
  auth.onAuthStateChanged(user => {
    const routeName = user !== null ? '/render' : '/login';
    navigation(routeName);
  });
}, []);

const { userData, userLoader, userError} = useSelector((state)=>state.viewusers);




const [roomTypeTableName, setRoomTypeTableName] = useState("");
const [roomTypeMaterialName, setRoomTypeMaterialName] = useState("");
const [roomTypeMeasurement, setRoomTypeMeasurement] = useState("");

//for dynamic fields
const [materialFields, setMaterialFields] = useState([{ label: '', value: '' }]); 

const handleAddField = () => {
  setMaterialFields([...materialFields, { label: '', value: '' }]); 
};

const handleRemoveField = (index) => {
  const updatedFields = [...materialFields];
  updatedFields.splice(index, 1);
  setMaterialFields(updatedFields);
};

const handleFieldChange = (value, index, fieldName) => {
  const updatedFields = [...materialFields];
  updatedFields[index][fieldName] = value;
  setMaterialFields(updatedFields);
};


const handleSubmit = () => {
  // e.preventDefault();
  // Handle form submission here, e.g., save data to the server or state
  console.log(materialFields);
  

  // Reset the form fields after submission
  // setMaterialFields([{ firstName: '', lastName: '' }]);
};





const handleAddingRoomMaterialCancel = () => {

}

const handleAddingRoomMaterial = () => {
const allData = {
  material:materialFields,
  name:roomTypeMaterialName,
  measurement:roomTypeMeasurement,
  table:roomTypeTableName,
  timestamp: new Date(),
};

dispatch(createMaterials(allData));
setRoomTypeTableName("");
setRoomTypeMaterialName("");
setRoomTypeMeasurement("");
setMaterialFields([{ label: '', value: '' }]);

}
  return (
    <Container fluid>
    <Row>
      <Col sm={1} style={{ backgroundColor: "#fdc771" }} className="">
        <Tab.Container id="left-tabs-example" defaultActiveKey="Render">
          <Row>
            <Col sm={1} style={{ backgroundColor: "#fdc771" }}>
              <div
                style={{
                  justifyContent: "center",
                  justifyItems: "center",
                  justifySelf: "center",
                }}
              >
                <img
                  src={require("../assests/logo-black.png")}
                  alt="Fabdiz"
                  style={{
                    width: 30,
                    height: 30,
                    alignContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                />
                <p style={{ fontSize: 12 }}>Fabdiz</p>
              </div>
              <Nav variant="tabs" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="Dashboard" href="/dashboard">
                    <img
                      src={require("../assests/dashboard.png")}
                      alt="Dashboard"
                      style={{
                        width: 25,
                        height: 25,
                        alignContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    />

                    <p style={{ color: "#000000", fontSize: 12 }}>
                      Dashboard
                    </p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Projects" href="/projects">
                    <img
                      src={require("../assests/projects.png")}
                      alt="Projects"
                      style={{ width: 25, height: 25 }}
                    />

                    <p style={{ color: "#000000", fontSize: 12 }}>Projects</p>
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="Settings" href="/settings">
                    <img
                      src={require("../assests/settings.png")}
                      alt="Settings"
                      style={{ width: 25, height: 25 }}
                    />

                    <p style={{ color: "#000000", fontSize: 12 }}>Settings</p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Services" href="/services">
                    <img
                      src={require("../assests/services.png")}
                      alt="Services"
                      style={{ width: 25, height: 25 }}
                    />

                    <p style={{ color: "#000000", fontSize: 12 }}>Services</p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Team" href="/team">
                    <img
                      src={require("../assests/team.png")}
                      alt="Team"
                      style={{ width: 25, height: 25 }}
                    />

                    <p style={{ color: "#000000", fontSize: 12 }}>Team</p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Factory" href="/factory">
                    <img
                      src={require("../assests/Factory.png")}
                      alt="Factory"
                      style={{ width: 25, height: 25, alignSelf: "center" }}
                    />

                    <p style={{ color: "#000000", fontSize: 12 }}>Factory</p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Inspiration" href="/inspiration">
                    <img
                      src={require("../assests/inspiration.png")}
                      alt="Pricing"
                      style={{ width: 25, height: 25 }}
                    />

                    <p style={{ color: "#000000", fontSize: 12 }}>
                    Pricing
                    </p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Render" href="/render">
                    <img
                      src={require("../assests/render.png")}
                      alt="Render"
                      style={{ width: 25, height: 25 }}
                    />

                    <p style={{ color: "#000000", fontSize: 12 }}>Render</p>
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
            </Col>
          </Row>
        </Tab.Container>
      </Col>
      <Col sm={11} className="m-0">
        <Row>
          <Col style={{
                backgroundColor: "#f5f8f8",
                display: "flex",
                flexDirection: "row",
                padding: "1%",
                justifyContent: "space-between",
              }}>
              <Col sm={10}>
              <p style={{ fontSize: 20, fontWeight: "bold" }}>Render</p>
              </Col>
            
              <Col sm={2}>
                <img
                  src={require("../assests/notification.png")}
                  alt="Notification"
                  style={{ width: 26, height: 26 }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Dropdown className="d-inline mx-2">
                  <Dropdown.Toggle id="dropdown-autoclose-true">
                    <img
                      src={require("../assests/user.png")}
                      alt="User"
                      style={{ width: 40, height: 40 }}
                    />{" "}
                    &nbsp;&nbsp; {userData ? userData[0].name : "UNKNOWN"} &nbsp;
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">
                      <Link to={"/viewprofile"}>View Profile</Link>
                    </Dropdown.Item>
                    <Dropdown.Item href="#">FAQ</Dropdown.Item>
                    <Dropdown.Item  onClick={()=>handleLogOut()}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
          </Col>
        </Row>
        <Row className="p-5">
       
        <Row className="p-2" style={{ backgroundColor: "#F2F2F2", height: 'auto' }}>
              
              <Row>

                <Col
                  sm={12}
                >
                  <Container>
                  <Form>
                 
                  <Row className='mb-2'>
                  <Col sm={7}> <h3>Add Materials for Rooms</h3></Col>
                            <Col><h5>Add Materials options</h5></Col>
                            <Col><Button  variant="success" onClick={handleAddField} > Add options <i class="bi bi-plus-circle-fill"></i> </Button></Col>
                          </Row>
                    <Row style={{ backgroundColor: "#F2F2F2", height: 'auto', overflowY: 'auto' }}>
                      <Col style={{ minHeight: 380, maxHeight: 480 }}>
                      
                       
                        <Row >
                          <Col sm={5}>
                          <Form.Group
                            className="mb-3"
                          
                          >
                            <Form.Label>Room Type</Form.Label>
                            <Form.Select value={roomTypeTableName} onChange={(e) => { setRoomTypeTableName(e.currentTarget.value) }} aria-label="Default select example" required >
                              <option>Select a Room</option>
                              <option value="kitchen">Kitchen</option>
                              <option value="Bedroom">Bedroom</option>
                              <option value="Foyer">Foyer</option>
                              <option value="LivingRoom">Living Room</option>
                              <option value="OtherWorks">Other Works</option>
                              <option value="LooseFurniture">Loose Furniture</option>
                              <option value="Accessories">Accessories</option>
                            </Form.Select>

                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                         
                          >
                            <Form.Label>Material Name</Form.Label>
                            <Form.Control
                              type="text"
                              value={roomTypeMaterialName}
                              onChange={(e) => { setRoomTypeMaterialName(e.target.value) }}
                              placeholder="Laminate / PU "
                              required
                         
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            
                          >
                            <Form.Label>Measurement</Form.Label>
                             <Form.Select value={roomTypeMeasurement} onChange={(e) => { setRoomTypeMeasurement(e.currentTarget.value) }} aria-label="Default select example" required >
                              <option>Select Measurement Type</option>
                              <option value="area">Area</option>
                              <option value="width">Width &  Height</option>
                              <option value="rft">RFT</option>
                              <option value="qty">Quantity</option>
                              <option value="length">Width & Length</option>
                              <option value="selection">Selection</option>
                            </Form.Select>
                          </Form.Group>
                          </Col>
                          <Col sm={7}>
                          
                          
                          {materialFields.map((field, index) => (
                            <Row key={index}>
                            <Col> <Form.Group
                            className="mb-3"
                           
                          >
                           
                            <Form.Control
                              type="text"
                              value={field.label}
                              onChange={(e) => handleFieldChange(e.target.value, index, 'label')}
                              placeholder="Label"
                              required={false}
                            />
                          </Form.Group></Col>
                            <Col> <Form.Group
                            className="mb-3"
                            
                          >
                           
                            <Form.Control
                              type="number"
                              value={field.value}
                              onChange={(e) => handleFieldChange(e.target.value, index, 'value')}
                              placeholder="Value"
                              required={false}
                            />
                          </Form.Group>
                          </Col>
                            <Col> <Button variant="danger" onClick={() => handleRemoveField(index)} >
                            <i class="bi bi-x-circle-fill"></i>
                            </Button></Col>
                            <Col>
                          
                          </Col>
                          </Row>
                           ))}
                          
                          </Col>
                          
                        </Row>
                          
                         
                        
                       
                      </Col>
                    </Row>
                    <Form.Group>
                            <Button variant="secondary" onClick={() => handleAddingRoomMaterialCancel()} className="w-25 mx-4">
                              Cancel
                            </Button>
                            <Button variant="success" onClick={() => handleAddingRoomMaterial()} className="w-25 mx-4">
                              Save
                            </Button>
                            
                          </Form.Group>
                        </Form>

                  </Container>

                </Col>
              </Row>
            
            
          </Row>
         
        </Row>
      </Col>
    </Row>
  </Container>
  );
}


