import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Tab, Col, Button, Card, Nav } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown'
import { Link } from 'react-router-dom';
import { auth, firestore as db } from '../config/Config';
import { useSelector, useDispatch } from 'react-redux';
import { fetchProjectList } from '../redux/projectReducer';
import { useNavigate } from 'react-router-dom';
import { Triangle } from  'react-loader-spinner';

// import Dashboard from './Dashboard';
import Factory from './Factory';
import Inspiration from './Inspiration';
import Projects from './Projects';
import Render from './Render';
import Services from './Services';
import Settings from './Settings';
import Team from './Team';
import Assigned from './Assigned';
import { fetchUserList } from '../redux/userReducer';

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  useEffect(()=>{dispatch(fetchProjectList());}, []);
  const { data, loader, error} = useSelector((state)=>state.projects)
  const { userData, userLoader, userError} = useSelector((state)=>state.viewusers);
   console.log("dashboardData",userData);
    const handleLogOut =()=>{
      auth.signOut();
      // navigation('/login');
    }
  // useEffect(()=>localStorage.setItem("name", JSON.stringify(data)), [data]);
  // useEffect(()=>dispatch(fetchUserList(auth.currentUser.uid)), []);
    useEffect(()=>{
    auth.onAuthStateChanged(user => {
      const routeName = user !== null ? '/dashboard' : '/login';
      navigation(routeName);
    });
  }, []);
  return (
    <Container fluid>
   <Row>
    <Col sm={1} style={{backgroundColor:'#fdc771'}}>
    <Tab.Container id="left-tabs-example" defaultActiveKey="Dashboard" >
    <Row>
      <Col sm={1} style={{backgroundColor:'#fdc771'}}>
      <div style={{justifyContent:'center',justifyItems:'center',justifySelf:'center',}}>
      <img src={require('../assests/logo-black.png')} alt='Fabdiz' style={{width:30,height:30,alignContent:'center',alignItems:'center',alignSelf:'center'}}/>
      <p style={{fontSize:12}}>Fabdiz</p>
      </div>
        <Nav variant="tabs" className="flex-column">
          <Nav.Item>
            <Nav.Link eventKey="Dashboard" href='/dashboard'>
            <img src={require('../assests/dashboard.png')} alt='Dashboard' style={{width:25,height:25,alignContent:'center',alignItems:'center',alignSelf:'center'}}/>

            <p style={{color:'#000000',fontSize:12}}>Dashboard</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Projects" href='/projects'>
            <img src={require('../assests/projects.png')} alt='Projects' style={{width:25,height:25}}/>

           <p style={{color:'#000000',fontSize:12}}>Projects</p></Nav.Link>
          </Nav.Item>
           <Nav.Item>
            <Nav.Link eventKey="Assigned" href='/assigned'>
            <img src={require('../assests/assign.png')} alt='Assigned' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Assigned</p></Nav.Link>
          </Nav.Item>
        {/*  <Nav.Item>
            <Nav.Link eventKey="Services" href='/services'>
            <img src={require('../assests/services.png')} alt='Services' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Services</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Team" href='/team'>
            <img src={require('../assests/team.png')} alt='Team' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Team</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Factory" href='/factory'>
            <img src={require('../assests/Factory.png')} alt='Factory' style={{width:25,height:25,alignSelf:'center'}}/>

            <p style={{color:'#000000',fontSize:12}}>Factory</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Inspiration" href='/inspiration'>
            <img src={require('../assests/inspiration.png')} alt='Pricing' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Pricing</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Render" href='/render'>
            <img src={require('../assests/render.png')} alt='Render' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Render</p></Nav.Link>
          </Nav.Item> */}
        </Nav>
      </Col>
    </Row>
  </Tab.Container>
      </Col>
      {loader ? 
        <center>  <Triangle
                    height="120"
                    width="120"
                    color="#ff5100"
                    ariaLabel="triangle-loading"
                    radius="1"
                    wrapperStyle={{position: "absolute",top: "50%",left: "50%",}}
                    wrapperClass=""
                    visible={true}
                  /> </center> : 
      <Col sm={11}>
      <Row>
      <Col style={{
                  backgroundColor: "#f5f8f8",
                  display: "flex",
                  flexDirection: "row",
                  padding: "1%",
                  justifyContent: "space-between",
                }}>
                <Col sm={10}>
                <p style={{ fontSize: 20, fontWeight: "bold" }}>Dashboard</p>
                </Col>
              
                <Col sm={2}>
                  <img
                    src={require("../assests/notification.png")}
                    alt="Notification"
                    style={{ width: 26, height: 26 }}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle id="dropdown-autoclose-true">
                      <img
                        src={require("../assests/user.png")}
                        alt="User"
                        style={{ width: 40, height: 40 }}
                      />{" "}
                      &nbsp;&nbsp; {userData ? userData[0].name : "UNKNOWN"} &nbsp;
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <Link to={"/viewprofile"}>View Profile</Link>
                      </Dropdown.Item>
                      <Dropdown.Item href="#">FAQ</Dropdown.Item>
                      <Dropdown.Item  onClick={()=>handleLogOut()}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
            </Col>
        
      </Row>
     
      <Row className='mt-5'>
        <Col className='col-12'>
          <Row>
          <Col className='col-md-8'>
            <p style={{fontSize:20,fontWeight:'bold'}}>Ongoing projects</p>
          </Col>
          <Col className='d-flex justify-content-center col-md-4'>
          <Col className='col-md-6'><Link to={'/dashboard'}>View All</Link></Col>
          <Col className='col-md-6'><Button onClick={()=>navigation('/projects', { state: { condition : true} })} variant="primary" >Create   new</Button></Col>
            
          </Col>
          </Row>
          <Row className='mt-5'>
       
          {data != null ? data.map((e)=>(
            <Col className='col-md-5 m-3' key={e.ID}>
            <Card>
              <Card.Body>
              <Card.Title>{e.pname}</Card.Title>
              <Row>
                <Col><Card.Subtitle className="mb-2 text-muted">{e.date}</Card.Subtitle></Col>
                <Col><Card.Subtitle className="mb-2 text-muted">{e.price == null ? e.price : 'Not quoted yet'}</Card.Subtitle></Col>
              </Row>
        
        <Card.Text>
         Design Not selected
        </Card.Text>
              </Card.Body>
            </Card>
            </Col>
          )) : null }
         
         
            
          </Row>
        </Col>
        
        </Row> 
        </Col> }
    </Row>
  
     </Container>
    
    
  );
}


