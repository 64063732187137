import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";

const  initialState={
    bedroomThingsData:[],
    bedroomThingsLoader:true,
    bedroomThingsError:"",
}

export const fetchBedroomList = createAsyncThunk('fetchbedroomThings',
async()=>{
    const projectList = await db
    .collection("Bedroom").orderBy('timestamp', 'asc')
    // .where("status", "==", "Ongoing")
    // .where("ID", "==", auth.currentUser.uid)
    .get();
  const projectListArray = [];
  for (var snap of projectList.docs) {
    var data = snap.data();
    data.ID = snap.id;
    projectListArray.push({
      ...data,
    });
    if (projectListArray.length === projectList.docs.length) {
      return projectListArray
    }
  }
});

const bedroomReducer = createSlice({
    name:'bedroomThings',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchBedroomList.fulfilled]:(state,action)=>{
            state.bedroomThingsData = action.payload
            state.bedroomThingsLoader = false
        },
        [fetchBedroomList.pending]:(state,action)=>{
            state.bedroomThingsLoader = true
        },
        [fetchBedroomList.rejected]:(state,action)=>{
            state.bedroomThingsError = "Close the tab and start with new tab"
        },
    }
});

export default bedroomReducer.reducer