import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { firestore as db } from "../config/Config";


const  initialState={
  names:[],
  modal:false,
  errore:{}
}

export const createClients = createAsyncThunk('createClientList',
async (userData) => {
    await db.collection("client").doc().set(userData);
  });

const createReducer = createSlice({
    name:'createClient',
    initialState,
    reducers:{},
    extraReducers:{
      [createClients.fulfilled]:(state,action)=>{
        state.modal = false;
      },
      [createClients.pending]:(state,action)=>{
        state.modal = false;
      },
      [createClients.rejected]:(state,action)=>{
        state.modal = false;
      },
    }
});

export default createReducer.reducer