import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Form,
  Table,
  Nav,
  Tab,
  InputGroup
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation } from "react-router-dom";
import { firestore as db } from "../config/Config";
import AddRooms from "./AddRooms";
import MainProject from "./MainProject";
import ProjectView from "./ProjectView";
import { auth } from "../config/Config";
import { useSelector, useDispatch } from "react-redux";
import { fetchProjectList } from "../redux/projectReducer";
import { createClients } from "../redux/createProject";
import { completeClients } from "../redux/completedReducer";
import {useNavigate} from "react-router-dom";

export default function Assigned() {

  const [search, setSearch] = useState('');
  const [viewRoom, setViewRoom] = useState(false);
  const [addRoom, setAddRoom] = useState(false);
  const [clientDataFor, setClientDataFor] = useState([]);
const location = useLocation();
const conditionForModal = location.state;

const navigation = useNavigate();

  const changeView = (clientData) =>{
    setClientDataFor(clientData);
    setViewRoom(true);
  }
  const addView = (clientData) =>{
    setClientDataFor(clientData);
    setAddRoom(true);
    setViewRoom(false);
  }
  
  const { data, loader, error} = useSelector((state)=>state.projects);
  const { userData, userLoader, userError} = useSelector((state)=>state.viewusers);
  console.log("hello",userData);
  
 const dispatch = useDispatch();

 
  const [projectName, setProjectName] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientPhone, setClientPhone] = useState(0);
  const [clientEmail, setClientEmail] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [show, setShow] = useState(conditionForModal);
  const [markOn, setMarkOn] = useState(false);
  const [clientID, setClientID] = useState("");
  const [increment, setIncrement] = useState(0);
  const [userName, setUserName] = useState(userData[0].name);

  const handleMarkClose = () => {
    setMarkOn(false);
  };
  const handleClose = () => {
    setShow(false);
  };
  const curDate = new Date().toLocaleDateString();

  const createProject=()=>{
    const userData = {
          ID: auth.currentUser.uid,
          pname: projectName,
          cname: clientName,
          cphone: clientPhone,
          cemail: clientEmail,
          city: city,
          state: state,
          date: curDate,
          status: "Ongoing",
          createdBy: userName,
          price: "",
          increment: increment,
        };
    dispatch(createClients(userData));
    dispatch(fetchProjectList());
     setShow(false);
  }


  // mark on complete modal

  const markModal = (e) => {
    
    setMarkOn(true);
    setClientID(e);
  };

  const handleComplete = () =>{
    dispatch(completeClients(clientID));
    dispatch(fetchProjectList());
    setMarkOn(false);
  }

  const handleViewRoom = (e) =>{
    changeView(e);
  }


  const handleLogOut =()=>{
    auth.signOut();
    // navigation('/login');
  }
// useEffect(()=>console.log('ffff', data), []);
  useEffect(()=>{
  auth.onAuthStateChanged(user => {
    const routeName = user !== null ? '/assigned' : '/login';
    navigation(routeName);
  });
}, []);


const [validated, setValidated] = useState(false);

const handleSubmit = (event) => {
  const form = event.currentTarget;
  if (form.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    
  }

  setValidated(true);
 
};
  return (
    <Container fluid>
    <Row>
    <Col sm={1} style={{backgroundColor:'#fdc771'}}>
    <Tab.Container id="left-tabs-example" defaultActiveKey="Assigned" >
    <Row>
      <Col sm={1} style={{backgroundColor:'#fdc771'}}>
      <div style={{justifyContent:'center',justifyItems:'center',justifySelf:'center',}}>
      <img src={require('../assests/logo-black.png')} alt='Fabdiz' style={{width:30,height:30,alignContent:'center',alignItems:'center',alignSelf:'center'}}/>
      <p style={{fontSize:12}}>Fabdiz</p>
      </div>
        <Nav variant="tabs" className="flex-column">
          <Nav.Item>
            <Nav.Link eventKey="Dashboard" href='/dashboard'>
            <img src={require('../assests/dashboard.png')} alt='Dashboard' style={{width:25,height:25,alignContent:'center',alignItems:'center',alignSelf:'center'}}/>

            <p style={{color:'#000000',fontSize:12}}>Dashboard</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Projects" href='/projects'>
            <img src={require('../assests/projects.png')} alt='Projects' style={{width:25,height:25}}/>

           <p style={{color:'#000000',fontSize:12}}>Projects</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Assigned" href='/assigned'>
            <img src={require('../assests/assign.png')} alt='Assigned' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Assigned</p></Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey="Settings" href='/settings'>
            <img src={require('../assests/settings.png')} alt='Settings' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Settings</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Services" href='/services'>
            <img src={require('../assests/services.png')} alt='Services' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Services</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Team" href='/team'>
            <img src={require('../assests/team.png')} alt='Team' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Team</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Factory" href='/factory'>
            <img src={require('../assests/Factory.png')} alt='Factory' style={{width:25,height:25,alignSelf:'center'}}/>

            <p style={{color:'#000000',fontSize:12}}>Factory</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Inspiration" href='/inspiration'>
            <img src={require('../assests/inspiration.png')} alt='Pricing' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Pricing</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Render" href='/render'>
            <img src={require('../assests/render.png')} alt='Render' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Render</p></Nav.Link>
          </Nav.Item> */}
        </Nav>
      </Col>
    </Row>
  </Tab.Container>
      </Col>
    <Col sm={11}>
    <Row>
    <Col style={{
                  backgroundColor: "#f5f8f8",
                  display: "flex",
                  flexDirection: "row",
                  padding: "1%",
                  justifyContent: "space-between",
                }}>
                <Col sm={10}>
                <p style={{ fontSize: 20, fontWeight: "bold" }}>Assigned</p>
                </Col>
              
                <Col sm={2}>
                  <img
                    src={require("../assests/notification.png")}
                    alt="Notification"
                    style={{ width: 26, height: 26 }}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle id="dropdown-autoclose-true">
                      <img
                        src={require("../assests/user.png")}
                        alt="User"
                        style={{ width: 40, height: 40 }}
                      />{" "}
                      &nbsp;&nbsp; {userData ? userData[0].name : "UNKNOWN"} &nbsp;
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <Link to={"/viewprofile"}>View Profile</Link>
                      </Dropdown.Item>
                      <Dropdown.Item href="#">FAQ</Dropdown.Item>
                      <Dropdown.Item  onClick={()=>handleLogOut()}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
            </Col>
      </Row>
      <Row className="mt-5">
     
        
        <Row className="mt-5">
        <Col><p style={{fontSize:'1.2em',fontWeight:'500'}}>Assigned List</p></Col>
        <Col>
          <Form>
            <InputGroup>
              <Form.Control onChange={(e) => setSearch(e.target.value)} placeholder="Search Projects"/>
            </InputGroup>
          </Form>
        </Col>
          
           <Table responsive>
            <thead>
              <tr>
                <th>Project</th>
                <th>Name</th>
                <th>Number</th>
                <th>Create</th>
                <th>Status</th>
                <th>Created By</th>
               
                <th>Price</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            {data != null ? data.filter((item) => {
              return search.toLowerCase() === '' ? item
              : item.pname.toLowerCase().includes(search);
            }).map((e) => (
                <tr key={e.ID}>
                  <td>{e.pname}</td>
                  <td>{e.cname}</td>
                  <td>{e.cphone}</td>
                  <td>{e.date}</td>
                  <td>{e.status}</td>
                  <td>{e.createdBy}</td>
                  {/* <td>
                    <Button
                      variant="link"
                      onClick={()=>markModal(e.ID)}
                    >
                      Mark as complete
                    </Button>
                  </td> */}
                  <td>{e.price}</td>
                  {/* <td> */}
                    {/* <Button>Update prices</Button> */}
                  {/* </td> */}
                  <td>
                    <Button variant="primary" onClick={()=>{navigation('/projects/view-rooms', {state:e})}}>View Rooms</Button>
                  </td>
                  <td>
                    <Button>Import Rooms</Button>
                  </td>
                </tr>
              )) : null}
            </tbody>
          </Table>
          <Modal show={markOn} onHide={handleMarkClose}>
            <Modal.Body>
              <Modal.Title style={{ fontSize: 15, fontWeight: "bold" }}>
                Do you want to mark this project as completed?
              </Modal.Title>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>handleMarkClose()}>
                Close
              </Button>
              <Button variant="primary" onClick={()=>handleComplete()}>Complete</Button>
            </Modal.Footer>
          </Modal>
        </Row>
      </Row>      
    
    </Col>
    </Row>
 
    
  
    </Container>
  );
}
