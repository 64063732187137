import React,{ useEffect } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import {Button, Tab, Col, Row, Nav, Form, Stack, Container } from 'react-bootstrap';
import Dashboard from './components/Dashboard';
import Factory from './components/Factory';
import Inspiration from './components/Inspiration';
import Projects from './components/Projects';
import Render from './components/Render';
import Services from './components/Services';
import Settings from './components/Settings';
import Team from './components/Team';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Login from './components/Login';
import MainPage from './components/MainPage';
import ViewProfile from './components/ViewProfile';
import Notification from './components/Notification';
import { Signup } from './components/Signup';
import { auth } from './config/Config';
import ProjectView from './components/ProjectView';
import AddRooms from './components/AddRooms';
import ViewRoom from './components/ViewRoom';
import AddQuotation from './components/AddQuotation';
import ClientQuotation from './components/ClientQuotation';
import EditRooms from './components/EditRooms';
import ViewDesign from './components/ViewDesign';
import PriceList from './components/PriceList';
import Assigned from './components/Assigned';



function App() {
  const navigate = useNavigate();
  // useEffect(()=>{
  //   auth.onAuthStateChanged(user => {
  //     const routeName = user !== null ? '/dashboard' : '/login';
  //     navigate(routeName);
  //   });
  // }, []);
  
  // useEffect(() => {
  //   setTimeout(async () => {
  //     const unsubscribe = await Auth().onAuthStateChanged(user => {
  //       const routeName = user !== null ? 'Home' : 'Login';

  //       unsubscribe();
  //       navigation.dispatch(StackActions.replace(routeName));
  //     });
  //   }, 3000);

  //   return () => {};
  // }, []);
  return (
   <Routes>
    <Route path='/' element={<Login/>}/>
    <Route path='/dashboard' element={<Dashboard/>}/>
    <Route path='/projects' element={<Projects/>}/>
    <Route path='/assigned' element={<Assigned/>}/>
    <Route path='/projects/view-rooms' element={<ProjectView/>}/>
    <Route path='/projects/view-rooms/add-rooms' element={<AddRooms/>}/>
    <Route path='/projects/view-rooms/edit-rooms' element={<EditRooms/>}/>
    <Route path='/projects/view-rooms/view-designs' element={<ViewDesign/>}/>
    <Route path='/projects/view-rooms/add-quotation' element={<AddQuotation/>}/>
    <Route path='/factory' element={<Factory/>}/>
    <Route path='/inspiration' element={<Inspiration/>}/>
    <Route path='/render' element={<Render/>}/>
    <Route path='/services' element={<Services/>}/>
    <Route path='/settings' element={<Settings/>}/>
    <Route path='/team' element={<Team/>}/>
    <Route path='/viewprofile' element={<ViewProfile/>}/>
    <Route path='/notification' element={<Notification/>}/>
    <Route path='/login' element={<Login/>}/>
    <Route path='/signup' element={<Signup/>}/>
    <Route path='/client-quotation' element={<ClientQuotation/>} />
    <Route path='/price-list' element={<PriceList/>} />
    {/* <Route path='/dashboard' element={<MainPage/>}/>
    <Route path='/dashboard' element={<MainPage/>}/> */}
   </Routes>
   
  );
}

export default App;
