import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";


const  initialState={
    updateNames:[],
  updateModal:false,
  updateError:{}
}

export const updateRooms = createAsyncThunk('updateRoomList', async (roomDatas) => {
  try {
    await db.collection('client').doc(roomDatas.id).collection('Rooms').doc(roomDatas.id2).update({
      title: roomDatas.title,
      fields: roomDatas.fields,
      roomTypePrice: roomDatas.roomTypePrice,
      roomTypeWoodPrice: roomDatas.roomTypeWoodPrice,
    });
  
    return roomDatas; // Return data if needed in the fulfilled action
    
  } catch (error) {
    throw error; // Handle the error in the rejected action
  }
});

const updateRoomsReducer = createSlice({
    name:'updateRoom',
    initialState,
    reducers:{},
    extraReducers:{
      [updateRooms.fulfilled]:(state,action)=>{
        state.updateModal = true;
      },
      [updateRooms.pending]:(state,action)=>{
        state.updateModal = false;
      },
      [updateRooms.rejected]:(state,action)=>{
        state.updateModal = false;
      },
    }
});

export default updateRoomsReducer.reducer