import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  Tab,
  Nav,
  Form,
  Modal,
  Accordion,
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { firestore as db } from "../config/Config";
import { useLocation } from "react-router-dom";
import { Collapse } from "bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchKitchenList } from "../redux/kitchenReducer";
import { useNavigate } from "react-router-dom";
import { auth } from "../config/Config";
import { createRooms } from "../redux/addRoomReducer";


export default function ViewDesign({ clientDataFor }) {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const data = location.state;
  console.log(data);

  useEffect(() => {
    dispatch(fetchKitchenList());
  }, []);

  const { userData, userLoader, userError} = useSelector((state)=>state.viewusers);

  // Material things

  const [kitchenBaseGrandTotal, setKitchenBaseGrandTotal] = useState(0);

  const [materialType, setMaterialType] = useState("");
  const [materialLabel, setMaterialLabel] = useState(0);
  const [materialValue, setMaterialValue] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [modalUrl, setModalUrl] = useState("");
  const [materialWidth, setMaterialWidth] = useState(0);
  const [materialHeight, setMaterialHeight] = useState(0);
  // const [materialDepth, setMaterialDepth] = useState(2);
  const [materialQuantity, setMaterialQuantity] = useState(0);
  const [materialPrice, setMaterialPrice] = useState(0);
  const handleFistItem = (name) => {
    setModalTitle(name.label);
    setModalUrl(name.url);
  };




  const handleLogOut = () => {
    auth.signOut();
    // navigation('/login');
  }

  // for room type view dynamically

  const [roomTypeModal, setRoomTypeModal] = useState("");



  return (
    <Container fluid>
      <Row>
        <Col sm={1} style={{ backgroundColor: '#fdc771' }}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="Projects" >
            <Row>
              <Col sm={1} style={{ backgroundColor: '#fdc771' }}>
                <div style={{ justifyContent: 'center', justifyItems: 'center', justifySelf: 'center', }}>
                  <img src={require('../assests/logo-black.png')} alt='Fabdiz' style={{ width: 30, height: 30, alignContent: 'center', alignItems: 'center', alignSelf: 'center' }} />
                  <p style={{ fontSize: 12 }}>Fabdiz</p>
                </div>
                <Nav variant="tabs" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="Dashboard" href='/dashboard'>
                      <img src={require('../assests/dashboard.png')} alt='Dashboard' style={{ width: 25, height: 25, alignContent: 'center', alignItems: 'center', alignSelf: 'center' }} />

                      <p style={{ color: '#000000', fontSize: 12 }}>Dashboard</p></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Projects" href='/projects'>
                      <img src={require('../assests/projects.png')} alt='Projects' style={{ width: 25, height: 25 }} />

                      <p style={{ color: '#000000', fontSize: 12 }}>Projects</p></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
            <Nav.Link eventKey="Assigned" href='/assigned'>
            <img src={require('../assests/assign.png')} alt='Assigned' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Assigned</p></Nav.Link>
          </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="Settings" href='/settings'>
                      <img src={require('../assests/settings.png')} alt='Settings' style={{ width: 25, height: 25 }} />

                      <p style={{ color: '#000000', fontSize: 12 }}>Settings</p></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Services" href='/services'>
                      <img src={require('../assests/services.png')} alt='Services' style={{ width: 25, height: 25 }} />

                      <p style={{ color: '#000000', fontSize: 12 }}>Services</p></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Team" href='/team'>
                      <img src={require('../assests/team.png')} alt='Team' style={{ width: 25, height: 25 }} />

                      <p style={{ color: '#000000', fontSize: 12 }}>Team</p></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Factory" href='/factory'>
                      <img src={require('../assests/Factory.png')} alt='Factory' style={{ width: 25, height: 25, alignSelf: 'center' }} />

                      <p style={{ color: '#000000', fontSize: 12 }}>Factory</p></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Inspiration" href='/inspiration'>
                      <img src={require('../assests/inspiration.png')} alt='Inspiration' style={{ width: 25, height: 25 }} />

                      <p style={{ color: '#000000', fontSize: 12 }}>Inspiration</p></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Render" href='/render'>
                      <img src={require('../assests/render.png')} alt='Render' style={{ width: 25, height: 25 }} />

                      <p style={{ color: '#000000', fontSize: 12 }}>Render</p></Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
        <Col sm={11}>
          <Row>
            <Col style={{
              backgroundColor: "#f5f8f8",
              display: "flex",
              flexDirection: "row",
              padding: "1%",
              justifyContent: "space-between",
            }}>
              <Col sm={10}>
                <p style={{ fontSize: 20, fontWeight: "bold" }}>Projects</p>
              </Col>

              <Col sm={2}>
                <img
                  src={require("../assests/notification.png")}
                  alt="Notification"
                  style={{ width: 26, height: 26 }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Dropdown className="d-inline mx-2">
                  <Dropdown.Toggle id="dropdown-autoclose-true">
                    <img
                      src={require("../assests/user.png")}
                      alt="User"
                      style={{ width: 40, height: 40 }}
                    />{" "}
                    &nbsp;&nbsp; {userData ? userData[0].name : "UNKNOWN"} &nbsp;
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">
                      <Link to={"/viewprofile"}>View Profile</Link>
                    </Dropdown.Item>
                    <Dropdown.Item href="#">FAQ</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleLogOut()}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Row className="d-flex justify-content-end col-sm-5">
              <Breadcrumb>
                <Breadcrumb.Item href="/projects">Projects</Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() =>
                    navigation("/projects/view-rooms", { state: data })
                  }
                >
                  {data.pname}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>View Design</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            {/* Room name and type modal */}
            <Row className="p-2" style={{ backgroundColor: "#F2F2F2", height: 'auto', overflowY: 'auto' }}>
              
              <Row>

                <Col
                  sm={12}
                >
                  <Container>
                    <Row>
                      <Col style={{ minHeight: 380, maxHeight: 480 }}>
                        <h4>Room Details</h4>
                        <Row className=" border-bottom border-dark">
                                <Col><h6>Name</h6></Col>
                                <Col><h6>Material</h6></Col>
                                <Col><h6>Width</h6></Col>
                                <Col><h6>Height/length</h6></Col>
                                <Col><h6>Area/RFT</h6></Col>
                                <Col><h6>Base Price</h6></Col>
                                <Col><h6>Quantity</h6></Col>
                                <Col><h6>Price</h6></Col>
                                <Col><h6>Remark</h6></Col>
                            </Row>
                       {data.viewData.title !== "" ? data.viewData.title.map((item, index) => {
                            if(item.value > 0 || item.name == "Shape Of Kitchen"){
                            return( <>
                            <Row className=" border-bottom border-dark-subtle p-2">
                                <Col><h6>{item.name} {item.label2 ? `  (${item.label2})` : null}</h6></Col>
                                <Col><h6>{item.label}</h6></Col>
                                <Col><h6>{item.width}</h6></Col>
                                <Col><h6>{item.height}</h6></Col>
                                <Col><h6>{item.area}{item.area}</h6></Col>
                                <Col><h6>{item.value}</h6></Col>
                                <Col><h6>{item.qnty ? item.qnty : null}</h6></Col>
                                <Col><h6>{item.price}</h6></Col>
                                <Col><h6>{item.remark}</h6></Col>
                            </Row>
                        </>)
                            }
                       
                       }) : null}
                       
                      </Col>
                    </Row>

                  </Container>

                </Col>
              </Row>
            
            
          </Row>
            
            
             {/* Bedroom modal */}
             
          </Row>

        </Col>
      </Row>

    </Container>
  );
}
