import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";

const  initialState={
    clientQuoteData:[],
    clientQuoteLoader:true,
    clientQuoteError:"",
}

export const fetchClientQuotationList = createAsyncThunk('fetchclientquote',
async(CQLId)=>{
  // db.collection("client").doc('roomData.url').collection("Rooms")
    const projectList = await db
    .collectionGroup("Quotations")
    // .doc(CQLId.id)
    // .collection("Room" +  auth.currentUser.uid)
    // .where("status", "==", "Ongoing")
    // .where("id", '==', "TYd2aGvjbDiANYdXAtzb")
    .get();
  const projectListArray = [];
  for (var snap of projectList.docs) {
    var data = snap.data();
    data.ID = snap.id;
    projectListArray.push({
      ...data,
    });
    if (projectListArray.length === projectList.docs.length) {
      console.log("roomlISt", projectListArray);
      return projectListArray
    }
  }
});

const clientQuotationReducer = createSlice({
    name:'viewclientquotes',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchClientQuotationList.fulfilled]:(state,action)=>{
            state.clientQuoteData = action.payload
            state.clientQuoteLoader = false
        },
        [fetchClientQuotationList.pending]:(state,action)=>{
            state.clientQuoteLoader = true
        },
        [fetchClientQuotationList.rejected]:(state,action)=>{
            state.clientQuoteError = "refresh the page"
        },
    }
});

export default clientQuotationReducer.reducer