import { configureStore } from '@reduxjs/toolkit';
import completedReducer from '../redux/completedReducer';

import createReducer from '../redux/createProject';
import projectReducer from '../redux/projectReducer';
import { combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import kitchenReducer from '../redux/kitchenReducer';
import kitchenBaseHandleReducer from '../redux/kitchenBaseHandleReducer';
import kitchenThaliBasketReducer from '../redux/kitchenThaliBasketReducer';
import kitchenThaliInletReducer from '../redux/kitchenThaliInletReducer';
import kitchenDustbinBasicReducer from '../redux/kitchenDustbinBasicReducer';
import kitchenWickerBasketBasicReducer from '../redux/kitchenWickerBasketBasicReducer';
import kitchenDetergentHolderBasicReducer from '../redux/kitchenDetergentHolderBasicReducer';
import kitchenBaseCabinetIMagesReducer from '../redux/kitchenBaseCabinetIMagesReducer';
import addRoomReducer from '../redux/addRoomReducer';
import viewRoomReducer from '../redux/viewRoomReducer';
import addQuotationReducer from '../redux/addQuotationReducer';
import quotationReducer from '../redux/quotationReducer';
import clientQuotationReducer from '../redux/clientQuotationReducer';
import renameRoomReducer from '../redux/renameRoomReducer';
import kitchenThingsReducer from '../redux/kitchenThingsReducer';
import looseFurnitureReducer from '../redux/looseFurnitureReducer';
import addMaterialReducer from '../redux/addMaterialReducer';
import otherWorkReducer from '../redux/otherWorkReducer';
import bedroomReducer from '../redux/bedroomReducer';
import foyerReducer from '../redux/foyerReducer';
import livingRoomReducer from '../redux/livingRoomReducer';
import updateRoomsReducer from '../redux/updateRoomsReducer';
import updateRooms2Reducer from '../redux/updateRooms2Reducer';
import renameDesignReducer from '../redux/renameDesignReducer';
import duplicationDesignReducer from '../redux/duplicationDesignReducer';
import accessoriesReducer from '../redux/accessoriesReducer';
import userReducer from '../redux/userReducer';
import clientLinkReducer from '../redux/clientLinkReducer';


const persistConfig = {
key:'root',
version: 1,
storage,
}
const rootReducer = combineReducers({
  projects: projectReducer,
    createClient: createReducer,
    completeClient: completedReducer,
    kitchens: kitchenReducer,
    kitchenBaseHandle: kitchenBaseHandleReducer,
    kitchenThaliBasket: kitchenThaliBasketReducer,
    kitchenThaliInlet: kitchenThaliInletReducer,
    kitchenDustbinBasic: kitchenDustbinBasicReducer,
    kitchenWickerBasketBasic: kitchenWickerBasketBasicReducer,
    kitchenDetergentHolderBasic: kitchenDetergentHolderBasicReducer,
    kitchenBaseCabinetIMages: kitchenBaseCabinetIMagesReducer,
    createRoom: addRoomReducer,
    updateRoom: updateRoomsReducer,
    updateRoom2: updateRooms2Reducer,
    viewrooms: viewRoomReducer,
    viewquotes: quotationReducer,
    createQuotation: addQuotationReducer,
    viewclientquotes : clientQuotationReducer,
    reNameRoomWithId: renameRoomReducer,
    reNameDesignWithId: renameDesignReducer,
    kitchenThings : kitchenThingsReducer,
    looseThings : looseFurnitureReducer,
    createMaterial : addMaterialReducer,
    OtherWorkThings : otherWorkReducer,
    bedroomThings : bedroomReducer,
    foyerThings : foyerReducer,
    livingThings : livingRoomReducer,
    accessories : accessoriesReducer,
    duplicateDesign : duplicationDesignReducer,
    viewusers : userReducer,
    linkQuote : clientLinkReducer,

});

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);

