import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";

const  initialState={
    kitchenThaliInletData:[],
    kitchenThaliInletLoader:true,
    kitchenThaliInletError:"",
}

export const fetchKitchenThaliInletList = createAsyncThunk('fetchkitchenThaliInlet',
async()=>{
    const projectList = await db
    .collectionGroup("thali_basket_ss")
    // .where("status", "==", "Ongoing")
    // .where("ID", "==", auth.currentUser.uid)
    .get();
  const projectListArray = [];
  for (var snap of projectList.docs) {
    var data = snap.data();
    data.ID = snap.id;
    projectListArray.push({
      ...data,
    });
    if (projectListArray.length === projectList.docs.length) {
      return projectListArray
    }
  }
});

const kitchenThaliInletReducer = createSlice({
    name:'kitchenThaliInlet',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchKitchenThaliInletList.fulfilled]:(state,action)=>{
            state.kitchenThaliInletData = action.payload
            state.kitchenThaliInletLoader = false
        },
        [fetchKitchenThaliInletList.pending]:(state,action)=>{
            state.kitchenThaliInletLoader = true
        },
        [fetchKitchenThaliInletList.rejected]:(state,action)=>{
            state.kitchenThaliInletError = "refresh the page"
        },
    }
});

export default kitchenThaliInletReducer.reducer