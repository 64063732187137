import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";

const  initialState={
    looseThingsData:[],
    looseThingsLoader:true,
    looseThingsError:"",
}

export const fetchLooseThingsList = createAsyncThunk('fetchlooseThings',
async()=>{
    const projectList = await db
    .collection("LooseFurniture").orderBy('timestamp', 'asc')
    // .where("status", "==", "Ongoing")
    // .where("ID", "==", auth.currentUser.uid)
    .get();
  const projectListArray = [];
  for (var snap of projectList.docs) {
    var data = snap.data();
    data.ID = snap.id;
    projectListArray.push({
      ...data,
    });
    if (projectListArray.length === projectList.docs.length) {
      return projectListArray
    }
  }
});

const looseFurnitureReducer = createSlice({
    name:'looseThings',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchLooseThingsList.fulfilled]:(state,action)=>{
            state.looseThingsData = action.payload
            state.looseThingsLoader = false
        },
        [fetchLooseThingsList.pending]:(state,action)=>{
            state.looseThingsLoader = true
        },
        [fetchLooseThingsList.rejected]:(state,action)=>{
            state.looseThingsError = "refresh the page"
        },
    }
});

export default looseFurnitureReducer.reducer