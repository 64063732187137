import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";

const  initialState={
    kitchenThaliBasketData:[],
    kitchenThaliBasketLoader:true,
    kitchenThaliBasketError:"",
}

export const fetchKitchenThaliBasketList = createAsyncThunk('fetchkitchenThaliBasket',
async()=>{
    const projectList = await db
    .collectionGroup("thali_basket_ss")
    // .where("status", "==", "Ongoing")
    // .where("ID", "==", auth.currentUser.uid)
    .get();
  const projectListArray = [];
  for (var snap of projectList.docs) {
    var data = snap.data();
    data.ID = snap.id;
    projectListArray.push({
      ...data,
    });
    if (projectListArray.length === projectList.docs.length) {
      return projectListArray
    }
  }
});

const kitchenThaliBasketReducer = createSlice({
    name:'kitchenThaliBasket',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchKitchenThaliBasketList.fulfilled]:(state,action)=>{
            state.kitchenThaliBasketData = action.payload
            state.kitchenThaliBasketLoader = false
        },
        [fetchKitchenThaliBasketList.pending]:(state,action)=>{
            state.kitchenThaliBasketLoader = true
        },
        [fetchKitchenThaliBasketList.rejected]:(state,action)=>{
            state.kitchenThaliBasketError = "refresh the page"
        },
    }
});

export default kitchenThaliBasketReducer.reducer