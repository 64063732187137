import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";

const  initialState={
    linkData:[],
    linkLoader:true,
    linkError:"",
}

export const fetchLinkQuotation = createAsyncThunk('fetchLinkQuotes',
async(linkData1)=>{
  // db.collection("client").doc('roomData.url').collection("Rooms")
    const projectList = await db
    .collection("client").doc(linkData1.Id).collection('Quotations')
    // .collection("Room" +  auth.currentUser.uid)
    // .where("status", "==", "Ongoing")
    // .where("ID", "==", linkData1.Id2)
    .get();
  const projectListArray = [];
  for (var snap of projectList.docs) {
    var data = snap.data();
    data.ID = snap.id;
    projectListArray.push({
      ...data,
    });
    if (projectListArray.length === projectList.docs.length) {
      console.log("roomlISt", projectListArray);
      return projectListArray
    }
  }
});

const clientLinkReducer = createSlice({
    name:'linkQuote',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchLinkQuotation.fulfilled]:(state,action)=>{
            state.linkData = action.payload
            state.linkLoader = false
        },
        [fetchLinkQuotation.pending]:(state,action)=>{
            state.linkLoader = true
        },
        [fetchLinkQuotation.rejected]:(state,action)=>{
          state.linkLoader = false
            state.linkError = "refresh the page"
        },
    }
});

export default clientLinkReducer.reducer