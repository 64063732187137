import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { firestore as db } from "../config/Config";

const  initialState={
  cnames:[],
  cmodal:false,
  cerrore:{}
}

export const completeClients = createAsyncThunk('completeClientList',
async (clientID) => {
    await db.collection("client").doc(clientID).update({
        status:"Completed",
      });
  });

const completeReducer = createSlice({
    name:'completeClient',
    initialState,
    reducers:{},
    extraReducers:{
      [completeClients.fulfilled]:(state,action)=>{
        state.cmodal = false;
      },
      [completeClients.pending]:(state,action)=>{
        state.cmodal = false;
      },
      [completeClients.rejected]:(state,action)=>{
        state.cmodal = false;
      },
    }
});

export default completeReducer.reducer