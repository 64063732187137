import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";


const  initialState={
    roomNames:[],
  roomModal:false,
  roomError:{}
}

export const createRooms = createAsyncThunk('createRoomList',
async (roomData) => {
    await db.collection("client").doc(roomData.id).collection("Rooms").doc().set(roomData);
  });

const addRoomReducer = createSlice({
    name:'createRoom',
    initialState,
    reducers:{},
    extraReducers:{
      [createRooms.fulfilled]:(state,action)=>{
        state.roomModal = true;
      },
      [createRooms.pending]:(state,action)=>{
        state.roomModal = false;
      },
      [createRooms.rejected]:(state,action)=>{
        state.roomModal = false;
      },
    }
});

export default addRoomReducer.reducer