import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ButtonGroup,
  Dropdown,
  Modal,
  Form,
  Table,
  Nav,
  Tab,
  Tabs,
  Card,
  Breadcrumb,
  DropdownButton,
  Badge,
} from "react-bootstrap";

import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import './ProjectView.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import * as Icon from 'react-bootstrap-icons';
import { threeDotsVertical } from 'react-bootstrap-icons';
import { useSelector, useDispatch } from "react-redux";
import { fetchRoomList } from "../redux/viewRoomReducer";
import { fetchQuotationList } from "../redux/quotationReducer";
import ClientPDF from "./ClientPDF";
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { Triangle } from  'react-loader-spinner';
import { renameRoomName } from "../redux/renameRoomReducer";
import ExportToExcel from "./ExportToExcel";
import { auth, firestore as db } from "../config/Config";
import { renameDesignName } from "../redux/renameDesignReducer";
import { duplicateDesigns } from "../redux/duplicationDesignReducer";
import ViewProfile from "./ViewProfile";
import ExportExcel from "./ExportExcel";

function ProjectView() { 


  const fileName = "Invoice.pdf";
  // useEffect(()=>{
  //   auth.onAuthStateChanged(user => {
  //     const routeName = user !== null ? '/projects/view-rooms' : '/login';
  //     navigation(routeName);
  //   });
  // }, []);
// how to refresh the page in react js?
  const location = useLocation() 
  const dataOfClient = location.state;

  console.log('dataof page viw', location);
  console.log('dataof client', dataOfClient);
  const navigation = useNavigate();

  // useSelector and dispatch

  const dispatch = useDispatch();
  // const { userData, userLoader, userError} = useSelector((state)=>state.viewusers);

  useEffect(()=>{
    const viewData = {
      Id : dataOfClient.ID,
    };
    dispatch(fetchRoomList(viewData));
    dispatch(fetchQuotationList(viewData));}, []);
    const { viewData, viewLoader, viewError} = useSelector((state)=>state.viewrooms);
    const { quoteData, quoteLoader, quoteError} = useSelector((state)=>state.viewquotes);
    const { userData, userLoader, userError} = useSelector((state)=>state.viewusers);
    // const { quotationNames } = useSelector((state)=>state.createQuotation);

    const result = viewData ? viewData.reduce((finalArray, current) => {
      let obj = finalArray.find((item) => item.roomTypeName === current.roomTypeName);
      if(obj){
        return finalArray;
      }
      
        return finalArray.concat([current]);
      
    }, []) : null;


    useEffect(()=>{console.log("quoteDaqqta", quoteData);},[]);
    useEffect(()=>{console.log("quoteDaqqtaResult", result);},[]);

  const handleLogOut =()=>{
    auth.signOut();
    // navigation('/login');
  }
useEffect(()=>console.log('ffff', viewData), []);
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {/* custom icon */}
    {children}

  </a>
));

 // To add rooms for 
const addRoom = () => {
  dataOfClient['roomType'] = "";
  dataOfClient['roomName'] = "";
  navigation('/projects/view-rooms/add-rooms', {state: dataOfClient})
 }

// To add designs in same rooms
 const addDesign = (i) => {
  dataOfClient['roomType'] = i.roomType;
  dataOfClient['roomName'] = i.roomTypeName;
  navigation('/projects/view-rooms/add-rooms', {state: dataOfClient})
 }
 // To edit designs in same rooms
 const editDesign = (i) => {
  // dataOfClient['roomType'] = i.roomType;
  dataOfClient['editData'] = i;
  navigation('/projects/view-rooms/edit-rooms', {state: dataOfClient})
 } 


  // To view designs in same rooms
  const viewDesign = (i) => {
    // dataOfClient['roomType'] = i.roomType;
    dataOfClient['viewData'] = i;
    navigation('/projects/view-rooms/view-designs', {state: dataOfClient})
   } 
 // rename modal
 const [renameOn, setRenameOn] = useState(false);
 const [renameID, setRenameID] = useState('');
 const [nameRoomTypeName, setNameRoomTypeName] = useState('');
 const [preNameRoomTypeName, setPreNameRoomTypeName] = useState('');
//  const [renameRoomTypeName, setRenameRoomTypeName] = useState('');


 const handleRenameCloseOn = () => {
  setRenameOn(false);
};

const renameModal = (i) => {
  setNameRoomTypeName(i.roomTypeName);
  setPreNameRoomTypeName(i.roomTypeName);
  setRenameID(i.ID);
  setRenameOn(true);
  
};

const handleRenameCom = () =>{
 const newRoomName = {
  cid : dataOfClient.ID,
  id: renameID,
  preRoomTypeName : preNameRoomTypeName,
  roomTypeNewName: nameRoomTypeName,
 }
 console.log("sanja",newRoomName);
  dispatch(renameRoomName(newRoomName));
  const viewData = {
    Id : dataOfClient.ID,
  };
  dispatch(fetchRoomList(viewData));
  // dispatch(fetchQuotationList(viewData));
  // dispatch(fetchRoomList(viewData));
  // dispatch(fetchProjectList());
  setRenameOn(false);
}

// Design Rename Modal

 // rename modal
 const [renameDesignModal, setRenameDesignModal] = useState(false);
 const [renameDesignID, setRenameDesignID] = useState('');
 const [newDesignTypeName, setNewDesignTypeName] = useState('');
//  const [preNameRoomTypeName, setPreNameRoomTypeName] = useState('');
//  const [renameRoomTypeName, setRenameRoomTypeName] = useState('');


 const handleRenameDesignCloseModal = () => {
  setRenameDesignModal(false);
};

const renameDesignModalFun = (i) => {
  console.log("checking whole desig nData",i)
  // setNameRoomTypeName(i.roomTypeName);
  setNewDesignTypeName(i.roomTypeDesign);
  setRenameDesignID(i.ID);
  setRenameDesignModal(true);
  
};

const handleRenameDesignModal = () =>{
 const newRoomName = {
  cid : dataOfClient.ID,
  id: renameDesignID,
  roomTypeNewName: newDesignTypeName,
 }
 console.log("sanja",newRoomName);
  dispatch(renameDesignName(newRoomName));
  const viewData = {
    Id : dataOfClient.ID,
  };
  dispatch(fetchRoomList(viewData));
  // dispatch(fetchQuotationList(viewData));
  // dispatch(fetchRoomList(viewData));
  // dispatch(fetchProjectList());
  setRenameDesignModal(false);
}

const duplicationDesign = async (l) => {
  const designData = { ...l };
  designData.roomTypeDesign = l.roomTypeDesign + ' Copy';
  await dispatch(duplicateDesigns(designData));
  const viewData = {
    Id : dataOfClient.ID,
  };
  dispatch(fetchRoomList(viewData));
}

const handleCardClick = (e) => {
  if (!e.target.closest(".dropdown-menu")) {
    // Check if the clicked element is not inside a dropdown-menu
    // redirectToEditPage();
  }
};



// Delete Method for Rooms 

const deleteRoomModal = async (roomName) => {
  const querySnapshot = await db.collection("client").doc(dataOfClient.ID).collection("Rooms").where("roomTypeName", "==", roomName.roomTypeName).get();

  // Iterate over the documents in the query result and delete each one
  querySnapshot.forEach(doc => {
      doc.ref.delete();
  });  const viewData = {
    Id : dataOfClient.ID,
  };
  dispatch(fetchRoomList(viewData));
  dispatch(fetchQuotationList(viewData));
  // window.location.reload();
}



// Delete Method for Design Options 

const deleteDocument = async (id) => {
  await db.collection("client").doc(dataOfClient.ID).collection("Rooms").doc(id).delete();
  const viewData = {
    Id : dataOfClient.ID,
  };
  dispatch(fetchRoomList(viewData));
  dispatch(fetchQuotationList(viewData));
  // window.location.reload();
}

  return (
    <Container fluid>
    <Row>
    <Col sm={1} style={{backgroundColor:'#fdc771'}}>
    <Tab.Container id="left-tabs-example" defaultActiveKey="Projects" >
    <Row>
      <Col sm={1} style={{backgroundColor:'#fdc771'}}>
      <div style={{justifyContent:'center',justifyItems:'center',justifySelf:'center',}}>
      <img src={require('../assests/logo-black.png')} alt='Fabdiz' style={{width:30,height:30,alignContent:'center',alignItems:'center',alignSelf:'center'}}/>
      <p style={{fontSize:12}}>Fabdiz</p>
      </div>
        <Nav variant="tabs" className="flex-column">
          <Nav.Item>
            <Nav.Link eventKey="Dashboard" href='/dashboard'>
            <img src={require('../assests/dashboard.png')} alt='Dashboard' style={{width:25,height:25,alignContent:'center',alignItems:'center',alignSelf:'center'}}/>

            <p style={{color:'#000000',fontSize:12}}>Dashboard</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Projects" href='/projects'>
            <img src={require('../assests/projects.png')} alt='Projects' style={{width:25,height:25}}/>

           <p style={{color:'#000000',fontSize:12}}>Projects</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Assigned" href='/assigned'>
            <img src={require('../assests/assign.png')} alt='Assigned' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Assigned</p></Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey="Settings" href='/settings'>
            <img src={require('../assests/settings.png')} alt='Settings' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Settings</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Services" href='/services'>
            <img src={require('../assests/services.png')} alt='Services' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Services</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Team" href='/team'>
            <img src={require('../assests/team.png')} alt='Team' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Team</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Factory" href='/factory'>
            <img src={require('../assests/Factory.png')} alt='Factory' style={{width:25,height:25,alignSelf:'center'}}/>

            <p style={{color:'#000000',fontSize:12}}>Factory</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Inspiration" href='/inspiration'>
            <img src={require('../assests/inspiration.png')} alt='Inspiration' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Inspiration</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Render" href='/render'>
            <img src={require('../assests/render.png')} alt='Render' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Render</p></Nav.Link>
          </Nav.Item> */}
        </Nav>
      </Col>
    </Row>
  </Tab.Container>
      </Col>
    <Col sm={11}>
    <Row>
    <Col style={{
                  backgroundColor: "#f5f8f8",
                  display: "flex",
                  flexDirection: "row",
                  padding: "1%",
                  justifyContent: "space-between",
                }}>
                <Col sm={10}>
                <p style={{ fontSize: 20, fontWeight: "bold" }}>Projects</p>
                </Col>
              
                <Col sm={2}>
                  <img
                    src={require("../assests/notification.png")}
                    alt="Notification"
                    style={{ width: 26, height: 26 }}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle id="dropdown-autoclose-true">
                      <img
                        src={require("../assests/user.png")}
                        alt="User"
                        style={{ width: 40, height: 40 }}
                      />{" "}
                      &nbsp;&nbsp; {userData ? userData[0].name : "UNKNOWN"} &nbsp;
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <Link to={"/viewprofile"}>View Profile</Link>
                      </Dropdown.Item>
                      <Dropdown.Item href="#">FAQ</Dropdown.Item>
                      <Dropdown.Item  onClick={()=>handleLogOut()}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
            </Col>
      </Row>
      <Row className="p-4">
      <Row className="">
        <Breadcrumb>
            <Breadcrumb.Item href="/projects">Projects</Breadcrumb.Item>
            <Breadcrumb.Item active>{dataOfClient.pname}</Breadcrumb.Item>
        </Breadcrumb>
        </Row>
        { viewLoader ? <center> <Triangle
                    height="120"
                    width="120"
                    color="#ff5100"
                    ariaLabel="triangle-loading"
                    radius="1"
                    wrapperStyle={{position: "absolute",top: "50%",left: "50%",}}
                    wrapperClass=""
                    visible={true}
                  /> </center> : viewData ? <Row className="roomRow" >
        
        <Col className="col-md-9">
        <Row>
          <Col className="md-8">
          <h2  style={{fontSize:24,fontFamily:'Arial',fontWeight:100}}>Rooms</h2>

          </Col>
          <Col  className="md-4 d-flex flex-row-reverse">
          <Button className="w-50"  onClick={()=>addRoom()}>Add room</Button>

          </Col>
        </Row>
        <Row className="mt-3">
          <Col style={{minHeight:400}}>
          <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="0"
                          variant="pills"
                          justify
                        >
                          <Col sm={12}>
                            <Nav variant="pills" className="flex-row">
                            {result.map((i, ind)=> (
                              <Nav.Item>
                                <Nav.Link
                                  eventKey={ind}
                                  className="p-2 m-1 rounded-0"
                                > <Row><Col md={9} style={{width:100}}>{i.roomTypeName}</Col><Col md={3}> <Dropdown> 
                                <Dropdown.Toggle split as={CustomToggle} variant="light" id="dropdown-split-basic">
                                <i class="bi bi-three-dots-vertical" style={{color:'#AFAFAF'}}></i>
                                </Dropdown.Toggle>
                                
                                <Dropdown.Menu >
                                  <Dropdown.Item onClick={()=>renameModal(i)}>Rename Room</Dropdown.Item>
                                  <Dropdown.Item onClick={()=>addDesign(i)}>Add Design Option</Dropdown.Item>
                                  <Dropdown.Item onClick={()=>deleteRoomModal(i)}>Delete Room</Dropdown.Item>
                                  
                                  
                                </Dropdown.Menu>
                              </Dropdown></Col></Row>
                               
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                           
                            </Nav>
                          </Col>
                          <Col sm={12} style={{backgroundColor:'#e2e2e2'}} className="p-3">
                          
                            <Tab.Content>
                            
                            {result.map((i, ind)=> (
                              <Tab.Pane eventKey={ind}>
                             <Row className="my-3"><Col><h5>Design options for {i.roomType}  </h5></Col>
                             <Col className="md-4 d-flex flex-row-reverse"> 
                              <Button className="w-50"  onClick={()=>addDesign(i)}>Add design options</Button>
                              {/* <Button className="w-50"  onClick={()=>navigation('/projects/view-rooms/add-rooms', {state: dataOfClient})}>Add design options</Button> */}
                              </Col></Row>
                             <Row className="my-3">
                             <Col>
                             {viewData.map((l, index)=>l.roomTypeName  == i.roomTypeName ? ( 
                                <Card style={{ width: '17rem',float:'left'}} bg="light" className="m-1">
                                <Card.Body>
                                  <Card.Title>
                                  <Row><Col md={10}>{l.roomTypeDesign}</Col><Col md={2}>
                                  <Dropdown  className="w-100" >
                                <Dropdown.Toggle split as={CustomToggle} variant="light" id="dropdown-split-basic" >
                                <i class="bi bi-three-dots-vertical"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={()=>renameDesignModalFun(l)}>Rename</Dropdown.Item>
                                  <Dropdown.Item onClick={()=>deleteDocument(l.ID)}>Delete</Dropdown.Item>
                                  <Dropdown.Item onClick={()=>duplicationDesign(l)}>Duplicate</Dropdown.Item>
                                  <Dropdown.Item onClick={()=>viewDesign(l)}>View</Dropdown.Item>
                                  <Dropdown.Item onClick={()=>editDesign(l)}>Edit</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                                  </Col></Row>
                                 
                                  </Card.Title>
                                  <Card.Subtitle className="mb-2 text-muted">{'\u20B9'} {l.roomTypePrice}</Card.Subtitle>
                                  {/* <Card.Text> */}
                                    {/* 5 components
                                    5 Loft
                                  </Card.Text> */}
                                  
                                </Card.Body>
                              </Card>) : null)}  
                             </Col>
                              </Row>
                             
                             
                              </Tab.Pane>
                              ))}
                              
                              </Tab.Content>
                              
                              </Col>
                              </Tab.Container>
 
          </Col>
        </Row>
        <Row className="mt-5 ">
          <Col className="md-8">
          <h2  style={{fontSize:24,fontFamily:'Arial',fontWeight:100}}>Quotations</h2>

          </Col>
          <Col className="md-4 d-flex flex-row-reverse">
          <Button className="w-50 align-self-end"  onClick={()=>navigation('/projects/view-rooms/add-quotation', {state: dataOfClient})}>Add Quotations</Button>

          </Col>
        </Row>
        <Row className="mt-1">
          <Col>
          <Table responsive hover >
            <thead className="table-warning">
              <tr>
              
                <th></th>
                <th>Quote ID</th>
                <th>Name</th>
                <th>Create</th>
                <th>Status</th>
                <th>Total (Rs.)</th>
                <th></th>
                <th>Actions</th>
                <th></th>
                
              </tr>
            </thead>
            <tbody className="table-light">
            {quoteData ? quoteData.map((e)=>(
              <tr>
                
                <td></td>
                <td>{e.ID}</td>
                <td>{e.quotationName}</td>
                <td>
                 {e.quotationDate}
                </td>
                <td><p  style={{backgroundColor:'#4caf50',borderRadius:15,textAlign:'center',color:'#FFFFFF',fontSize:15}}>Submitted</p></td>
                <td>
                {'\u20B9'} {e.quotationPrice}
                </td>
                <td>
                  {/* <Button variant="primary" >D/L Xlsx</Button> */}
                  {/* <ExportToExcel e={e}/> */}
                  <ViewProfile e={e} /> 
                  {/* <ExportExcel /> */}
                </td>
                <td>
                  {/* <Button variant="primary" onClick={()=>{navigation('/projects/view-rooms')}}>Download</Button> */}
                  <PDFDownloadLink
                    document={<ClientPDF e={e}/>}
                    fileName={fileName}
                   className="btn btn-primary">
                    {({ blob, url, loading, error }) =>
                      loading ? "Loading..." : "D/L PDF"
                    }
                 </PDFDownloadLink>
                 {/* <PDFViewer width={800} height={500} showToolbar={false}>
        <ClientPDF e={e} />
      </PDFViewer> */}
                </td>
                <td>
                {/* <Button variant="primary" onClick={()=>{navigation('/client-quotation')}}>Download</Button> */}
                 {/* <a href={`/client-quotation/${e.ID}`}  target="_blank"><Button>Open / Share</Button></a>  */}
                  <a href={`/client-quotation?cqifds=${e.ID}&cqid=${e.clientDetails.ID}`}  target="_blank"><Button>Open / Share</Button></a>
                </td>
              </tr>
          )) : null}
               
               
            </tbody>
          </Table>
      
          </Col>
        </Row>
        </Col>
        <Col className="col-md-3" style={{borderLeftWidth:1,borderLeftColor:'#e2e2e2',borderLeftStyle:'solid'}}>

        </Col>
        
        
          </Row>: 
          <Row className="addRow" >
        <Col className="">
        {/* <img src={require("../assests/add.png")}  alt='Dashboard' style={{ width: '50%',height:'50%', resize:'cover' }} /> */}
            <p style={{fontSize:36,fontFamily:'Arial',fontWeight:100}}>You don’t have any rooms yet</p>
        <Button className="w-25"  onClick={()=>navigation('/projects/view-rooms/add-rooms', {state: dataOfClient})}>Add room</Button>
        </Col>
        
          </Row>}
        

          <Modal show={renameOn} onHide={handleRenameCloseOn}>
            <Modal.Body>
              {/* <Modal.Title style={{ fontSize: 15, fontWeight: "bold" }}>
                Do you Rename?
              </Modal.Title> */}
              <Form>
              <Form.Control
                type="text"
                value={nameRoomTypeName}
                onChange={(e)=>{setNameRoomTypeName(e.target.value)}}        
                 autoFocus
              />
            
          </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>handleRenameCloseOn()}>
                Cancel
              </Button>
              <Button variant="primary" onClick={()=>handleRenameCom()}>Rename</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={renameDesignModal} onHide={handleRenameDesignCloseModal}>
            <Modal.Body>
              {/* <Modal.Title style={{ fontSize: 15, fontWeight: "bold" }}>
                Do you Rename?
              </Modal.Title> */}
              <Form>
              <Form.Control
                type="text"
                value={newDesignTypeName}
                onChange={(e)=>{setNewDesignTypeName(e.target.value)}}        
                 autoFocus
              />
            
          </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={()=>handleRenameDesignCloseModal()}>
                Cancel
              </Button>
              <Button variant="primary" onClick={()=>handleRenameDesignModal()}>Rename</Button>
            </Modal.Footer>
          </Modal>
       
        
      </Row>      
    
    </Col>
    </Row>
 
    
  
    </Container>
  );
}
export default ProjectView;
