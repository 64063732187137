import React,{useState} from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    ButtonGroup,
    Dropdown,
    Modal,
    Form,
    Table,
    Nav,
    Tab,
    Tabs,
    Card,
    Breadcrumb,
    FormCheck
  } from "react-bootstrap";

  import { auth } from "../config/Config";
  import { Link } from "react-router-dom";
  import { useNavigate, useLocation } from "react-router-dom";
  import { useSelector, useDispatch } from "react-redux";
  import CheckboxGroup from 'react-checkbox-group'
import { current } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { RowSelection } from './RowSelection';
import { createQuotations } from '../redux/addQuotationReducer';

export default function AddQuotation() {

    const navigation = useNavigate();
    const location = useLocation();
    const data = location.state;
    const dispatch = useDispatch();
    
    const [value, setValue] = useState([]);
    const [sta, setSta] = useState(false);
    const [inputTitle, setInputTitle] = useState('');
    const [inputBase, setInputBase] = useState(0);
    const [inputDiscount, setInputDiscount] = useState(0);

    const { viewData, viewLoader, viewError} = useSelector((state)=>state.viewrooms);
    const { userData, userLoader, userError} = useSelector((state)=>state.viewusers);
    
  

    const result = viewData.reduce((finalArray, current) => {
      let obj = finalArray.find((item) => item.roomTypeName === current.roomTypeName);
      if(obj){
        return finalArray;
      }
      
        return finalArray.concat([current]);
      
    }, []);
 

  

    const handleLogOut =()=>{
        auth.signOut();
        // navigation('/login');
      }


  const [userinfo, setUserInfo] = useState([{
    languages: [],
    response: [],
  }]);
  const [peopleInfo, setPeopleInfo] = useState([]);
  // let calculationInfo  = 0;
  const [calculationWood, setCalculationWood] = useState(0);
  const [calculationAccess, setCalculationAccess] = useState(0);
  const [calculationInfo, setCalculationInfo] = useState(0);
  const [selectedDesigns, setSelectedDesigns] = useState({});

  const handleChange = (e, roomTypeName, roomType) => {
    // Destructuring
    console.log('checing data1212', roomType);
    let totalPriceAccessories = 0;
    let totalPriceWood = 0;
    const { id, value, checked } = e.target;
    console.log('checing data', value);
    const { languages } = userinfo;
    let quoteChecked = [];
    // console.log(`${value} is ${checked}`);

    setSelectedDesigns({
      ...selectedDesigns,
      [roomTypeName]: checked ? id : null
    });
     
    // Case 1 : The user checks the box
    if (checked) {
      peopleInfo.push(id);
      setCalculationInfo(calculationInfo + 1);
   
    }
  
    // Case 2  : The user unchecks the box
    else {
      for( var i = 0; i < peopleInfo.length; i++){ 
                                   
        if ( peopleInfo[i] === id) { 
          peopleInfo.splice(i, 1); 
          setCalculationInfo(calculationInfo - 1);
            // i--; 
        }
    }


   
    }

   

    for( var i = 0; i < peopleInfo.length; i++){ 
      for( var j = 0; j < viewData.length; j++){
       if ( peopleInfo[i] == viewData[j].ID) { 
        quoteChecked.push(viewData[j].roomTypePrice); 
        if (viewData[j].roomType !== "Accessories" || viewData[j].roomType !== "Loose Furniture" || viewData[j].roomType !== "Other Works" || viewData[j].roomType !== "ExtraItems") {
         
          totalPriceWood += viewData[j].roomTypeWoodPrice;
         
        }
           // j--; 
       }
      }                      
     //  i--;
}

// reducing the price in a single value

// Check the room type and update the corresponding variable

setCalculationWood(totalPriceWood);
console.log("wood2",calculationWood);

const reducerOfPrice = (accumulator,currentValue)=>accumulator+currentValue;

const totalPrice = quoteChecked.reduce(reducerOfPrice,0);

setCalculationInfo(totalPrice);
    // console.log("sdf",peopleInfo);
    // console.log("cccf",totalPrice);
   
  };




  const quoteDate = new Date().toLocaleDateString();

    const handleFinalQuotation = (e) =>{
      e.preventDefault();
      console.log(e.target.value);

      let quotationData = [];
      let quotationWood = 0;
    
      // quotation checkbox data
      
      
      for( var i = 0; i < peopleInfo.length; i++){ 
             for( var j = 0; j < viewData.length; j++){
              if ( peopleInfo[i] == viewData[j].ID) { 
                quotationData.push(viewData[j]);
                console.log("Updating calculationKitchen",viewData[j]);
                if (viewData[j].roomType === "Kitchen" || viewData[j].roomType === "Bedroom" || viewData[j].roomType === "Foyer" || viewData[j].roomType === "Living Room") {
                  console.log("Updating calculationKitchen");

                  quotationWood += viewData[j].roomTypeWoodPrice;
                  console.log("Updatingn",quotationWood);
                  setCalculationWood(quotationWood);

                
                }
               
                  // j--; 
              }
             }                      
            //  i--;
    }

    const quoteData = {
      
      quotationData : quotationData,
     clientDetails : data,
     userDetials : userData,
     quotationName : inputTitle,
     quotationBase : inputBase,
     quotationDiscount : inputDiscount,
     quotationDate : quoteDate,
     quotationWood : calculationWood,
     quotationPrice : calculationInfo, 
      id : data.ID,
      timestamp: new Date(),
  
    };

    dispatch(createQuotations(quoteData));
    navigation("/projects/view-rooms", { state: data });
    }



  
    

 
  return (
    <Container fluid>
    <Row>
    <Col sm={1} style={{backgroundColor:'#fdc771'}}>
    <Tab.Container id="left-tabs-example" defaultActiveKey="Projects" >
    <Row>
      <Col sm={1} style={{backgroundColor:'#fdc771'}}>
      <div style={{justifyContent:'center',justifyItems:'center',justifySelf:'center',}}>
      <img src={require('../assests/logo-black.png')} alt='Fabdiz' style={{width:30,height:30,alignContent:'center',alignItems:'center',alignSelf:'center'}}/>
      <p style={{fontSize:12}}>Fabdiz</p>
      </div>
        <Nav variant="tabs" className="flex-column">
          <Nav.Item>
            <Nav.Link eventKey="Dashboard" href='/dashboard'>
            <img src={require('../assests/dashboard.png')} alt='Dashboard' style={{width:25,height:25,alignContent:'center',alignItems:'center',alignSelf:'center'}}/>

            <p style={{color:'#000000',fontSize:12}}>Dashboard</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Projects" href='/projects'>
            <img src={require('../assests/projects.png')} alt='Projects' style={{width:25,height:25}}/>

           <p style={{color:'#000000',fontSize:12}}>Projects</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Assigned" href='/assigned'>
            <img src={require('../assests/assign.png')} alt='Assigned' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Assigned</p></Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey="Settings" href='/settings'>
            <img src={require('../assests/settings.png')} alt='Settings' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Settings</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Services" href='/services'>
            <img src={require('../assests/services.png')} alt='Services' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Services</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Team" href='/team'>
            <img src={require('../assests/team.png')} alt='Team' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Team</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Factory" href='/factory'>
            <img src={require('../assests/Factory.png')} alt='Factory' style={{width:25,height:25,alignSelf:'center'}}/>

            <p style={{color:'#000000',fontSize:12}}>Factory</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Inspiration" href='/inspiration'>
            <img src={require('../assests/inspiration.png')} alt='Inspiration' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Inspiration</p></Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="Render" href='/render'>
            <img src={require('../assests/render.png')} alt='Render' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Render</p></Nav.Link>
          </Nav.Item> */}
        </Nav>
      </Col>
    </Row>
  </Tab.Container>
      </Col>
    <Col sm={11}>
    <Row>
    <Col style={{
                  backgroundColor: "#f5f8f8",
                  display: "flex",
                  flexDirection: "row",
                  padding: "1%",
                  justifyContent: "space-between",
                }}>
                <Col sm={10}>
                <p style={{ fontSize: 20, fontWeight: "bold" }}>Projects</p>
                </Col>
              
                <Col sm={2}>
                  <img
                    src={require("../assests/notification.png")}
                    alt="Notification"
                    style={{ width: 26, height: 26 }}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Dropdown className="d-inline mx-2">
                    <Dropdown.Toggle id="dropdown-autoclose-true">
                      <img
                        src={require("../assests/user.png")}
                        alt="User"
                        style={{ width: 40, height: 40 }}
                      />{" "}
                      &nbsp;&nbsp; {userData ? userData[0].name : "UNKNOWN"} &nbsp;
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href="#">
                        <Link to={"/viewprofile"}>View Profile</Link>
                      </Dropdown.Item>
                      <Dropdown.Item href="#">FAQ</Dropdown.Item>
                      <Dropdown.Item  onClick={()=>handleLogOut()}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
            </Col>
      </Row>
      <Row className="p-4">
      <Row className="">
        <Breadcrumb>
            <Breadcrumb.Item href="/projects">Projects</Breadcrumb.Item>
            <Breadcrumb.Item onClick={() =>
                    navigation("/projects/view-rooms", { state: data })
                  }
                >
                  {data.pname}</Breadcrumb.Item>
            <Breadcrumb.Item active>Add Quotation</Breadcrumb.Item>
        </Breadcrumb>
        </Row>
                  <Form onSubmit={handleFinalQuotation}>
       
        {/* <Row>
        <RowSelection />
        </Row> */}
        {result.map((i, ind)=> (
           <Row key={i.ID}> 
          <Row className="mt-5 ">
          <Col className="md-12">
          <h2  style={{fontSize:24,fontFamily:'Arial',fontWeight:100}}>{i.roomTypeName}</h2>
         
          </Col>
         
        </Row>
        <Row className="mt-1">
          <Col>
          
          <Table responsive hover >
            <thead className="table-warning">
              <tr>
              
                <th></th>
                <th>Design Option</th>
                <th></th>
                <th>Components</th>
                
                <th>Addtional H/W</th>
                <th>Total</th>
                
                
              </tr>
            </thead>
            <tbody className="table-light">
            {viewData.map((l, index)=>l.roomTypeName  == i.roomTypeName ? ( 
       
           <tr key={l.ID}>
                
                <td>
                  <input type="checkbox" id={l.ID} name={l.ID} value={l.roomTypePrice} onChange={(e)=> handleChange(e, l.roomTypeName, l.roomType)} />
                  {/* <input 
            type="radio" 
            id={l.ID} 
            name={l.roomType} 
            value={l.roomTypePrice} 
            onChange={(e) => handleChange(e)} 
          /> */}
          
           {/* <input 
            type="radio" 
            id={l.ID} 
            name={`designOption-${i.roomTypeName}`} 
            value={l.roomTypePrice} 
            onChange={(e) => handleChange(e)} 
          /> */}
          {/* <input 
        type="checkbox" 
        id={l.ID} 
        name={l.ID} 
        value={l.roomTypePrice}
        checked={selectedDesigns[l.roomTypeName] === l.ID} // Check if this design is the selected one
        onChange={(e) => handleChange(e, l.roomTypeName)}
      /> */}
                </td>
                <td>
                {l.roomTypeDesign}
                </td>
                <td></td>
                <td>
                {'\u20B9'} {l.roomTypePrice}
                </td>
                <td>
                  -
                </td>
                <td>
                {'\u20B9'} {l.roomTypePrice}
                </td>
              </tr>) : null)}
          
                
               
            </tbody>
          </Table>
          </Col>
         
        </Row>
        </Row>
        ) )}

        <Row className="mt-5" >
        
        <Col className="md-4 p-3 justify-content-center" style={{backgroundColor:'#a0efff'}}>
        <h2  style={{fontSize:24,fontFamily:'Arial',fontWeight:100}}>Total : ₹ {calculationInfo}</h2>

        </Col>
        {/* <Col className="md-4 p-3 justify-content-center" style={{backgroundColor:'#a0efff'}}> */}
        {/* <h2  style={{fontSize:24,fontFamily:'Arial',fontWeight:100}}>Title (Mandatory)</h2> */}
        {/* <Form.Control type="text" placeholder="Base" className="w-75" name='QuotationTitle' onChange={(e)=>setInputBase(e.target.value)}/> */}

        {/* </Col> */}
        <Col className="md-4 p-3 justify-content-center" style={{backgroundColor:'#a0efff'}}>
        {/* <h2  style={{fontSize:24,fontFamily:'Arial',fontWeight:100}}>Title (Mandatory)</h2> */}
        <Form.Control type="text" placeholder="Discount" className="w-75" name='QuotationTitle' onChange={(e)=>setInputDiscount(e.target.value)}/>

        </Col>
        <Col className="md-4 p-3 justify-content-center" style={{backgroundColor:'#a0efff'}}>
        {/* <h2  style={{fontSize:24,fontFamily:'Arial',fontWeight:100}}>Title (Mandatory)</h2> */}
        <Form.Control type="text" placeholder="Title (Mandatory)*" className="w-75" name='QuotationTitle' onChange={(e)=>setInputTitle(e.target.value)}/>

        </Col>
        <Col className="md-4 p-3 justify-content-center" style={{backgroundColor:'#a0efff'}}>
        <Button className="w-75 p-2 rounded-5" style={{fontSize:20,fontWeight:600}} disabled={false} type="submit">Create</Button>

        </Col>
       
      </Row>
        </Form>
      </Row>      
     
    </Col>
    </Row>
 
    
  
    </Container>
  )
}

