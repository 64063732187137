import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Breadcrumb,
  Tab,
  Nav,
  Form,
  Modal,
  Accordion,
} from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { firestore as db } from "../config/Config";
import { useLocation } from "react-router-dom";
import { Collapse } from "bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { fetchKitchenList } from "../redux/kitchenReducer";
import { useNavigate } from "react-router-dom";
import { auth } from "../config/Config";
import { createRooms } from "../redux/addRoomReducer";


export default function AddRooms({ clientDataFor }) {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const data = location.state;

  console.log("clients data", data);

  const { userData, userLoader, userError} = useSelector((state)=>state.viewusers);

  // useEffect(() => {
  //   dispatch(fetchKitchenList());
  // }, []);

  // const { looseThingsData } = useSelector(
  //   (state) => state.looseThings
  // );

  // console.log("sdfsdf", kitchenThingsData);


  // Room Type 

  const [roomType, setRoomType] = useState(data['roomType']);
  const [roomTypeName, setRoomTypeName] = useState(data['roomName']);
  const [roomTypeDesign, setRoomTypeDesign] = useState("");
  const [roomTypeDescription, setRoomTypeDescription] = useState("");
  const [roomTypeModal, setRoomTypeModal] = useState('');
  const [roomTypePrice, setRoomTypePrice] = useState(0);
  const [roomTypeWoodPrice, setRoomTypeWoodPrice] = useState(0);
  

  const [defkey, setDefkey] = useState('room');

  const handleRoomTypeCancel = () => {
    setRoomType("");
    setRoomTypeName("");
    setRoomTypeDesign("");
    setRoomTypeDescription("");

    navigation("/projects/view-rooms", { state: data })

  }

  const handleRoomTypeSubmit = (e) => {
    e.preventDefault();
    const roomData = [{ 'roomType': roomType }, { 'roomTypeName': roomTypeName }, { 'roomTypeDesign': roomTypeDesign }, { 'roomTypeDescription': roomTypeDescription }];
    localStorage.setItem("roomTypeData", JSON.stringify(roomData));

  }

  // Base cabinet modal things & all

  const [addShow, setAddShow] = useState(false);
  const [roomData, setRoomData] = useState([]);


  // Material things

  const [kitchenBaseGrandTotal, setKitchenBaseGrandTotal] = useState(0);

  const [materialType, setMaterialType] = useState("");
  const [materialLabel, setMaterialLabel] = useState(0);
  const [materialValue, setMaterialValue] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [modalUrl, setModalUrl] = useState("");
  const [materialWidth, setMaterialWidth] = useState(0);
  const [materialHeight, setMaterialHeight] = useState(0);
  // const [materialDepth, setMaterialDepth] = useState(2);
  const [materialQuantity, setMaterialQuantity] = useState(0);
  const [materialPrice, setMaterialPrice] = useState(0);
  const handleFistItem = (name) => {
    setModalTitle(name.label);
    setModalUrl(name.url);
  };
  const handleCalculation = (e) => {
    setMaterialPrice(
      materialType * materialWidth * materialHeight * materialQuantity
    );
  };




  const handleLogOut = () => {
    auth.signOut();
    // navigation('/login');
  }

  // writting code for dynamic fields of inputs

  const [selectedItems, setSelectedItems] = useState([]);

  const handleQuantityChange = (itemId, newQuantity) => {
    const updatedItems = selectedItems.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          selectedQuantity: newQuantity,
        };
      }
      return item;
    });

    setSelectedItems(updatedItems);
  };


  
  // Save Room types and naem

  const handleSaveNameType = () => {
    // alert('everything');

    const roomDatas = {

      title: '',
      fields: '',
      roomType: roomType,
      roomTypeName: roomTypeName,
      roomTypeDesign: roomTypeDesign,
      roomTypeDescription: roomTypeDescription,
      roomTypePrice: 0,
      roomTypeWoodPrice: 0,
      id: data.ID,
    };


    dispatch(createRooms(roomDatas));
    navigation("/projects/view-rooms", { state: data });
  }

  // Save Everything Calculation & Headings

  const handleSaveEverything = () => {
    // alert('everything');

    const roomDatas = {

      // title : [{'accordin' : [modalTitle, modalUrl, kitchenBaseGrandTotal]},{'material' : [materialType, materialWidth, materialHeight, materialQuantity, materialPrice]},{'baseHandleType' : [accessoriesBaseHandleType, accessoriesBaseHandleQuantity, accessoriesBaseHandlePrice]},{'thaliBasket' : [accessoriesThaliBasketType, accessoriesThaliBasketQuantity, accessoriesThaliBasketPrice]},{'thaliInlet' : [accessoriesThaliInletType, accessoriesThaliInletQuantity, accessoriesThaliInletPrice]},{'dustbinBasic' : [accessoriesDustbinBasicType, accessoriesDustbinBasicQuantity, accessoriesDustbinBasicPrice]},{'wickerBasket' : [accessoriesWickerBasketBasicType, accessoriesWickerBasketBasicQuantity, accessoriesWickerBasketBasicPrice]},{'detergentHolder' : [accessoriesDetergentHolderBasicType, accessoriesDetergentHolderBasicQuantity, accessoriesDetergentHolderBasicPrice]}],
      title: roomData,
      roomType: roomType,
      roomTypeName: roomTypeName,
      roomTypeDesign: roomTypeDesign,
      roomTypeDescription: roomTypeDescription,
      roomTypePrice: kitchenBaseGrandTotal,
      id: data.ID,
    };


    dispatch(createRooms(roomDatas));
    navigation("/projects/view-rooms", { state: data });
  }

  // dynamic form fields and data

  const [dynamicData, setDynamicData] = useState([{ fname: "", lname: "" }]);

  const handleAddClick = () => {
    setDynamicData([...dynamicData, { fname: "", lname: "" }])
  }

  const handleAddChange = (e, i) => {
    const { name, value } = e.target;
    const onchangeVal = [...dynamicData];
    onchangeVal[i][name] = value;
    setDynamicData(onchangeVal);
  }

 

  
  

  // dynamic for kitchen furniture 

const { kitchenThingsData } = useSelector(
  (state) => state.kitchenThings
);

 //dynamic for extra items in every room
const [extraItemMeasure, setExtraItemMeasure] = useState('');
const [extraItemName, setExtraItemName] = useState('');
const [extraItemMaterialName, setExtraItemMaterialName] = useState('');
const [extraItemMaterialValue, setExtraItemMaterialValue] = useState(0);
const [isChecked, setIsChecked] = useState(false);
const [extraItemType, setExtraItemType] = useState('');

const handleCheckboxChange = (event) => {
  setIsChecked(event.target.checked);
  // You can set the data based on the checkbox state here
  setExtraItemType(event.target.checked ? 'woodwork' : '');
};

const [kitchenThingsDataCopy, setKitchenThingsDataCopy] = useState(kitchenThingsData);
const [kitchenFormData, setKitchenFormData] = useState(
  kitchenThingsDataCopy.map((item) => ({
  name: item.name,
  label: "",
  value: 0,
  valueIncresed: 0,
  width:"",
  height: "",
  qnty: "",
  rft: "",
  area:"",
  price:0,
  remark: "",
  type: item.type,

}))
);

const addNewKitchenItem = () => {

  setKitchenThingsDataCopy([
    ...kitchenThingsDataCopy,
    {
      name: extraItemName,
      measurement: extraItemMeasure,
      material: [{ label: extraItemMaterialName, value: extraItemMaterialValue }],
      type: extraItemType,
      isUserAdded: true,
    }, 
  ]);
  console.log("data for copy kicthen data",kitchenThingsDataCopy);

  setKitchenFormData([
    ...kitchenFormData,
    {
      name: extraItemName,
      label: "",
      value: 0,
      valueIncresed: 0,
      width:"",
      height: "",
      qnty: "",
      rft: "",
      area:"",
      price:0,
      remark: "",
      type: extraItemType,
      isUserAdded: true,
    }, 
  ]);
  setExtraItemMeasure('');
  setExtraItemName('');
  setExtraItemMaterialName('');
  setExtraItemMaterialValue(0);
  setExtraItemType('');
  console.log("Kitchen Nwe data",kitchenFormData);
};




const handleKitchenChange = (e, index, field) => {
  const newValue = parseFloat(e.target.value);
  // const newValue = e.target.value;
  const updatedKitchenFormData = [...kitchenFormData];
  const item = updatedKitchenFormData[index];
  const incrementPercentage = data['increment'] / 100; 

  if (kitchenThingsDataCopy[index].measurement === "area") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * newValue * (1 + incrementPercentage));
      // setRoomTypeWoodPrice(prevPrice => prevPrice + item.price);

    } else {
      item.price = Math.round(item.value * newValue);
      
    }
  } else if (kitchenThingsDataCopy[index].measurement === "width") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * item.width * item.height * item.qnty * (1 + incrementPercentage));
      // roomTypeWoodPrice += item.price;
      // setRoomTypeWoodPrice(prevPrice => prevPrice + item.price);

    } else {
      item.price = Math.round(item.value * item.width * item.height * item.qnty);
   
    }
  } else if (kitchenThingsDataCopy[index].measurement === "qty") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * newValue * (1 + incrementPercentage));
      // setRoomTypeWoodPrice(prevPrice => prevPrice + item.price);

    } else {
      item.price = Math.round(item.value * newValue);

    }
  } else if (kitchenThingsDataCopy[index].measurement === "length") {
    item[field] = newValue;  
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * item.height * item.width * (1 + incrementPercentage));
      // setRoomTypeWoodPrice(prevPrice => prevPrice + item.price);

    } else {
      item.price = Math.round(item.value * item.height * item.width);
    
    }
  } else if (kitchenThingsDataCopy[index].measurement === "rft") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * newValue * (1 + incrementPercentage));
      // setRoomTypeWoodPrice(prevPrice => prevPrice + item.price);

    } else {
      item.price = Math.round(item.value * newValue);
   
    }
  }

  // Update the state with the modified data
  updatedKitchenFormData[index] = item;
  setKitchenFormData(updatedKitchenFormData);
  console.log("price checking", kitchenThingsDataCopy);
};


const handleKitchenPriceChange = (e, index, field) => {
  const newValue = parseFloat(e.target.value);
  // const newValue = e.target.value;
  const updatedKitchenFormData = [...kitchenFormData];
  const item = updatedKitchenFormData[index];
  item[field] = newValue;
  // Update the state with the modified data
  updatedKitchenFormData[index] = item;
  setKitchenFormData(updatedKitchenFormData);
  // console.log("price checking", kitchenFormData);
};

const handleKitchenRemarkChange = (e, index, field) => {
  // const newValue = parseFloat(e.target.value);
  const newValue = e.target.value;
  const updatedKitchenFormData = [...kitchenFormData];
  const item = updatedKitchenFormData[index];
  item[field] = newValue;
  // Update the state with the modified data
  updatedKitchenFormData[index] = item;
  setKitchenFormData(updatedKitchenFormData);
  // console.log("price checking", kitchenFormData);
};




const handleKitchenOptionChange = (e, index) => {
  const value = e.target.value;
  const selectedLabel = e.target.options[e.target.selectedIndex].text;
  const incrementPercentage = data['increment'] / 100;

  setKitchenFormData((prevData) => {
    const newData = [...prevData];
    newData[index]['value'] = value; 
    newData[index]['valueIncresed'] = Math.round(value * (1 + incrementPercentage));
    newData[index]['label'] = selectedLabel;

    // Recalculate the price based on the selected value
    const item = newData[index];
    if (kitchenThingsDataCopy[index].measurement === "area") {
      
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.area * (1 + incrementPercentage));
        // setRoomTypeWoodPrice(prevPrice => prevPrice + item.price);

      } else {
        item.price = Math.round(value * item.area);
       
      }
    } else if (kitchenThingsDataCopy[index].measurement === "width") {
      
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.width * item.height * item.qnty * (1 + incrementPercentage));
        // setRoomTypeWoodPrice(prevPrice => prevPrice + item.price);

      } else {
        item.price = Math.round(value * item.width * item.height * item.qnty);
     
      }
    } else if (kitchenThingsDataCopy[index].measurement === "qty") {
     
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.qnty * (1 + incrementPercentage));
        // setRoomTypeWoodPrice(prevPrice => prevPrice + item.price);

      } else {
        item.price = Math.round(value * item.qnty);
     
      }
    } else if (kitchenThingsDataCopy[index].measurement === "length") {
     
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.height * item.width * (1 + incrementPercentage));
        // setRoomTypeWoodPrice(prevPrice => prevPrice + item.price);

      } else {
        item.price = Math.round(value * item.height * item.width);
      
      }
    } else if (kitchenThingsDataCopy[index].measurement === "rft") {
     
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.rft * (1 + incrementPercentage));
        // setRoomTypeWoodPrice(prevPrice => prevPrice + item.price);

      } else {
        item.price = Math.round(value * item.rft);
     
      }
    }

    return newData;
  });
};



// const calculateKitchenTotal = () => {
//   let newTotal = 0;
//   for (let i = 0; i < kitchenFormData.length; i++) {
//     newTotal += parseInt(kitchenFormData[i].price);
//   }
//   return newTotal;
// };

// useEffect(() => {
//   const newKitchenTotal = calculateKitchenTotal();
//   console.log("kitchenPrice", newKitchenTotal);
//   setRoomTypePrice(newKitchenTotal);
// }, [kitchenFormData]); // This will update the total whenever formData changes.


// to calculate Total Price
// useEffect(() => {
//   // Calculate total price
//   const totalPrice = kitchenFormData.reduce((total, item) => {
//     return total + parseInt(item.price);
//   }, 0);

//   // Update roomTypeWoodPrice
//   setRoomTypePrice(totalPrice);
// }, [kitchenFormData]);




// // to calculate wood work only
// useEffect(() => {
//   // Calculate total woodwork price
//   const totalWoodworkPrice = kitchenFormData.reduce((total, item) => {
//     if (item.type === "woodwork") {
//       return total + item.price;
//     }
//     return total;
//   }, 0);

//   // Update roomTypeWoodPrice
//   setRoomTypeWoodPrice(totalWoodworkPrice);
// }, [kitchenFormData]);




useEffect(() => {
  // Recalculate the total price
  const totalPrice = kitchenFormData.reduce((total, item) => total + item.price, 0);
  setRoomTypePrice(totalPrice);

  // Recalculate the woodwork price
  const totalWoodworkPrice = kitchenFormData.reduce(
    (total, item) => item.type === 'woodwork' ? total + item.price : total,
    0
  );
  setRoomTypeWoodPrice(totalWoodworkPrice);
}, [kitchenFormData]); // Recalculate when kitchenFormData changes



const saveKitchenData = async () => {

  
  const roomDatas = {

    title: kitchenFormData,
    title2: materialFields,
    fields: kitchenThingsDataCopy,
    roomType: roomType,
    roomTypeName: roomTypeName,
    roomTypeDesign: roomTypeDesign,
    roomTypeDescription: roomTypeDescription,
    roomTypePrice: roomTypePrice,
    roomTypeWoodPrice: roomTypeWoodPrice,
    roomTypePrice2: materialFieldsTotal,
    id: data.ID,
  };


 await dispatch(createRooms(roomDatas));
 setRoomTypeWoodPrice(0);
  navigation("/projects/view-rooms", { state: data });
} 



const deleteUserAddedKitchenItem = (index) => {
  // Only delete if the item is user-added
  if (kitchenThingsDataCopy[index].isUserAdded) {
    const updatedKitchenThingsDataCopy = [...kitchenThingsDataCopy];
    const updatedKitchenFormData = [...kitchenFormData];

    // Remove the item from the list and the form data
    updatedKitchenThingsDataCopy.splice(index, 1);
    updatedKitchenFormData.splice(index, 1);

    // Update the state
    setKitchenThingsDataCopy(updatedKitchenThingsDataCopy);
    setKitchenFormData(updatedKitchenFormData);
  }
};




// dynamic for Bedroom furniture 

const { bedroomThingsData } = useSelector(
  (state) => state.bedroomThings
);



const [bedroomThingsDataCopy, setBedroomThingsDataCopy] = useState(bedroomThingsData);

const [bedroomFormData, setBedroomFormData] = useState(
  bedroomThingsDataCopy.map((item) => ({
  name: item.name,
  label: "",
  value: 0,
  valueIncresed: 0,
  label2: "",
  value2: 0,
  valueIncresed2: 0,
  width:"",
  height: "",
  qnty: "",
  rft:"",
  area:"",
  price:0,
  remark: "",
  type:item.type,
}))
);

const addNewBedroomItem = () => {

  setBedroomThingsDataCopy([
    ...bedroomThingsDataCopy,
    {
      name: extraItemName,
      measurement: extraItemMeasure,
      material: [{ label: extraItemMaterialName, value: extraItemMaterialValue }],
      type: extraItemType,
      isUserAdded: true,
    }, 
  ]);
  console.log("data for copy kicthen data",bedroomThingsDataCopy);

  setBedroomFormData([
    ...bedroomFormData,
    {
      name: extraItemName,
      label: "",
      value: 0,
      valueIncresed: 0,
      label2: "",
      value2: 0,
      valueIncresed2: 0,
      width:"",
      height: "",
      qnty: "",
      rft: "",
      area:"",
      price:0,
      remark: "",
      type: extraItemType,
      isUserAdded: true,
    }, 
  ]);
  setExtraItemMeasure('');
  setExtraItemName('');
  setExtraItemMaterialName('');
  setExtraItemMaterialValue(0);
  setExtraItemType('');
  // console.log("Kitchen Nwe data",kitchenFormData);
};


const handleBedroomChange = (e, index, field) => {
  const newValue = parseFloat(e.target.value);
  // const newValue = e.target.value;
  const updatedBedroomFormData = [...bedroomFormData];
  const item = updatedBedroomFormData[index];


  const incrementPercentage = data['increment'] / 100; 

  if (bedroomThingsDataCopy[index].measurement === "area") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * newValue * (1 + incrementPercentage));
    } else {
      item.price = Math.round(item.value * newValue);
    }
  } else if (bedroomThingsDataCopy[index].measurement === "width") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * item.width * item.height * item.qnty * (1 + incrementPercentage));
    } else {
      item.price = Math.round(item.value * item.width * item.height * item.qnty );
    }
  } else if (bedroomThingsDataCopy[index].measurement === "qty") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * newValue * (1 + incrementPercentage));
    } else {
      item.price = Math.round(item.value * newValue );
    }
  } else if (bedroomThingsDataCopy[index].measurement === "length") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * item.height * item.width * (1 + incrementPercentage));
    } else {
      item.price = Math.round(item.value * item.height * item.width );
    }
  } else if (bedroomThingsDataCopy[index].measurement === "rft") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * newValue * (1 + incrementPercentage));
    } else {
      item.price = Math.round(item.value * newValue );
    }
  } else if (bedroomThingsDataCopy[index].measurement === "door") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(((item.value * item.width * item.height * item.qnty) + parseFloat(item.value2)) * (1 + incrementPercentage));
    } else {
      item.price = Math.round(((item.value * item.width * item.height * item.qnty) + parseFloat(item.value2)));
    }
  }
  

  
  // Update the state with the modified data
  updatedBedroomFormData[index] = item;
  setBedroomFormData(updatedBedroomFormData);
  console.log("price checking", bedroomFormData);
};


const handleBedroomPriceChange = (e, index, field) => {
  const newValue = parseFloat(e.target.value);
  // const newValue = e.target.value;
  const updatedBedroomFormData = [...bedroomFormData];
  const item = updatedBedroomFormData[index];


    item[field] = newValue;

  // Update the state with the modified data
  updatedBedroomFormData[index] = item;
  setBedroomFormData(updatedBedroomFormData);
  console.log("price checking", bedroomFormData);
};


const handleBedroomRemarkChange = (e, index, field) => {
  // const newValue = parseFloat(e.target.value);
  const newValue = e.target.value;
  const updatedBedroomFormData = [...bedroomFormData];
  const item = updatedBedroomFormData[index];


    item[field] = newValue;

  // Update the state with the modified data
  updatedBedroomFormData[index] = item;
  setBedroomFormData(updatedBedroomFormData);
  console.log("price checking", bedroomFormData);
};



const handleBedroomOptionChange = (e, index) => {
  const value = e.target.value;
  const selectedLabel = e.target.options[e.target.selectedIndex].text;
  const incrementPercentage = data['increment'] / 100; 

  setBedroomFormData((prevData) => {
    const newData = [...prevData];    newData[index]['value'] = value;
    newData[index]['valueIncresed'] = Math.round(value * (1 + incrementPercentage));

    newData[index]['label'] = selectedLabel;

    // Recalculate the price based on the selected value
    const item = newData[index];
    if (bedroomThingsDataCopy[index].measurement === "area") {
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.area * (1 + incrementPercentage));
      } else {
        item.price = Math.round(value * item.area);
      }
    } else if (bedroomThingsDataCopy[index].measurement === "width") {
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.width * item.height * item.qnty * (1 + incrementPercentage));
      } else {
        item.price = Math.round(value * item.width * item.height * item.qnty);
      }
    } else if (bedroomThingsDataCopy[index].measurement === "qty") {
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.qnty * (1 + incrementPercentage));
      } else {
        item.price = Math.round(value * item.qnty);
      }
    } else if (bedroomThingsDataCopy[index].measurement === "length") {
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.height * item.width * (1 + incrementPercentage));
      } else {
        item.price = Math.round(value * item.height * item.width);
      }
    } else if (bedroomThingsDataCopy[index].measurement === "rft") {
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.rft * (1 + incrementPercentage));
      } else {
        item.price = Math.round(value * item.rft);
      }
    } else if (bedroomThingsDataCopy[index].measurement === "door") {
      if (item.type === "woodwork") {
        item.price = Math.round(((value * item.width * item.height * item.qnty) + parseFloat(item.value2)) * (1 + incrementPercentage));
      } else {
        item.price = Math.round(((value * item.width * item.height * item.qnty) + parseFloat(item.value2)));
      }
    }
    

    return newData;
  });
};


const handleBedroomOption2Change = (e, index) => {
  const value = e.target.value;
  const selectedLabel = e.target.options[e.target.selectedIndex].text;
  const incrementPercentage = data['increment'] / 100; 

  setBedroomFormData((prevData) => {
    const newData = [...prevData];
    newData[index]['value2'] = value;
    newData[index]['valueIncresed2'] = Math.round(value * (1 + incrementPercentage));

    newData[index]['label2'] = selectedLabel;

    // Recalculate the price based on the selected value
    const item = newData[index];
    if (bedroomThingsDataCopy[index].measurement === "door") {
      // Parse value as a floating-point number (assuming it's a decimal number)
      const valueAsNumber = parseFloat(value);
      if (!isNaN(valueAsNumber)) {
        item.price = Math.round(((item.value * item.width * item.height * item.qnty) + valueAsNumber) * (1 + incrementPercentage));
      } 
    }
    

    return newData;
  });
};

// const calculateBedroomTotal = () => {
//   let newTotal = 0;
//   for (let i = 0; i < bedroomFormData.length; i++) {
//     newTotal += parseInt(bedroomFormData[i].price);
//   }
//   return newTotal;
// };

// useEffect(() => {
//   const newBedroomTotal = calculateBedroomTotal();

//   setRoomTypePrice(newBedroomTotal);
// }, [bedroomFormData]); // This will update the total whenever formData changes.

// to calculate Total Price
useEffect(() => {
  // Calculate total price
  const totalPrice = bedroomFormData.reduce((total, item) => {
    return total + parseInt(item.price);
  }, 0);

  // Update roomTypeWoodPrice
  setRoomTypePrice(totalPrice);
}, [bedroomFormData]);



useEffect(() => {
  // Calculate total woodwork price
  const totalWoodworkPrice = bedroomFormData.reduce((total, item) => {
    if (item.type === "woodwork") {
      return total + item.price;
    }
    return total;
  }, 0);

  // Update roomTypeWoodPrice
  setRoomTypeWoodPrice(totalWoodworkPrice);
}, [bedroomFormData]);

const saveBedroomData = async () => {

  
  const roomDatas = {

    title: bedroomFormData,
    fields: bedroomThingsDataCopy,
    roomType: roomType,
    roomTypeName: roomTypeName,
    roomTypeDesign: roomTypeDesign,
    roomTypeDescription: roomTypeDescription,
    roomTypePrice: roomTypePrice,
    roomTypeWoodPrice: roomTypeWoodPrice,
    id: data.ID,
  };


 await dispatch(createRooms(roomDatas));
  navigation("/projects/view-rooms", { state: data });
} 


const deleteUserAddedBedroomItem = (index) => {
  // Only delete if the item is user-added
  if (bedroomThingsDataCopy[index].isUserAdded) {
    const updatedKitchenThingsDataCopy = [...bedroomThingsDataCopy];
    const updatedKitchenFormData = [...bedroomFormData];

    // Remove the item from the list and the form data
    updatedKitchenThingsDataCopy.splice(index, 1);
    updatedKitchenFormData.splice(index, 1);

    // Update the state
    setBedroomThingsDataCopy(updatedKitchenThingsDataCopy);
    setBedroomFormData(updatedKitchenFormData);
  }
};
  
 // dynamic for foyer furniture 

 const { foyerThingsData } = useSelector(
  (state) => state.foyerThings
);

const [foyerThingsDataCopy, setFoyerThingsDataCopy] = useState(foyerThingsData);

const [foyerFormData, setFoyerFormData] = useState(
  foyerThingsDataCopy.map((item) => ({
  name: item.name,
  label: "",
  value: 0,
  valueIncresed: 0,
  width:"",
  height: "",
  qnty: "",
  rft:"",
  area:"",
  price:0,
  remark: "",
  type: item.type,
}))
);

const addNewFoyerItem = () => {

  setFoyerThingsDataCopy([
    ...foyerThingsDataCopy,
    {
      name: extraItemName,
      measurement: extraItemMeasure,
      material: [{ label: extraItemMaterialName, value: extraItemMaterialValue }],
      type: extraItemType,
      isUserAdded: true,
    }, 
  ]);
  // console.log("data for copy kicthen data",kitchenThingsDataCopy);

  setFoyerFormData([
    ...foyerFormData,
    {
      name: extraItemName,
      label: "",
      value: 0,
      valueIncresed: 0,
      width:"",
      height: "",
      qnty: "",
      rft: "",
      area:"",
      price:0,
      remark: "",
      type: extraItemType,
      isUserAdded: true,
    }, 
  ]);
  setExtraItemMeasure('');
  setExtraItemName('');
  setExtraItemMaterialName('');
  setExtraItemMaterialValue(0);
  setExtraItemType('');
  // console.log("Kitchen Nwe data",kitchenFormData);
};



const handleFoyerChange = (e, index, field) => {
  const newValue = parseFloat(e.target.value);
  // const newValue = e.target.value;
  const updatedFoyerFormData = [...foyerFormData];
  const item = updatedFoyerFormData[index];
  const incrementPercentage = data['increment'] / 100;

  if (foyerThingsDataCopy[index].measurement === "area") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * newValue * (1 + incrementPercentage));
    } else {
      item.price = Math.round(item.value * newValue);
    }
  } else if (foyerThingsDataCopy[index].measurement === "width") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * item.width * item.height * item.qnty * (1 + incrementPercentage));
    } else {
      item.price = Math.round(item.value * item.width * item.height * item.qnty);
    }
  } else if (foyerThingsDataCopy[index].measurement === "qty") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * newValue * (1 + incrementPercentage));
    } else {
      item.price = Math.round(item.value * newValue);
    }
  } else if (foyerThingsDataCopy[index].measurement === "length") {
    item[field] = newValue;  
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * item.height * item.width * (1 + incrementPercentage));
    } else {
      item.price = Math.round(item.value * item.height * item.width);
    }
  } else if (foyerThingsDataCopy[index].measurement === "rft") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * newValue * (1 + incrementPercentage));
    } else {
      item.price = Math.round(item.value * newValue);
    }
  }

  // Update the state with the modified data
  updatedFoyerFormData[index] = item;
  setFoyerFormData(updatedFoyerFormData);
  console.log("price checking", foyerFormData);
};


const handleFoyerPriceChange = (e, index, field) => {
  const newValue = parseFloat(e.target.value);
  // const newValue = e.target.value;
  const updatedFoyerFormData = [...foyerFormData];
  const item = updatedFoyerFormData[index];

    item[field] = newValue;


  // Update the state with the modified data
  updatedFoyerFormData[index] = item;
  setFoyerFormData(updatedFoyerFormData);
  console.log("price checking", foyerFormData);
};




const handleFoyerRemarkChange = (e, index, field) => {
  // const newValue = parseFloat(e.target.value);
  const newValue = e.target.value;
  const updatedFoyerFormData = [...foyerFormData];
  const item = updatedFoyerFormData[index];

    item[field] = newValue;


  // Update the state with the modified data
  updatedFoyerFormData[index] = item;
  setFoyerFormData(updatedFoyerFormData);
  console.log("price checking", foyerFormData);
};



const handleFoyerOptionChange = (e, index) => {
  const value = e.target.value;
  const selectedLabel = e.target.options[e.target.selectedIndex].text;
  const incrementPercentage = data['increment'] / 100;

  setFoyerFormData((prevData) => {
    const newData = [...prevData];
    newData[index]['value'] = value;
    newData[index]['valueIncresed'] = Math.round(value * (1 + incrementPercentage));
    newData[index]['label'] = selectedLabel;

    // Recalculate the price based on the selected value
    const item = newData[index];
    if (foyerThingsDataCopy[index].measurement === "area") {
    
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.area * (1 + incrementPercentage));
      } else {
        item.price = Math.round(value * item.area);
      }
    } else if (foyerThingsDataCopy[index].measurement === "width") {
      
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.width * item.height * item.qnty * (1 + incrementPercentage));
      } else {
        item.price = Math.round(value * item.width * item.height * item.qnty);
      }
    } else if (foyerThingsDataCopy[index].measurement === "qty") {
     
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.qnty * (1 + incrementPercentage));
      } else {
        item.price = Math.round(value * item.qnty);
      }
    } else if (foyerThingsDataCopy[index].measurement === "length") {
     
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.height * item.width * (1 + incrementPercentage));
      } else {
        item.price = Math.round(value * item.height * item.width);
      }
    } else if (foyerThingsDataCopy[index].measurement === "rft") {
     
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.rft * (1 + incrementPercentage));
      } else {
        item.price = Math.round(value * item.rft);
      }
    }

    return newData;
  });
};




// const calculateFoyerTotal = () => {
//   let newTotal = 0;
//   for (let i = 0; i < foyerFormData.length; i++) {
//     newTotal += parseInt(foyerFormData[i].price);
//   }
//   return newTotal;
// };

// useEffect(() => {
//   const newFoyerTotal = calculateFoyerTotal();

//   setRoomTypePrice(newFoyerTotal);
// }, [foyerFormData]); // This will update the total whenever formData changes.

useEffect(() => {
  // Calculate total price
  const totalPrice = foyerFormData.reduce((total, item) => {
    return total + parseInt(item.price);
  }, 0);

  // Update roomTypeWoodPrice
  setRoomTypePrice(totalPrice);
}, [foyerFormData]);



useEffect(() => {
  // Calculate total woodwork price
  const totalWoodworkPrice = foyerFormData.reduce((total, item) => {
    if (item.type === "woodwork") {
      return total + item.price;
    }
    return total;
  }, 0);

  // Update roomTypeWoodPrice
  setRoomTypeWoodPrice(totalWoodworkPrice);
}, [foyerFormData]);


const saveFoyerData = async () => {

  
  const roomDatas = {

    title: foyerFormData,
    fields : foyerThingsDataCopy,
    roomType: roomType,
    roomTypeName: roomTypeName,
    roomTypeDesign: roomTypeDesign,
    roomTypeDescription: roomTypeDescription,
    roomTypePrice: roomTypePrice,
    roomTypeWoodPrice: roomTypeWoodPrice,
    id: data.ID,
  };


 await dispatch(createRooms(roomDatas));
  navigation("/projects/view-rooms", { state: data });
} 

const deleteUserAddedFoyerItem = (index) => {
  // Only delete if the item is user-added
  if (foyerThingsDataCopy[index].isUserAdded) {
    const updatedKitchenThingsDataCopy = [...foyerThingsDataCopy];
    const updatedKitchenFormData = [...foyerFormData];

    // Remove the item from the list and the form data
    updatedKitchenThingsDataCopy.splice(index, 1);
    updatedKitchenFormData.splice(index, 1);

    // Update the state
    setFoyerThingsDataCopy(updatedKitchenThingsDataCopy);
    setFoyerFormData(updatedKitchenFormData);
  }
};


// dynamic for living furniture 

const { livingThingsData } = useSelector(
  (state) => state.livingThings
);


const [livingThingsDataCopy, setLivingThingsDataCopy] = useState(livingThingsData);

const [livingFormData, setLivingFormData] = useState(
  livingThingsDataCopy.map((item) => ({
  name: item.name,
  label: "",
  value: 0,
  valueIncresed: 0,
  width:"",
  height: "",
  qnty: "",
  rft: "",
  area: "",
  price:0,
  remark: "",
  type: item.type,
}))
);

const addNewLivingItem = () => {

  setLivingThingsDataCopy([
    ...livingThingsDataCopy,
    {
      name: extraItemName,
      measurement: extraItemMeasure,
      material: [{ label: extraItemMaterialName, value: extraItemMaterialValue }],
      type: extraItemType,
      isUserAdded: true,
    }, 
  ]);
  // console.log("data for copy kicthen data",kitchenThingsDataCopy);

  setLivingFormData([
    ...livingFormData,
    {
      name: extraItemName,
      label: "",
      value: 0,
      valueIncresed: 0,
      width:"",
      height: "",
      qnty: "",
      rft: "",
      area:"",
      price:0,
      remark: "",
      type: extraItemType,
      isUserAdded: true,
    }, 
  ]);
  setExtraItemMeasure('');
  setExtraItemName('');
  setExtraItemMaterialName('');
  setExtraItemMaterialValue(0);
  setExtraItemType('');
  // console.log("Kitchen Nwe data",kitchenFormData);
};




const handleLivingChange = (e, index, field) => {
  const newValue = parseFloat(e.target.value);
  // const newValue = e.target.value;
  const updatedLivingFormData = [...livingFormData];
  const item = updatedLivingFormData[index];
  const incrementPercentage = data['increment'] / 100;

  if (livingThingsDataCopy[index].measurement === "area") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * newValue * (1 + incrementPercentage));
    } else {
      item.price = Math.round(item.value * newValue);
    }
  } else if (livingThingsDataCopy[index].measurement === "width") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * item.width * item.height * item.qnty * (1 + incrementPercentage));
    } else {
      item.price = Math.round(item.value * item.width * item.height * item.qnty);
    }
  } else if (livingThingsDataCopy[index].measurement === "qty") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * newValue * (1 + incrementPercentage));
    } else {
      item.price = Math.round(item.value * newValue);
    }
  } else if (livingThingsDataCopy[index].measurement === "length") {
    item[field] = newValue;  
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * item.height * item.width * (1 + incrementPercentage));
    } else {
      item.price = Math.round(item.value * item.height * item.width);
    }
  } else if (livingThingsDataCopy[index].measurement === "rft") {
    item[field] = newValue;
    if (item.type === "woodwork") {
      item.price = Math.round(item.value * newValue * (1 + incrementPercentage));
    } else {
      item.price = Math.round(item.value * newValue);
    }
  }

  // Update the state with the modified data
  updatedLivingFormData[index] = item;
  setLivingFormData(updatedLivingFormData);
  console.log("price checking", livingFormData);
};


const handleLivingPriceChange = (e, index, field) => {
  const newValue = parseFloat(e.target.value);
  // const newValue = e.target.value;
  const updatedLivingFormData = [...livingFormData];
  const item = updatedLivingFormData[index];

 
    item[field] = newValue;


  // Update the state with the modified data
  updatedLivingFormData[index] = item;
  setLivingFormData(updatedLivingFormData);
  console.log("price checking", livingFormData);
};




const handleLivingRemarkChange = (e, index, field) => {
  // const newValue = parseFloat(e.target.value);
  const newValue = e.target.value;
  const updatedLivingFormData = [...livingFormData];
  const item = updatedLivingFormData[index];

 
    item[field] = newValue;


  // Update the state with the modified data
  updatedLivingFormData[index] = item;
  setLivingFormData(updatedLivingFormData);
  console.log("price checking", livingFormData);
};



const handleLivingOptionChange = (e, index) => {
  const value = e.target.value;
  const selectedLabel = e.target.options[e.target.selectedIndex].text;
  const incrementPercentage = data['increment'] / 100;

  setLivingFormData((prevData) => {
    const newData = [...prevData];
    newData[index]['value'] = value;
    newData[index]['valueIncresed'] = Math.round(value * (1 + incrementPercentage));
    newData[index]['label'] = selectedLabel;

    // Recalculate the price based on the selected value
    const item = newData[index];
    if (livingThingsDataCopy[index].measurement === "area") {
    
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.area * (1 + incrementPercentage));
      } else {
        item.price = Math.round(value * item.area);
      }
    } else if (livingThingsDataCopy[index].measurement === "width") {
      
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.width * item.height * item.qnty * (1 + incrementPercentage));
      } else {
        item.price = Math.round(value * item.width * item.height * item.qnty);
      }
    } else if (livingThingsDataCopy[index].measurement === "qty") {
     
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.qnty * (1 + incrementPercentage));
      } else {
        item.price = Math.round(value * item.qnty);
      }
    } else if (livingThingsDataCopy[index].measurement === "length") {
     
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.height * item.width * (1 + incrementPercentage));
      } else {
        item.price = Math.round(value * item.height * item.width);
      }
    } else if (livingThingsDataCopy[index].measurement === "rft") {
     
      if (item.type === "woodwork") {
        item.price = Math.round(value * item.rft * (1 + incrementPercentage));
      } else {
        item.price = Math.round(value * item.rft);
      }
    }

    return newData;
  });
};




// const calculateLivingTotal = () => {
//   let newTotal = 0;
//   for (let i = 0; i < livingFormData.length; i++) {
//     newTotal += parseInt(livingFormData[i].price);
//   }
//   return newTotal;
// };

// useEffect(() => {
//   const newlivingTotal = calculateLivingTotal();

//   setRoomTypePrice(newlivingTotal);
// }, [livingFormData]); // This will update the total whenever formData changes.


useEffect(() => {
  // Calculate total price
  const totalPrice = livingFormData.reduce((total, item) => {
    return total + parseInt(item.price);
  }, 0);

  // Update roomTypeWoodPrice
  setRoomTypePrice(totalPrice);
}, [livingFormData]);



useEffect(() => {
  // Calculate total woodwork price
  const totalWoodworkPrice = livingFormData.reduce((total, item) => {
    if (item.type === "woodwork") {
      return total + item.price;
    }
    return total;
  }, 0);

  // Update roomTypeWoodPrice
  setRoomTypeWoodPrice(totalWoodworkPrice);
}, [livingFormData]);


const saveLivingData = async () => {

  
  const roomDatas = {

    title: livingFormData,
    fields : livingThingsDataCopy,
    roomType: roomType,
    roomTypeName: roomTypeName,
    roomTypeDesign: roomTypeDesign,
    roomTypeDescription: roomTypeDescription,
    roomTypePrice: roomTypePrice,
    roomTypeWoodPrice: roomTypeWoodPrice,
    id: data.ID,
  };


 await dispatch(createRooms(roomDatas));
  navigation("/projects/view-rooms", { state: data });
} 

const deleteUserAddedLivingItem = (index) => {
  // Only delete if the item is user-added
  if (livingThingsDataCopy[index].isUserAdded) {
    const updatedKitchenThingsDataCopy = [...livingThingsDataCopy];
    const updatedKitchenFormData = [...livingFormData];

    // Remove the item from the list and the form data
    updatedKitchenThingsDataCopy.splice(index, 1);
    updatedKitchenFormData.splice(index, 1);

    // Update the state
    setLivingThingsDataCopy(updatedKitchenThingsDataCopy);
    setLivingFormData(updatedKitchenFormData);
  }
};

  // dynamic for other furniture 

  const { otherWorkThingsData } = useSelector(
    (state) => state.OtherWorkThings
  );
  
  
  
  const [otherFormData, setOtherFormData] = useState(
    otherWorkThingsData.map((item) => ({
    name: item.name,
    label: "",
    value: 0,
    valueIncresed: 0,
    width: "",
    height: "",
    qnty: "",
    rft: "",
    area: "",
    painting: "",
    price:0,
    remark: "",
  }))
  );
  
  

  
  const handleOtherChange = (e, index, field) => {
    const newValue = parseFloat(e.target.value);
    // const newValue = e.target.value;
    const updatedOtherFormData = [...otherFormData];
    const item = updatedOtherFormData[index];
  
    if (otherWorkThingsData[index].measurement === "area") {
      item[field] = newValue;
      item.price = Math.round(item.value * newValue);
    } else if (otherWorkThingsData[index].measurement === "width") {
      item[field] = newValue;
      item.price = Math.round(item.value * item.width * item.height * item.qnty);
    } else if (otherWorkThingsData[index].measurement === "qty") {
      item[field] = newValue;
      item.price = Math.round(item.value * newValue);
    } else if (otherWorkThingsData[index].measurement === "length") {
      item[field] = newValue;
      item.price = Math.round(item.value * item.height * item.width);
    } else if (otherWorkThingsData[index].measurement === "rft") {
      item[field] = newValue;
      item.price = Math.round(item.value * newValue);
    } else if (otherWorkThingsData[index].measurement === "painting") {
      item[field] = newValue;
      item.price = Math.round(item.value * 3.5 *  newValue);
    }
  
    // Update the state with the modified data
    updatedOtherFormData[index] = item;
    setOtherFormData(updatedOtherFormData);
    console.log("price checking", otherFormData);
  };


  const handleOtherPriceChange = (e, index, field) => {
    const newValue = parseFloat(e.target.value);
    // const newValue = e.target.value;
    const updatedOtherFormData = [...otherFormData];
    const item = updatedOtherFormData[index];
  
 
      item[field] = newValue;
    
  
    // Update the state with the modified data
    updatedOtherFormData[index] = item;
    setOtherFormData(updatedOtherFormData);
    console.log("price checking", otherFormData);
  };



  const handleOtherRemarkChange = (e, index, field) => {
    // const newValue = parseFloat(e.target.value);
    const newValue = e.target.value;
    const updatedOtherFormData = [...otherFormData];
    const item = updatedOtherFormData[index];
  
 
      item[field] = newValue;
    
  
    // Update the state with the modified data
    updatedOtherFormData[index] = item;
    setOtherFormData(updatedOtherFormData);
    console.log("price checking", otherFormData);
  };
  
  

  const handleOtherOptionChange = (e, index) => {
    const value = e.target.value;
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
  
    setOtherFormData((prevData) => {
      const newData = [...prevData];
      newData[index]['value'] = value;
      newData[index]['valueIncresed'] = value; 
      newData[index]['label'] = selectedLabel;
  
      // Recalculate the price based on the selected value
      const item = newData[index];
      if (otherWorkThingsData[index].measurement === "area") {
        item.price = Math.round(value * item.area);
      } else if (otherWorkThingsData[index].measurement === "width") {
        item.price = Math.round(value * item.width * item.height * item.qnty);
      } else if (otherWorkThingsData[index].measurement === "qty") {
        item.price = Math.round(value * item.qnty);
      } else if (otherWorkThingsData[index].measurement === "length") {
        item.price = Math.round(value * item.height * item.width);
      } else if (otherWorkThingsData[index].measurement === "rft") {
        item.price = Math.round(value * item.rft);
      } else if (otherWorkThingsData[index].measurement === "painting") {
        item.price = Math.round(value * 3.5 * item.painting);
      }
  
      return newData;
    });
  };
  
  
 
  
  // const calculateOtherTotal = () => {
  //   let newTotal = 0;
  //   for (let i = 0; i < otherFormData.length; i++) {
  //     newTotal += parseInt(otherFormData[i].price);
  //   }
  //   return newTotal;
  // };
  
  // useEffect(() => {
  //   const newOtherTotal = calculateOtherTotal();

  //   setRoomTypePrice(newOtherTotal);
  // }, [otherFormData]); // This will update the total whenever formData changes.
  
  
  useEffect(() => {
    // Recalculate the total price
    const totalPrice = otherFormData.reduce((total, item) => total + item.price, 0);
    setRoomTypePrice(totalPrice);
 

  }, [otherFormData]); // Recalculate when kitchenFormData changes
  
  
  
  
  const saveOtherData = async () => {
  
    
    const roomDatas = {
  
      title: otherFormData,
      roomType: roomType,
      roomTypeName: roomTypeName,
      roomTypeDesign: roomTypeDesign,
      roomTypeDescription: roomTypeDescription,
      roomTypePrice: roomTypePrice,
      roomTypeWoodPrice: 0,
      id: data.ID,
    };
  
  
   await dispatch(createRooms(roomDatas));
    navigation("/projects/view-rooms", { state: data });
  } 



   // dynamic for loose furniture 

   const { looseThingsData } = useSelector(
    (state) => state.looseThings
  );
  
  
  
  const [looseFormData, setLooseFormData] = useState(
    looseThingsData.map((item) => ({
    name: item.name,
    label: "",
    value: 0,
    valueIncresed: 0,
    width:"",
    height: "",
    qnty: "",
    rft: "",
    area: "",
    price:0,
    remark: "",
  }))
  );
  
  

  
  const handleLooseChange = (e, index, field) => {
    const newValue = parseFloat(e.target.value);
    // const newValue = e.target.value;
    const updatedLooseFormData = [...looseFormData];
    const item = updatedLooseFormData[index];
  
    if (looseThingsData[index].measurement === "area") {
      item[field] = newValue;
      item.price = Math.round(item.value * newValue);
    } else if (looseThingsData[index].measurement === "width") {
      item[field] = newValue;
      item.price = Math.round(item.value * item.width * item.height * item.qnty);
    } else if (looseThingsData[index].measurement === "qty") {
      item[field] = newValue;
      item.price = Math.round(item.value * newValue);
    } else if (looseThingsData[index].measurement === "length") {
      item[field] = newValue;
      item.price = Math.round(item.value * item.height * item.width);
    } else if (looseThingsData[index].measurement === "rft") {
      item[field] = newValue;
      item.price = Math.round(item.value * newValue);
    }
  
    // Update the state with the modified data
    updatedLooseFormData[index] = item;
    setLooseFormData(updatedLooseFormData);
    console.log("price checking", looseFormData);
  };


  const handleLoosePriceChange = (e, index, field) => {
    const newValue = parseFloat(e.target.value);
    // const newValue = e.target.value;
    const updatedLooseFormData = [...looseFormData];
    const item = updatedLooseFormData[index];
  
      item[field] = newValue;
     
  
    // Update the state with the modified data
    updatedLooseFormData[index] = item;
    setLooseFormData(updatedLooseFormData);
    console.log("price checking", looseFormData);
  };



  const handleLooseRemarkChange = (e, index, field) => {
    // const newValue = parseFloat(e.target.value);
    const newValue = e.target.value;
    const updatedLooseFormData = [...looseFormData];
    const item = updatedLooseFormData[index];
  
      item[field] = newValue;
     
  
    // Update the state with the modified data
    updatedLooseFormData[index] = item;
    setLooseFormData(updatedLooseFormData);
    console.log("price checking", looseFormData);
  };
  
  

  const handleLooseOptionChange = (e, index) => {
    const value = e.target.value;
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
  
    setLooseFormData((prevData) => {
      const newData = [...prevData];
      newData[index]['value'] = value;
      newData[index]['valueIncresed'] = value; 
      newData[index]['label'] = selectedLabel;
  
      // Recalculate the price based on the selected value
      const item = newData[index];
      if (looseThingsData[index].measurement === "area") {
        item.price = Math.round(value * item.area);
      } else if (looseThingsData[index].measurement === "width") {
        item.price = Math.round(value * item.width * item.height * item.qnty);
      } else if (looseThingsData[index].measurement === "qty") {
        item.price = Math.round(value * item.qnty);
      } else if (looseThingsData[index].measurement === "length") {
        item.price = Math.round(value * item.height * item.width);
      } else if (looseThingsData[index].measurement === "rft") {
        item.price = Math.round(value * item.rft);
      }
  
      return newData;
    });
  };
  
  
 
  
  // const calculateLooseTotal = () => {
  //   let newTotal = 0;
  //   for (let i = 0; i < looseFormData.length; i++) {
  //     newTotal += parseInt(looseFormData[i].price);
  //   }
  //   return newTotal;
  // };
  
  // useEffect(() => {
  //   const newLooseTotal = calculateLooseTotal();

  //   setRoomTypePrice(newLooseTotal);
  // }, [looseFormData]); // This will update the total whenever formData changes.
  
  
  useEffect(() => {
    // Recalculate the total price
    const totalPrice = looseFormData.reduce((total, item) => total + item.price, 0);
    setRoomTypePrice(totalPrice);
  
  }, [looseFormData]); // Recalculate when kitchenFormData changes
  
  
  
  
  const saveLooseData = async () => {
  
    
    const roomDatas = {
  
      title: looseFormData,
      roomType: roomType,
      roomTypeName: roomTypeName,
      roomTypeDesign: roomTypeDesign,
      roomTypeDescription: roomTypeDescription,
      roomTypePrice: roomTypePrice,
      roomTypeWoodPrice: 0,
      id: data.ID,
    };
  
  
  await  dispatch(createRooms(roomDatas));
    navigation("/projects/view-rooms", { state: data });
  } 



   // dynamic for Accessories

   const { accessoriesThingsData } = useSelector(
    (state) => state.accessories
  );
  
  
  
  const [accessoriesFormData, setAccessoriesFormData] = useState(
    accessoriesThingsData.map((item) => ({
    name: item.name,
    label: "",
    value: 0,
    valueIncresed: 0,
    width: "",
    height: "",
    qnty: "",
    rft: "",
    area: "",
    price:0,
    remark: "",
  }))
  );
  
  

  
  const handleAccessoriesChange = (e, index, field) => {
    const newValue = parseFloat(e.target.value);
    // const newValue = e.target.value;
    const updatedAccessoriesFormData = [...accessoriesFormData];
    const item = updatedAccessoriesFormData[index];
  
    if (accessoriesThingsData[index].measurement === "area") {
      item[field] = newValue;
      item.price = Math.round(item.value * newValue);
    } else if (accessoriesThingsData[index].measurement === "width") {
      item[field] = newValue;
      item.price = Math.round(item.value * item.width * item.height * item.qnty);
    } else if (accessoriesThingsData[index].measurement === "qty") {
      item[field] = newValue;
      item.price = Math.round(item.value * newValue);
    } else if (accessoriesThingsData[index].measurement === "length") {
      item[field] = newValue;
      item.price = Math.round(item.value * item.height * item.width);
    } else if (accessoriesThingsData[index].measurement === "rft") {
      item[field] = newValue;
      item.price = Math.round(item.value * newValue);
    }
  
    // Update the state with the modified data
    updatedAccessoriesFormData[index] = item;
    setAccessoriesFormData(updatedAccessoriesFormData);
    console.log("price checking", accessoriesFormData);
  };


  const handleAccessoriesPriceChange = (e, index, field) => {
    const newValue = parseFloat(e.target.value);
    // const newValue = e.target.value;
    const updatedAccessoriesFormData = [...accessoriesFormData];
    const item = updatedAccessoriesFormData[index];
  
      item[field] = newValue;
     
  
    // Update the state with the modified data
    updatedAccessoriesFormData[index] = item;
    setAccessoriesFormData(updatedAccessoriesFormData);
    console.log("price checking", accessoriesFormData);
  };



  const handleAccessoriesRemarkChange = (e, index, field) => {
    // const newValue = parseFloat(e.target.value);
    const newValue = e.target.value;
    const updatedAccessoriesFormData = [...accessoriesFormData];
    const item = updatedAccessoriesFormData[index];
  
      item[field] = newValue;
     
  
    // Update the state with the modified data
    updatedAccessoriesFormData[index] = item;
    setAccessoriesFormData(updatedAccessoriesFormData);
    console.log("price checking", accessoriesFormData);
  };
  
  

  const handleAccessoriesOptionChange = (e, index) => {
    const value = e.target.value;
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
  
    setAccessoriesFormData((prevData) => {
      const newData = [...prevData];
      newData[index]['value'] = value;
      newData[index]['valueIncresed'] = value; 
      newData[index]['label'] = selectedLabel;
  
      // Recalculate the price based on the selected value
      const item = newData[index];
      if (accessoriesThingsData[index].measurement === "area") {
        item.price = Math.round(value * item.area);
      } else if (accessoriesThingsData[index].measurement === "width") {
        item.price = Math.round(value * item.width * item.height * item.qnty);
      } else if (accessoriesThingsData[index].measurement === "qty") {
        item.price = Math.round(value * item.qnty);
      } else if (accessoriesThingsData[index].measurement === "length") {
        item.price = Math.round(value * item.height * item.width);
      } else if (accessoriesThingsData[index].measurement === "rft") {
        item.price = Math.round(value * item.rft);
      }
  
      return newData;
    });
  };
  
  
 
  
  // const calculateAccessoriesTotal = () => {
  //   let newTotal = 0;
  //   for (let i = 0; i < accessoriesFormData.length; i++) {
  //     newTotal += parseInt(accessoriesFormData[i].price);
  //   }
  //   return newTotal;
  // };
  
  // useEffect(() => {
  //   const newAccessoriesTotal = calculateAccessoriesTotal();

  //   setRoomTypePrice(newAccessoriesTotal);
  // }, [accessoriesFormData]); // This will update the total whenever formData changes.
  
  
  
  useEffect(() => {
    // Recalculate the total price
    const totalPrice = accessoriesFormData.reduce((total, item) => total + item.price, 0);
    setRoomTypePrice(totalPrice);
  
  }, [accessoriesFormData]); // Recalculate when kitchenFormData changes
  
  
  
  const saveAccessoriesData = async () => {
  
    
    const roomDatas = {
  
      title: accessoriesFormData,
      roomType: roomType,
      roomTypeName: roomTypeName,
      roomTypeDesign: roomTypeDesign,
      roomTypeDescription: roomTypeDescription,
      roomTypePrice: roomTypePrice,
      roomTypeWoodPrice: 0,
      id: data.ID,
    };
  
  
   await dispatch(createRooms(roomDatas));
    navigation("/projects/view-rooms", { state: data });
  } 

  // name: item.name,
  // label: "",
  // value: 0,
  // valueIncresed: 0,
  // width:0,
  // height: 0,
  // qnty: 0,
  // rft:0,
  // area:0,
  // price:0,
  // remark: "",

  // adding dynamic fields for extra items
  const [materialFields, setMaterialFields] = useState([{ label: '', value: 0, qnty: 1, remark: '' }]); 
  const [materialFieldsTotal, setMaterialFieldsTotal] = useState(0); 

  const handleAddField = () => {
    setMaterialFields([...materialFields, { label: '', value: 0, qnty: 1, remark: '' }]); 
  };
  
  const handleRemoveField = (index) => {
    const updatedFields = [...materialFields];
    updatedFields.splice(index, 1);
    setMaterialFields(updatedFields);
  };
  
  const handleFieldChange = (value, index, fieldName) => {
    const updatedFields = [...materialFields];
    updatedFields[index][fieldName] = value;
    setMaterialFields(updatedFields);
  };

    const values = materialFields.map(items => {
    return items.value;
  });
  // reducing the qty in  a single value
  const reducerOfValues = (accumulator, currentValue) =>
  accumulator + parseFloat(currentValue || 0);

  // const totalValues = values.reduce(reducerOfValues, 0);
  const totalValues = () => {
    return values.reduce(reducerOfValues, 0);
  } 

  useEffect(() => {
    const newAccessoriesTotal = totalValues;

    setMaterialFieldsTotal(newAccessoriesTotal);
    setRoomTypePrice(newAccessoriesTotal);
  }, [materialFields]);

     
  const saveExtraItemsData = async () => {
  
    
    const roomDatas = {
  
      title: materialFields,
      roomType: roomType,
      roomTypeName: roomTypeName,
      roomTypeDesign: roomTypeDesign,
      roomTypeDescription: roomTypeDescription,
      roomTypePrice: roomTypePrice,
      roomTypeWoodPrice: 0,
      id: data.ID,
    };
  
  
   await dispatch(createRooms(roomDatas));
    navigation("/projects/view-rooms", { state: data });
  } 
  return (
    <Container fluid>
      <Row>
        <Col sm={1} style={{ backgroundColor: '#fdc771' }}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="Projects" >
            <Row>
              <Col sm={1} style={{ backgroundColor: '#fdc771' }}>
                <div style={{ justifyContent: 'center', justifyItems: 'center', justifySelf: 'center', }}>
                  <img src={require('../assests/logo-black.png')} alt='Fabdiz' style={{ width: 30, height: 30, alignContent: 'center', alignItems: 'center', alignSelf: 'center' }} />
                  <p style={{ fontSize: 12 }}>Fabdiz</p>
                </div>
                <Nav variant="tabs" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="Dashboard" href='/dashboard'>
                      <img src={require('../assests/dashboard.png')} alt='Dashboard' style={{ width: 25, height: 25, alignContent: 'center', alignItems: 'center', alignSelf: 'center' }} />

                      <p style={{ color: '#000000', fontSize: 12 }}>Dashboard</p></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Projects" href='/projects'>
                      <img src={require('../assests/projects.png')} alt='Projects' style={{ width: 25, height: 25 }} />

                      <p style={{ color: '#000000', fontSize: 12 }}>Projects</p></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
            <Nav.Link eventKey="Assigned" href='/assigned'>
            <img src={require('../assests/assign.png')} alt='Assigned' style={{width:25,height:25}}/>

            <p style={{color:'#000000',fontSize:12}}>Assigned</p></Nav.Link>
          </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="Settings" href='/settings'>
                      <img src={require('../assests/settings.png')} alt='Settings' style={{ width: 25, height: 25 }} />

                      <p style={{ color: '#000000', fontSize: 12 }}>Settings</p></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Services" href='/services'>
                      <img src={require('../assests/services.png')} alt='Services' style={{ width: 25, height: 25 }} />

                      <p style={{ color: '#000000', fontSize: 12 }}>Services</p></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Team" href='/team'>
                      <img src={require('../assests/team.png')} alt='Team' style={{ width: 25, height: 25 }} />

                      <p style={{ color: '#000000', fontSize: 12 }}>Team</p></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Factory" href='/factory'>
                      <img src={require('../assests/Factory.png')} alt='Factory' style={{ width: 25, height: 25, alignSelf: 'center' }} />

                      <p style={{ color: '#000000', fontSize: 12 }}>Factory</p></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Inspiration" href='/inspiration'>
                      <img src={require('../assests/inspiration.png')} alt='Inspiration' style={{ width: 25, height: 25 }} />

                      <p style={{ color: '#000000', fontSize: 12 }}>Inspiration</p></Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Render" href='/render'>
                      <img src={require('../assests/render.png')} alt='Render' style={{ width: 25, height: 25 }} />

                      <p style={{ color: '#000000', fontSize: 12 }}>Render</p></Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
        <Col sm={11}>
          <Row>
            <Col style={{
              backgroundColor: "#f5f8f8",
              display: "flex",
              flexDirection: "row",
              padding: "1%",
              justifyContent: "space-between",
            }}>
              <Col sm={10}>
                <p style={{ fontSize: 20, fontWeight: "bold" }}>Projects</p>
              </Col>

              <Col sm={2}>
                <img
                  src={require("../assests/notification.png")}
                  alt="Notification"
                  style={{ width: 26, height: 26 }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Dropdown className="d-inline mx-2">
                  <Dropdown.Toggle id="dropdown-autoclose-true">
                  <img
                        src={require("../assests/user.png")}
                        alt="User"
                        style={{ width: 40, height: 40 }}
                      />{" "}
                      &nbsp;&nbsp; {userData ? userData[0].name : "UNKNOWN"} &nbsp;
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">
                      <Link to={"/viewprofile"}>View Profile</Link>
                    </Dropdown.Item>
                    <Dropdown.Item href="#">FAQ</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleLogOut()}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Col>
          </Row>
          <Row className="px-5 py-2">
            <Row className="d-flex justify-content-end col-sm-5">
              <Breadcrumb>
                <Breadcrumb.Item href="/projects">Projects</Breadcrumb.Item>
                <Breadcrumb.Item
                  onClick={() =>
                    navigation("/projects/view-rooms", { state: data })
                  }
                >
                  {data.pname}
                </Breadcrumb.Item>
                <Breadcrumb.Item active>Add Room  ({roomTypeDesign})</Breadcrumb.Item>
              </Breadcrumb>
            </Row>
            {/* Room name and type modal */}

            {roomTypeModal == '' ?
             <Row className="p-2" style={{ backgroundColor: "#F2F2F2", height: 'auto', overflowY: 'auto' }}>
              
              <Row>

                <Col
                  sm={12}
                >
                  <Container>
                    <Row>
                      <Col style={{ minHeight: 380, maxHeight: 480 }}>
                        <h4>What is this design option for?</h4>
                        <Form onSubmit={handleRoomTypeSubmit}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Room Type</Form.Label>
                            <Form.Select value={roomType} onChange={(e) => { setRoomType(e.currentTarget.value) }} aria-label="Default select example" required
                            //  disabled={roomType ? true : false}
                            >
                              <option>Select a Room</option>
                              <option value="Kitchen">Kitchen</option>
                              <option value="Bedroom">Bedroom</option>
                              <option value="Foyer">Foyer</option>
                              <option value="Living Room">Living Room</option>
                              <option value="Other Works">Other Works</option>
                              <option value="Loose Furniture">Loose Furniture</option>
                              <option value="Accessories">Accessories</option>
                              <option value="ExtraItems">Extra items</option>
                            </Form.Select>
                           

                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Room Name</Form.Label>
                            <Form.Control
                              type="text"
                              value={roomTypeName}
                              onChange={(e) => { setRoomTypeName(e.target.value) }}
                              placeholder="Kitchen / Bedroom / Foyer / Living Room / Other Works / Loose Furniture"
                              required
                            // disabled={roomTypeName ? true : false}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Design options</Form.Label>
                            <Form.Control
                              type="text"
                              value={roomTypeDesign}
                              onChange={(e) => { setRoomTypeDesign(e.target.value) }}
                              placeholder="Lorraine U, Damaris Straight..."
                              required={false}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              as="textarea"
                              value={roomTypeDescription}
                              onChange={(e) => { setRoomTypeDescription(e.target.value) }}
                              placeholder="Description"
                              required
                            />
                          </Form.Group>
                          <Form.Group>
                            <Button variant="secondary" onClick={() => handleRoomTypeCancel()} className="w-25 mx-4">
                              Cancel
                            </Button>
                            <Button variant="success" onClick={() => handleSaveNameType()} className="w-25 mx-4">
                              Save
                            </Button>
                            <Button variant="primary" className="w-25 mx-4" onClick={()=>setRoomTypeModal(roomType)}>
                              Next
                            </Button>
                          </Form.Group>
                        </Form>
                       
                      </Col>
                    </Row>

                  </Container>

                </Col>
              </Row>
            
            
          </Row> : roomTypeModal == 'Kitchen' ? 
          <Row className="p-2" >
            <h4>Kitchen</h4>
               
                 
                      <Row  style={{ backgroundColor: "#F2F2F2", height: 'auto', overflowY: 'auto' }}>
                        <Col style={{ minHeight: 380, maxHeight: 480 }}>
                       
                          <Form>

                            <Form.Group>
                              <Row>

                                <Col sm={12}>
                                  
                                          {kitchenThingsDataCopy.map((item, index) => (
                                           item.measurement == "area" ?  
                                           <Row className="mx-1 my-3">
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Label
                                          style={{
                                            fontSize: 16,
                                            fontWeight: "500",
                                          }}
                                        >{item.name}
                                        </Form.Label>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Select
                                        value={kitchenFormData[index].value}
                                        onChange={(e) => handleKitchenOptionChange(e, index)}
                               

                                        >
                                          <option value="0">None</option>
                                          {item.material.map((item2, index2) => (
                                            <option value={item2.value} key={index2}>{item2.label}</option>
                                          ))}
                                          
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                    <Form.Group>
                                        <Form.Control
                                          type="number"
                                          value={kitchenFormData[index].area} 
                                         onChange={(e) => handleKitchenChange(e, index, "area")}
                                          placeholder="Area"
                                        />
                                      </Form.Group>
                                    </Col>
                                    
                                    <Col sm={1}>
                                      
                                    </Col>

                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Control
                                          type="number"
                                          value={kitchenFormData[index].price}
                                          //  onChange={(e) => handleKitchenPriceChange(e, index, "price")}
                                          placeholder="Price"
                                          disabled={kitchenFormData[index].value ? false : true }
                                          
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Control
                                       
                                        value={kitchenFormData[index].remark}
                                        onChange={(e) => handleKitchenRemarkChange(e, index, "remark")}
                                          as="textarea" rows={1}
                                          placeholder="Remark"
                                        />
                                         
                                      </Form.Group>
                                      
                                     
                                  </Col>
                                  <Col sm={1}>
                                  {item.isUserAdded && (
                                        <Button variant="danger" onClick={() => deleteUserAddedKitchenItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                                  </Col>
                                  </Row>
                                   : item.measurement == "width" ? 
                                    <Row className="mx-1 my-3">
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Label
                                          style={{
                                            fontSize: 16,
                                            fontWeight: "500",
                                          }}
                                        >{item.name}
                                        </Form.Label>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Select
                                        value={kitchenFormData[index].value}
                                        onChange={(e) => handleKitchenOptionChange(e, index)}
                               

                                        >
                                          <option value="0">None</option>
                                          {item.material.map((item2, index2) => (
                                            <option value={item2.value} key={index2}>{item2.label}</option>
                                          ))}
                                          
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={1}>
                                    <Form.Group>
                                        <Form.Control
                                          type="number"
                                          value={kitchenFormData[index].width}
                                         onChange={(e) => handleKitchenChange(e, index, "width")}
                                          placeholder="Width"
                                        />
                                      </Form.Group>
                                    </Col>
                                    
                                    <Col sm={1}>
                                    <Form.Group>
                                        <Form.Control
                                          type="number"
                                          value={kitchenFormData[index].height}
                                         onChange={(e) => handleKitchenChange(e, index, "height")}
                                          placeholder="Height"
                                        />
                                      </Form.Group>
                                    </Col>

                                    <Col sm={1}>
                                    <Form.Group>
                                        <Form.Control
                                          type="number"
                                          value={kitchenFormData[index].qnty}
                                         onChange={(e) => handleKitchenChange(e, index, "qnty")}
                                          placeholder="Qty"
                                        />
                                      </Form.Group>
                                    </Col>

                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Control
                                          type="number"
                                          value={kitchenFormData[index].price}
                                          // onChange={(e) => handleKitchenPriceChange(e, index, "price")} 
                                          placeholder="Price"
                                           disabled={kitchenFormData[index].value ? false : true }
                                           min={123}
                                           autoFocus={true}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Control
                                        value={kitchenFormData[index].remark}
                                        onChange={(e) => handleKitchenRemarkChange(e, index, "remark")}
                                          as="textarea" rows={1}
                                          placeholder="Remark"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={1}>
                                    {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedKitchenItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                                  </Col>
                                  </Row> 
                                  : item.measurement == "qty" ? 
                                   <Row className="mx-1 my-3">
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Label
                                          style={{
                                            fontSize: 16,
                                            fontWeight: "500",
                                          }}
                                        >{item.name}
                                        </Form.Label>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Select
                                        value={kitchenFormData[index].value}
                                        onChange={(e) => handleKitchenOptionChange(e, index)}
                               

                                        >
                                          <option value="0">None</option>
                                          {item.material.map((item2, index2) => (
                                            <option value={item2.value} key={index2}>{item2.label}</option>
                                          ))}
                                          
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                    <Form.Group>
                                        <Form.Control
                                          type="number"
                                          value={kitchenFormData[index].qnty || 0}
                                         onChange={(e) => handleKitchenChange(e, index, "qnty")}
                                          placeholder="Qty"
                                        />
                                      </Form.Group>
                                    </Col>
                                    
                                    <Col sm={1}>
                                      
                                    </Col>

                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Control
                                          type="number"
                                          value={kitchenFormData[index].price}
                                          //  onChange={(e) => handleKitchenPriceChange(e, index, "price")}
                                          placeholder="Price"
                                          disabled={kitchenFormData[index].value ? false : true }
                                          min={123}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Control
                                        value={kitchenFormData[index].remark}
                                        onChange={(e) => handleKitchenRemarkChange(e, index, "remark")}
                                          as="textarea" rows={1}
                                          placeholder="Remark"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={1}>
                                    {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedKitchenItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                                  </Col>
                                  </Row> 
                                  : item.measurement == "length" ? 
                                   <Row className="mx-1 my-3">
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Label
                                          style={{
                                            fontSize: 16,
                                            fontWeight: "500",
                                          }}
                                        >{item.name}
                                        </Form.Label>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Select
                                        value={kitchenFormData[index].value}
                                        onChange={(e) => handleKitchenOptionChange(e, index)}
                               

                                        >
                                          <option value="0">None</option>
                                          {item.material.map((item2, index2) => (
                                            <option value={item2.value} key={index2}>{item2.label}</option>
                                          ))}
                                          
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={1}>
                                    <Form.Group>
                                        <Form.Control
                                          type="number"
                                          value={kitchenFormData[index].height}
                                         onChange={(e) => handleKitchenChange(e, index, "height")}
                                          placeholder="Length"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={1}>
                                    <Form.Group>
                                        <Form.Control
                                          type="number"
                                          value={kitchenFormData[index].width}
                                         onChange={(e) => handleKitchenChange(e, index, "width")}
                                          placeholder="Width"
                                        />
                                      </Form.Group>
                                    </Col>
                                   
                                    
                                    <Col sm={1}>
                                      
                                    </Col>

                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Control
                                          type="number"
                                          value={kitchenFormData[index].price}
                                          // onChange={(e) => handleKitchenPriceChange(e, index, "price")} 
                                          placeholder="Price"
                                           disabled={kitchenFormData[index].value ? false : true }
                                           min={123}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Control
                                        value={kitchenFormData[index].remark}
                                        onChange={(e) => handleKitchenRemarkChange(e, index, "remark")}
                                          as="textarea" rows={1}
                                          placeholder="Remark"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={1}>
                                    {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedKitchenItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                                  </Col>
                                  </Row> : item.measurement == "rft" ? 
                                   <Row className="mx-1 my-3">
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Label
                                          style={{
                                            fontSize: 16,
                                            fontWeight: "500",
                                          }}
                                        >{item.name}
                                        </Form.Label>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Select
                                        value={kitchenFormData[index].value}
                                        onChange={(e) => handleKitchenOptionChange(e, index)}
                               

                                        >
                                          <option value="0">None</option>
                                          {item.material.map((item2, index2) => (
                                            <option value={item2.value} key={index2}>{item2.label}</option>
                                          ))}
                                          
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                    <Form.Group>
                                        <Form.Control
                                          type="number"
                                          value={kitchenFormData[index].rft}
                                         onChange={(e) => handleKitchenChange(e, index, "rft")}
                                          placeholder="RFT"
                                        />
                                      </Form.Group>
                                    </Col>
                                    
                                    <Col sm={1}>
                                      
                                    </Col>

                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Control
                                          type="number"
                                          value={kitchenFormData[index].price}
                                          // onChange={(e) => handleKitchenPriceChange(e, index, "price")} 
                                          placeholder="Price"
                                           disabled={kitchenFormData[index].value ? false : true }
                                           min={123}
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Control
                                        value={kitchenFormData[index].remark}
                                        onChange={(e) => handleKitchenRemarkChange(e, index, "remark")}
                                          as="textarea" rows={1}
                                          placeholder="Remark"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={1}>
                                    {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedKitchenItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                                  </Col>
                                  </Row> : item.measurement == "selection" ? 
                                   <Row className="mx-1 my-3">
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Label
                                          style={{
                                            fontSize: 16,
                                            fontWeight: "500",
                                          }}
                                        >{item.name}
                                        </Form.Label>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                      <Form.Group>
                                        <Form.Select
                                        value={kitchenFormData[index].value}
                                        onChange={(e) => handleKitchenOptionChange(e, index)}
                               

                                        >
                                          <option value="0">None</option>
                                          {item.material.map((item2, index2) => (
                                            <option value={item2.value} key={index2}>{item2.label}</option>
                                          ))}
                                          
                                        </Form.Select>
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                   
                                    </Col>
                                    
                                    <Col sm={1}>
                                      
                                    </Col>

                                    <Col sm={2}>
                                     
                                    </Col>
                                    <Col sm={3}>
                                      <Form.Group>
                                        <Form.Control
                                        value={kitchenFormData[index].remark}
                                        onChange={(e) => handleKitchenRemarkChange(e, index, "remark")}
                                          as="textarea" rows={1}
                                          placeholder="Remark"
                                        />
                                      </Form.Group>
                                    </Col>
                                  </Row> : null
                                    ))}
                               

                                </Col>
                                {/* <button onClick={addNewKitchen}>Add New Accessory</button> */}
                              </Row>
                              <Row>
                              <Col>
                              
                                <Row className="mx-1 my-3">
                                    <Col sm={2}>
                                    <Form.Group >
                                        <Form.Control
                                        value={extraItemName}
                                        onChange={(e) => setExtraItemName(e.target.value)}
                                        type="text"
                                          placeholder="Item Name"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                    <Form.Group >
                                        <Form.Control
                                        value={extraItemMaterialName}
                                        onChange={(e) => setExtraItemMaterialName(e.target.value)}
                                        type="text"
                                          placeholder="Material Name"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                    <Form.Group >
                                        <Form.Control
                                        value={extraItemMaterialValue}
                                        onChange={(e) => setExtraItemMaterialValue(e.target.value)}
                                        type="number"
                                          placeholder="Material Value"
                                        />
                                      </Form.Group>
                                    </Col>
                                    
                                    <Col sm={3}>
                                    <Form.Select value={extraItemMeasure} onChange={(e) => { setExtraItemMeasure(e.currentTarget.value) }} >
                              <option>Select Measurement Type</option>
                              <option value="area">Area</option>
                              <option value="width">Width &  Height</option>
                              <option value="rft">RFT</option>
                              <option value="qty">Quantity</option>
                              <option value="length">Width & Length</option>
                              <option value="selection">Selection</option>
                            </Form.Select>
                                    </Col>
                                    <Col sm={1}>
                                       <Form.Check
                                      inline
                                      label="Wood"
                                      name="Wood"
                                      type="checkbox"
                                      id="inline-checkbox-1"
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                    />
                                    </Col>
                                   <Col sm={2}>
                                    <Button onClick={addNewKitchenItem}>Add Item</Button>
                                    </Col>
                                  </Row>
                               
                                      
                                      
                               
                               
                              </Col>
                          
                              </Row>
                            
                            </Form.Group>


                          </Form>
                          
                        </Col>
                      </Row>
                      <Row className="mt-2">
                                <Col className="col-sm-5">
                                  <Button className="w-25 rounded-1" style={{fontSize:16,fontWeight:600}} onClick={() => setRoomTypeModal("")} >Go Back</Button>
                                  <Button className="w-50 rounded-1" style={{fontSize:16,fontWeight:600}} onClick={() => saveKitchenData()} >Save</Button>
                                </Col>
                                <Col className="col-sm-3">
                                  <h4>Total Price</h4>
                                </Col>
                                <Col className="col-sm-2"></Col>
                                <Col className="col-sm-2">
                                  <h4>
                                    Rs:- {roomTypePrice}

                                  </h4>
                                </Col>
                              </Row>

            </Row>  : roomTypeModal == 'Bedroom' ? 
            <Row className="p-2" >
  <h4>Bedroom</h4>
     
       
            <Row  style={{ backgroundColor: "#F2F2F2", height: 'auto', overflowY: 'auto' }}>
              <Col style={{ minHeight: 380, maxHeight: 480 }}>
             
                <Form>

                  <Form.Group>
                    <Row>

                      <Col sm={12}>
                        
                                {bedroomThingsDataCopy.map((item, index) => (
                                 item.measurement == "area" ?  
                                 <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={bedroomFormData[index].value}
                              onChange={(e) => handleBedroomOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].area}
                               onChange={(e) => handleBedroomChange(e, index, "area")}
                                placeholder={"Area"}
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].price}
                                // onChange={(e) => handleBedroomPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={bedroomFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                              value={bedroomFormData[index].remark}
                              onChange={(e) => handleBedroomRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedBedroomItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                          </Col>
                        </Row>
                         : item.measurement == "width" ? 
                          <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={bedroomFormData[index].value}
                              onChange={(e) => handleBedroomOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].width}
                               onChange={(e) => handleBedroomChange(e, index, "width")}
                                placeholder="Width"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].height}
                               onChange={(e) => handleBedroomChange(e, index, "height")}
                                placeholder="Height"
                              />
                            </Form.Group>
                          </Col>

                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].qnty}
                               onChange={(e) => handleBedroomChange(e, index, "qnty")}
                                placeholder="Qty"
                              />
                            </Form.Group>
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].price}
                                // onChange={(e) => handleBedroomPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={bedroomFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                              value={bedroomFormData[index].remark}
                              onChange={(e) => handleBedroomRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedBedroomItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                                      </Col>
                        </Row> 
                        : item.measurement == "qty" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={bedroomFormData[index].value}
                              onChange={(e) => handleBedroomOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].qnty}
                               onChange={(e) => handleBedroomChange(e, index, "qnty")}
                                placeholder="Qty"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].price}
                                // onChange={(e) => handleBedroomPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={bedroomFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                              value={bedroomFormData[index].remark}
                              onChange={(e) => handleBedroomRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedBedroomItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                          </Col>
                        </Row> 
                        : item.measurement == "length" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={bedroomFormData[index].value}
                              onChange={(e) => handleBedroomOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].height}
                               onChange={(e) => handleBedroomChange(e, index, "height")}
                                placeholder="Length"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].width}
                               onChange={(e) => handleBedroomChange(e, index, "width")}
                                placeholder="Width"
                              />
                            </Form.Group>
                          </Col>
                         
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].price}
                                  // onChange={(e) => handleBedroomPriceChange(e, index, "price")}
                                  placeholder="Price"
                                  disabled={bedroomFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                              value={bedroomFormData[index].remark}
                              onChange={(e) => handleBedroomRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedBedroomItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                          </Col>
                        </Row> : item.measurement == "rft" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={bedroomFormData[index].value}
                              onChange={(e) => handleBedroomOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].rft}
                               onChange={(e) => handleBedroomChange(e, index, "rft")}
                                placeholder="RFT"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].price}
                                // onChange={(e) => handleBedroomPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={bedroomFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                              value={bedroomFormData[index].remark}
                              onChange={(e) => handleBedroomRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedBedroomItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                          </Col>
                        </Row> :  item.measurement == "door" ? 
                          <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={bedroomFormData[index].value}
                              onChange={(e) => handleBedroomOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                            <Form.Group>
                              <Form.Select
                              value={bedroomFormData[index].value2}
                              onChange={(e) => handleBedroomOption2Change(e, index)}
                                  
                              disabled={bedroomFormData[index].price == 0 ? true : false}
                              >
                                <option value="0">None</option>
                                {item.door.map((item3, index3) => (
                                  <option value={item3.value} key={index3}>{item3.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].width}
                               onChange={(e) => handleBedroomChange(e, index, "width")}
                                placeholder="Width"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].height}
                               onChange={(e) => handleBedroomChange(e, index, "height")}
                                placeholder="Height"
                              />
                            </Form.Group>
                          </Col>

                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].qnty}
                               onChange={(e) => handleBedroomChange(e, index, "qnty")}
                                placeholder="Qty"
                              />
                            </Form.Group>
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={bedroomFormData[index].price}
                                // onChange={(e) => handleBedroomPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={bedroomFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                              value={bedroomFormData[index].remark}
                              onChange={(e) => handleBedroomRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row> : null
                                ))}
                     

                      </Col>
                    </Row>
                    <Row>
                              <Col>
                              
                                <Row className="mx-1 my-3">
                                    <Col sm={2}>
                                    <Form.Group >
                                        <Form.Control
                                        value={extraItemName}
                                        onChange={(e) => setExtraItemName(e.target.value)}
                                        type="text"
                                          placeholder="Item Name"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                    <Form.Group >
                                        <Form.Control
                                        value={extraItemMaterialName}
                                        onChange={(e) => setExtraItemMaterialName(e.target.value)}
                                        type="text"
                                          placeholder="Material Name"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                    <Form.Group >
                                        <Form.Control
                                        value={extraItemMaterialValue}
                                        onChange={(e) => setExtraItemMaterialValue(e.target.value)}
                                        type="number"
                                          placeholder="Material Value"
                                        />
                                      </Form.Group>
                                    </Col>
                                    
                                    <Col sm={3}>
                                    <Form.Select value={extraItemMeasure} onChange={(e) => { setExtraItemMeasure(e.currentTarget.value) }} >
                              <option>Select Measurement Type</option>
                              <option value="area">Area</option>
                              <option value="width">Width, Height & Qty</option>
                              <option value="rft">RFT</option>
                              <option value="qty">Quantity</option>
                              <option value="length">Width & Length</option>
                              <option value="selection">Selection</option>
                            </Form.Select>
                                    </Col>

                                    <Col sm={1}>
                                       <Form.Check
                                      inline
                                      label="Wood"
                                      name="Wood"
                                      type="checkbox"
                                      id="inline-checkbox-1"
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                    />
                                    </Col>
                                    <Col sm={2}>
                                    <Button  onClick={addNewBedroomItem}>Add Item</Button>
                                    </Col>
                                  </Row>
                               
                                      
                                      
                               
                               
                              </Col>
                          
                              </Row>
                  </Form.Group>


                </Form>

                
              </Col>
            </Row>
            <Row className="mt-2">
                      <Col className="col-sm-5">
                      <Button className="w-25 rounded-1" style={{fontSize:16,fontWeight:600}} onClick={() => setRoomTypeModal("")} >Go Back</Button>

                        <Button className="w-50 rounded-1" style={{fontSize:16,fontWeight:600}} onClick={() => saveBedroomData()} >Save</Button>
                      </Col>
                      <Col className="col-sm-3">
                        <h4>Total Price</h4>
                      </Col>
                      <Col className="col-sm-2"></Col>
                      <Col className="col-sm-2">
                        <h4>
                          Rs:- {roomTypePrice}

                        </h4>
                      </Col>
                    </Row>

  </Row>  : roomTypeModal == 'Foyer' ? 
  <Row className="p-2" >
  <h4>Foyer</h4>
     
       
            <Row  style={{ backgroundColor: "#F2F2F2", height: 'auto', overflowY: 'auto' }}>
              <Col style={{ minHeight: 380, maxHeight: 480 }}>
             
                <Form>

                  <Form.Group>
                    <Row>

                      <Col sm={12}>
                        
                                {foyerThingsDataCopy.map((item, index) => (
                                 item.measurement == "area" ?  
                                 <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={foyerFormData[index].value}
                              onChange={(e) => handleFoyerOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={foyerFormData[index].area}
                               onChange={(e) => handleFoyerChange(e, index, "area")}
                                placeholder="Area"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={foyerFormData[index].price}
                                // onChange={(e) => handleFoyerPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={foyerFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                              value={foyerFormData[index].remark}
                              onChange={(e) => handleFoyerRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedFoyerItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                          </Col>
                        </Row>
                         : item.measurement == "width" ? 
                          <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={foyerFormData[index].value}
                              onChange={(e) => handleFoyerOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={foyerFormData[index].width}
                               onChange={(e) => handleFoyerChange(e, index, "width")}
                                placeholder="Width"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={foyerFormData[index].height}
                               onChange={(e) => handleFoyerChange(e, index, "height")}
                                placeholder="Height"
                              />
                            </Form.Group>
                          </Col>

                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={foyerFormData[index].qnty}
                               onChange={(e) => handleFoyerChange(e, index, "qnty")}
                                placeholder="Qty"
                              />
                            </Form.Group>
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={foyerFormData[index].price}
                                // onChange={(e) => handleFoyerPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={foyerFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                              value={foyerFormData[index].remark}
                              onChange={(e) => handleFoyerRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedFoyerItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                          </Col>
                        </Row> 
                        : item.measurement == "qty" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={foyerFormData[index].value}
                              onChange={(e) => handleFoyerOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={foyerFormData[index].qnty}
                               onChange={(e) => handleFoyerChange(e, index, "qnty")}
                                placeholder="Qty"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={foyerFormData[index].price}
                                // onChange={(e) => handleFoyerPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={foyerFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                              value={foyerFormData[index].remark}
                              onChange={(e) => handleFoyerRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedFoyerItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                          </Col>
                        </Row> 
                        : item.measurement == "length" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={foyerFormData[index].value}
                              onChange={(e) => handleFoyerOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={foyerFormData[index].height}
                               onChange={(e) => handleFoyerChange(e, index, "height")}
                                placeholder="Length"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={foyerFormData[index].width}
                               onChange={(e) => handleFoyerChange(e, index, "width")}
                                placeholder="Width"
                              />
                            </Form.Group>
                          </Col>
                          
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={foyerFormData[index].price}
                                // onChange={(e) => handleFoyerPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={foyerFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                              value={foyerFormData[index].remark}
                              onChange={(e) => handleFoyerRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedFoyerItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                          </Col>
                        </Row> : item.measurement == "rft" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={foyerFormData[index].value}
                              onChange={(e) => handleFoyerOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={foyerFormData[index].rft}
                               onChange={(e) => handleFoyerChange(e, index, "rft")}
                                placeholder="RFT"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={foyerFormData[index].price}
                                // onChange={(e) => handleFoyerPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={foyerFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                              value={foyerFormData[index].remark}
                              onChange={(e) => handleFoyerRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedFoyerItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                          </Col>
                        </Row> : null
                                ))}
                     

                      </Col>
                    </Row>
                    <Row>
                              <Col>
                              
                                <Row className="mx-1 my-3">
                                    <Col sm={2}>
                                    <Form.Group >
                                        <Form.Control
                                        value={extraItemName}
                                        onChange={(e) => setExtraItemName(e.target.value)}
                                        type="text"
                                          placeholder="Item Name"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                    <Form.Group >
                                        <Form.Control
                                        value={extraItemMaterialName}
                                        onChange={(e) => setExtraItemMaterialName(e.target.value)}
                                        type="text"
                                          placeholder="Material Name"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                    <Form.Group >
                                        <Form.Control
                                        value={extraItemMaterialValue}
                                        onChange={(e) => setExtraItemMaterialValue(e.target.value)}
                                        type="number"
                                          placeholder="Material Value"
                                        />
                                      </Form.Group>
                                    </Col>
                                    
                                    <Col sm={3}>
                                    <Form.Select value={extraItemMeasure} onChange={(e) => { setExtraItemMeasure(e.currentTarget.value) }} >
                              <option>Select Measurement Type</option>
                              <option value="area">Area</option>
                              <option value="width">Width &  Height</option>
                              <option value="rft">RFT</option>
                              <option value="qty">Quantity</option>
                              <option value="length">Width & Length</option>
                              <option value="selection">Selection</option>
                            </Form.Select>
                                    </Col>
                                    <Col sm={1}>
                                       <Form.Check
                                      inline
                                      label="Wood"
                                      name="Wood"
                                      type="checkbox"
                                      id="inline-checkbox-1"
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                    />
                                    </Col>
                                    <Col sm={2}>
                                    <Button onClick={addNewFoyerItem}>Add Item</Button>
                                    </Col>
                                  </Row>
                               
                                      
                                      
                               
                               
                              </Col>
                          
                              </Row>
                  </Form.Group>


                </Form>
              </Col>
            </Row>
            <Row className="mt-2">
                      <Col className="col-sm-5">
                      <Button className="w-25 rounded-1" style={{fontSize:16,fontWeight:600}} onClick={() => setRoomTypeModal("")} >Go Back</Button>

                        <Button className="w-50 rounded-1" style={{fontSize:16,fontWeight:600}} onClick={() => saveFoyerData()} >Save</Button>
                      </Col>
                      <Col className="col-sm-3">
                        <h4>Total Price</h4>
                      </Col>
                      <Col className="col-sm-2"></Col>
                      <Col className="col-sm-2">
                        <h4>
                          Rs:- {roomTypePrice}

                        </h4>
                      </Col>
                    </Row>

  </Row>   : roomTypeModal == 'Living Room' ?
  <Row className="p-2" >
  <h4>living</h4>
     
       
            <Row  style={{ backgroundColor: "#F2F2F2", height: 'auto', overflowY: 'auto' }}>
              <Col style={{ minHeight: 380, maxHeight: 480 }}>
             
                <Form>

                  <Form.Group>
                    <Row>

                      <Col sm={12}>
                        
                                {livingThingsDataCopy.map((item, index) => (
                                 item.measurement == "area" ?  
                                 <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name} 
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={livingFormData[index].value}
                              onChange={(e) => handleLivingOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={livingFormData[index].area}
                               onChange={(e) => handleLivingChange(e, index, "area")}
                                placeholder="Area"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={livingFormData[index].price}
                                // onChange={(e) => handleLivingPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={livingFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                              value={livingFormData[index].remark}
                              onChange={(e) => handleLivingRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedLivingItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                          </Col>
                        </Row>
                         : item.measurement == "width" ? 
                          <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={livingFormData[index].value}
                              onChange={(e) => handleLivingOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={livingFormData[index].width}
                               onChange={(e) => handleLivingChange(e, index, "width")}
                                placeholder="Width"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={livingFormData[index].height}
                               onChange={(e) => handleLivingChange(e, index, "height")}
                                placeholder="Height"
                              />
                            </Form.Group>
                          </Col>

                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={livingFormData[index].qnty}
                               onChange={(e) => handleLivingChange(e, index, "qnty")}
                                placeholder="Qty"
                              />
                            </Form.Group>
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={livingFormData[index].price}
                                // onChange={(e) => handleLivingPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={livingFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                              value={livingFormData[index].remark}
                              onChange={(e) => handleLivingRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedLivingItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                          </Col>
                        </Row> 
                        : item.measurement == "qty" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={livingFormData[index].value}
                              onChange={(e) => handleLivingOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={livingFormData[index].qnty}
                               onChange={(e) => handleLivingChange(e, index, "qnty")}
                                placeholder="Qty"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={livingFormData[index].price}
                                // onChange={(e) => handleLivingPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={livingFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                              value={livingFormData[index].remark}
                              onChange={(e) => handleLivingRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedLivingItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                          </Col>
                        </Row> 
                        : item.measurement == "length" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={livingFormData[index].value}
                              onChange={(e) => handleLivingOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={livingFormData[index].height}
                               onChange={(e) => handleLivingChange(e, index, "height")}
                                placeholder="Length"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={livingFormData[index].width}
                               onChange={(e) => handleLivingChange(e, index, "width")}
                                placeholder="Width"
                              />
                            </Form.Group>
                          </Col>
                        
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={livingFormData[index].price}
                                // onChange={(e) => handleLivingPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={livingFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                              value={livingFormData[index].remark}
                              onChange={(e) => handleLivingRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedLivingItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                          </Col>
                        </Row> : item.measurement == "rft" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={livingFormData[index].value}
                              onChange={(e) => handleLivingOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={livingFormData[index].rft}
                               onChange={(e) => handleLivingChange(e, index, "rft")}
                                placeholder="RFT"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={livingFormData[index].price}
                                // onChange={(e) => handleLivingPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={livingFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                              value={livingFormData[index].remark}
                              onChange={(e) => handleLivingRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          {item.isUserAdded && (
                                      <Button variant="danger" onClick={() => deleteUserAddedLivingItem(index)}>
                            <i class="bi bi-x-circle-fill"></i>
                            </Button>
                                      )}
                          </Col>
                        </Row> : null
                                ))}
                     

                      </Col>
                    </Row>
                    <Row>
                              <Col>
                              
                                <Row className="mx-1 my-3">
                                    <Col sm={2}>
                                    <Form.Group >
                                        <Form.Control
                                        value={extraItemName}
                                        onChange={(e) => setExtraItemName(e.target.value)}
                                        type="text"
                                          placeholder="Item Name"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                    <Form.Group >
                                        <Form.Control
                                        value={extraItemMaterialName}
                                        onChange={(e) => setExtraItemMaterialName(e.target.value)}
                                        type="text"
                                          placeholder="Material Name"
                                        />
                                      </Form.Group>
                                    </Col>
                                    <Col sm={2}>
                                    <Form.Group >
                                        <Form.Control
                                        value={extraItemMaterialValue}
                                        onChange={(e) => setExtraItemMaterialValue(e.target.value)}
                                        type="number"
                                          placeholder="Material Value"
                                        />
                                      </Form.Group>
                                    </Col>
                                    
                                    <Col sm={3}>
                                    <Form.Select value={extraItemMeasure} onChange={(e) => { setExtraItemMeasure(e.currentTarget.value) }} >
                              <option>Select Measurement Type</option>
                              <option value="area">Area</option>
                              <option value="width">Width &  Height</option>
                              <option value="rft">RFT</option>
                              <option value="qty">Quantity</option>
                              <option value="length">Width & Length</option>
                              <option value="selection">Selection</option>
                            </Form.Select>
                                    </Col>
                                    <Col sm={1}>
                                       <Form.Check
                                      inline
                                      label="Wood"
                                      name="Wood"
                                      type="checkbox"
                                      id="inline-checkbox-1"
                                      checked={isChecked}
                                      onChange={handleCheckboxChange}
                                    />
                                    </Col>
                                    <Col sm={2}>
                                    <Button onClick={addNewLivingItem}>Add Item</Button>
                                    </Col>
                                  </Row>
                               
                                      
                                      
                               
                               
                              </Col>
                          
                              </Row>
                  </Form.Group>


                </Form>
              </Col>
            </Row>
            <Row className="mt-2">
                      <Col className="col-sm-5">
                      <Button className="w-25 rounded-1" style={{fontSize:16,fontWeight:600}} onClick={() => setRoomTypeModal("")} >Go Back</Button>

                        <Button className="w-50 rounded-1" style={{fontSize:16,fontWeight:600}} onClick={() => saveLivingData()} >Save</Button>
                      </Col>
                      <Col className="col-sm-3">
                        <h4>Total Price</h4>
                      </Col>
                      <Col className="col-sm-2"></Col>
                      <Col className="col-sm-2">
                        <h4>
                          Rs:- {roomTypePrice}

                        </h4>
                      </Col>
                    </Row>

  </Row>   : roomTypeModal == 'Other Works' ? 
  <Row className="p-2" >
  <h4>Other Works</h4>
     
       
            <Row  style={{ backgroundColor: "#F2F2F2", height: 'auto', overflowY: 'auto' }}>
              <Col style={{ minHeight: 380, maxHeight: 480 }}>
             
                <Form>

                  <Form.Group>
                    <Row>

                      <Col sm={12}>
                        
                                {otherWorkThingsData.map((item, index) => (
                                 item.measurement == "area" ?  
                                 <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={otherFormData[index].value}
                              onChange={(e) => handleOtherOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={otherFormData[index].area}
                               onChange={(e) => handleOtherChange(e, index, "area")}
                                placeholder="Area"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={otherFormData[index].price}
                                // onChange={(e) => handleOtherPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={otherFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group>
                              <Form.Control
                              value={otherFormData[index].remark}
                              onChange={(e) => handleOtherRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                         : item.measurement == "width" ? 
                          <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={otherFormData[index].value}
                              onChange={(e) => handleOtherOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={otherFormData[index].width}
                               onChange={(e) => handleOtherChange(e, index, "width")}
                                placeholder="Width"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={otherFormData[index].height}
                               onChange={(e) => handleOtherChange(e, index, "height")}
                                placeholder="Height"
                              />
                            </Form.Group>
                          </Col>

                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={otherFormData[index].qnty}
                               onChange={(e) => handleOtherChange(e, index, "qnty")}
                                placeholder="Qty"
                              />
                            </Form.Group>
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={otherFormData[index].price}
                                // onChange={(e) => handleOtherPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={otherFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group>
                              <Form.Control
                              value={otherFormData[index].remark}
                              onChange={(e) => handleOtherRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row> 
                        : item.measurement == "qty" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={otherFormData[index].value}
                              onChange={(e) => handleOtherOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={otherFormData[index].qnty}
                               onChange={(e) => handleOtherChange(e, index, "qnty")}
                                placeholder="Qty"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={otherFormData[index].price}
                                // onChange={(e) => handleOtherPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={otherFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group>
                              <Form.Control
                              value={otherFormData[index].remark}
                              onChange={(e) => handleOtherRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row> 
                        : item.measurement == "length" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={otherFormData[index].value}
                              onChange={(e) => handleOtherOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={otherFormData[index].height}
                               onChange={(e) => handleOtherChange(e, index, "height")}
                                placeholder="Length"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={otherFormData[index].width}
                               onChange={(e) => handleOtherChange(e, index, "width")}
                                placeholder="Width"
                              />
                            </Form.Group>
                          </Col>
                         
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={otherFormData[index].price}
                                // onChange={(e) => handleOtherPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={otherFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group>
                              <Form.Control
                              value={otherFormData[index].remark}
                              onChange={(e) => handleOtherRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row> : item.measurement == "rft" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={otherFormData[index].value}
                              onChange={(e) => handleOtherOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={otherFormData[index].rft}
                               onChange={(e) => handleOtherChange(e, index, "rft")}
                                placeholder="RFT"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={otherFormData[index].price}
                                // onChange={(e) => handleOtherPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={otherFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group>
                              <Form.Control
                              value={otherFormData[index].remark}
                              onChange={(e) => handleOtherRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row> : item.measurement == "painting" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={otherFormData[index].value}
                              onChange={(e) => handleOtherOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={otherFormData[index].painting}
                               onChange={(e) => handleOtherChange(e, index, "painting")}
                                placeholder="Area"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={otherFormData[index].price}
                                // onChange={(e) => handleOtherPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={otherFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group>
                              <Form.Control
                              value={otherFormData[index].remark}
                              onChange={(e) => handleOtherRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row> : null
                                ))}
                     

                      </Col>
                    </Row>
                  
                  </Form.Group>


                </Form>
              </Col>
            </Row>
            <Row className="mt-2">
                      <Col className="col-sm-5">
                      <Button className="w-25 rounded-1" style={{fontSize:16,fontWeight:600}} onClick={() => setRoomTypeModal("")} >Go Back</Button>

                        <Button className="w-50 rounded-1" style={{fontSize:16,fontWeight:600}} onClick={() => saveOtherData()} >Save</Button>
                      </Col>
                      <Col className="col-sm-3">
                        <h4>Total Price</h4>
                      </Col>
                      <Col className="col-sm-2"></Col>
                      <Col className="col-sm-2">
                        <h4>
                          Rs:- {roomTypePrice}

                        </h4>
                      </Col>
                    </Row>

  </Row>   : roomTypeModal == 'Loose Furniture' ?  
  <Row className="p-2" >
  <h4>loose Furniture</h4>
     
       
            <Row  style={{ backgroundColor: "#F2F2F2", height: 'auto', overflowY: 'auto' }}>
              <Col style={{ minHeight: 380, maxHeight: 480 }}>
             
                <Form>

                  <Form.Group>
                    <Row>

                      <Col sm={12}>
                        
                                {looseThingsData.map((item, index) => (
                                 item.measurement == "area" ?  
                                 <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={looseFormData[index].value}
                              onChange={(e) => handleLooseOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={looseFormData[index].area}
                               onChange={(e) => handleLooseChange(e, index, "area")}
                                placeholder="Area"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={looseFormData[index].price}
                                // onChange={(e) => handleLoosePriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={looseFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group>
                              <Form.Control
                              value={looseFormData[index].remark}
                              onChange={(e) => handleLooseRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                         : item.measurement == "width" ? 
                          <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={looseFormData[index].value}
                              onChange={(e) => handleLooseOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={looseFormData[index].width}
                               onChange={(e) => handleLooseChange(e, index, "width")}
                                placeholder="Width"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={looseFormData[index].height}
                               onChange={(e) => handleLooseChange(e, index, "height")}
                                placeholder="Height"
                              />
                            </Form.Group>
                          </Col>

                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={looseFormData[index].qnty}
                               onChange={(e) => handleLooseChange(e, index, "qnty")}
                                placeholder="Qty"
                              />
                            </Form.Group>
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={looseFormData[index].price}
                                // onChange={(e) => handleLoosePriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={looseFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group>
                              <Form.Control
                              value={looseFormData[index].remark}
                              onChange={(e) => handleLooseRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row> 
                        : item.measurement == "qty" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={looseFormData[index].value}
                              onChange={(e) => handleLooseOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={looseFormData[index].qnty}
                               onChange={(e) => handleLooseChange(e, index, "qnty")}
                                placeholder="Qty"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={looseFormData[index].price}
                                // onChange={(e) => handleLoosePriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={looseFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group>
                              <Form.Control
                              value={looseFormData[index].remark}
                              onChange={(e) => handleLooseRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row> 
                        : item.measurement == "length" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={looseFormData[index].value}
                              onChange={(e) => handleLooseOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={looseFormData[index].height}
                               onChange={(e) => handleLooseChange(e, index, "height")}
                                placeholder="Length"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={looseFormData[index].width}
                               onChange={(e) => handleLooseChange(e, index, "width")}
                                placeholder="Width"
                              />
                            </Form.Group>
                          </Col>
                        
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={looseFormData[index].price}
                                // onChange={(e) => handleLoosePriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={looseFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group>
                              <Form.Control
                              value={looseFormData[index].remark}
                              onChange={(e) => handleLooseRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row> : item.measurement == "rft" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={looseFormData[index].value}
                              onChange={(e) => handleLooseOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={looseFormData[index].rft}
                               onChange={(e) => handleLooseChange(e, index, "rft")}
                                placeholder="RFT"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={looseFormData[index].price}
                                // onChange={(e) => handleLoosePriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={looseFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group>
                              <Form.Control
                              value={looseFormData[index].remark}
                              onChange={(e) => handleLooseRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row> : null
                                ))}
                     

                      </Col>
                    </Row>
                  
                  </Form.Group>


                </Form>
              </Col>
            </Row>
            <Row className="mt-2">
                      <Col className="col-sm-5">
                      <Button className="w-25 rounded-1" style={{fontSize:16,fontWeight:600}} onClick={() => setRoomTypeModal("")} >Go Back</Button>

                        <Button className="w-50 rounded-1" style={{fontSize:16,fontWeight:600}} onClick={() => saveLooseData()} >Save</Button>
                      </Col>
                      <Col className="col-sm-3">
                        <h4>Total Price</h4>
                      </Col>
                      <Col className="col-sm-2"></Col>
                      <Col className="col-sm-2">
                        <h4>
                          Rs:- {roomTypePrice}

                        </h4>
                      </Col>
                    </Row>

  </Row>  :  roomTypeModal == 'Accessories' ?  
  <Row className="p-2" >
  <h4>Accessories</h4>
     
       
            <Row  style={{ backgroundColor: "#F2F2F2", height: 'auto', overflowY: 'auto' }}>
              <Col style={{ minHeight: 380, maxHeight: 480 }}>
             
                <Form>

                  <Form.Group>
                    <Row>

                      <Col sm={12}>
                        
                                {accessoriesThingsData.map((item, index) => (
                                 item.measurement == "area" ?  
                                 <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={accessoriesFormData[index].value}
                              onChange={(e) => handleAccessoriesOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={accessoriesFormData[index].area}
                               onChange={(e) => handleAccessoriesChange(e, index, "area")}
                                placeholder="Area"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={accessoriesFormData[index].price}
                                // onChange={(e) => handleAccessoriesPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={accessoriesFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group>
                              <Form.Control
                              value={accessoriesFormData[index].remark}
                              onChange={(e) => handleAccessoriesRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                         : item.measurement == "width" ? 
                          <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={accessoriesFormData[index].value}
                              onChange={(e) => handleAccessoriesOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={accessoriesFormData[index].width}
                               onChange={(e) => handleAccessoriesChange(e, index, "width")}
                                placeholder="Width"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={accessoriesFormData[index].height}
                               onChange={(e) => handleAccessoriesChange(e, index, "height")}
                                placeholder="Height"
                              />
                            </Form.Group>
                          </Col>

                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={accessoriesFormData[index].qnty}
                               onChange={(e) => handleAccessoriesChange(e, index, "qnty")}
                                placeholder="Qty"
                              />
                            </Form.Group>
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={accessoriesFormData[index].price}
                                // onChange={(e) => handleAccessoriesPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={accessoriesFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group>
                              <Form.Control
                              value={accessoriesFormData[index].remark}
                              onChange={(e) => handleAccessoriesRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row> 
                        : item.measurement == "qty" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={accessoriesFormData[index].value}
                              onChange={(e) => handleAccessoriesOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={accessoriesFormData[index].qnty}
                               onChange={(e) => handleAccessoriesChange(e, index, "qnty")}
                                placeholder="Qty"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={accessoriesFormData[index].price}
                                // onChange={(e) => handleAccessoriesPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={accessoriesFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group>
                              <Form.Control
                              value={accessoriesFormData[index].remark}
                              onChange={(e) => handleAccessoriesRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row> 
                        : item.measurement == "length" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={accessoriesFormData[index].value}
                              onChange={(e) => handleAccessoriesOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={accessoriesFormData[index].height}
                               onChange={(e) => handleAccessoriesChange(e, index, "height")}
                                placeholder="Length"
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={1}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={accessoriesFormData[index].width}
                               onChange={(e) => handleAccessoriesChange(e, index, "width")}
                                placeholder="Width"
                              />
                            </Form.Group>
                          </Col>
                        
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={accessoriesFormData[index].price}
                                // onChange={(e) => handleAccessoriesPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={accessoriesFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group>
                              <Form.Control
                              value={accessoriesFormData[index].remark}
                              onChange={(e) => handleAccessoriesRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row> : item.measurement == "rft" ? 
                         <Row className="mx-1 my-3">
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Label
                                style={{
                                  fontSize: 16,
                                  fontWeight: "500",
                                }}
                              >{item.name}
                              </Form.Label>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group>
                              <Form.Select
                              value={accessoriesFormData[index].value}
                              onChange={(e) => handleAccessoriesOptionChange(e, index)}
                     

                              >
                                <option value="0">None</option>
                                {item.material.map((item2, index2) => (
                                  <option value={item2.value} key={index2}>{item2.label}</option>
                                ))}
                                
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                          <Form.Group>
                              <Form.Control
                                type="number"
                                value={accessoriesFormData[index].rft}
                               onChange={(e) => handleAccessoriesChange(e, index, "rft")}
                                placeholder="RFT"
                              />
                            </Form.Group>
                          </Col>
                          
                          <Col sm={1}>
                            
                          </Col>

                          <Col sm={2}>
                            <Form.Group>
                              <Form.Control
                                type="number"
                                value={accessoriesFormData[index].price}
                                // onChange={(e) => handleAccessoriesPriceChange(e, index, "price")}
                                placeholder="Price"
                                disabled={accessoriesFormData[index].value ? false : true }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group>
                              <Form.Control
                              value={accessoriesFormData[index].remark}
                              onChange={(e) => handleAccessoriesRemarkChange(e, index, "remark")}
                                as="textarea" rows={1}
                                placeholder="Remark"
                              />
                            </Form.Group>
                          </Col>
                        </Row> : null
                                ))}
                     

                      </Col>
                    </Row>
                  
                  </Form.Group>


                </Form>
              </Col>
            </Row>
            <Row className="mt-2">
                      <Col className="col-sm-5">
                      <Button className="w-25 rounded-1" style={{fontSize:16,fontWeight:600}} onClick={() => setRoomTypeModal("")} >Go Back</Button>

                        <Button className="w-50 rounded-1" style={{fontSize:16,fontWeight:600}} onClick={() => saveAccessoriesData()} >Save</Button>
                      </Col>
                      <Col className="col-sm-3">
                        <h4>Total Price</h4>
                      </Col>
                      <Col className="col-sm-2"></Col>
                      <Col className="col-sm-2">
                        <h4>
                          Rs:- {roomTypePrice}

                        </h4>
                      </Col>
                    </Row>

  </Row>  : roomTypeModal == 'ExtraItems' ?  
  <Row className="p-2" >
  <h4>Extra items</h4>
     
       
            <Row  style={{ backgroundColor: "#F2F2F2", height: 'auto', overflowY: 'auto' }}>
              <Col style={{ minHeight: 380, maxHeight: 480 }}>
             
              {materialFields.map((field, index) => (
                            <Row key={index} className="mx-1 my-1">
                            <Col  sm={2}> <Form.Group
                            className="mb-3"
                           
                          >
                           
                        <Form.Label style={{
                                            fontSize: 16,
                                            fontWeight: "500",
                                          }}>Extra items</Form.Label>
                          </Form.Group></Col>
                            <Col  sm={3}> <Form.Group
                            className="mb-3"
                           
                          >
                           
                            <Form.Control
                              type="text"
                              value={field.label}
                              onChange={(e) => handleFieldChange(e.target.value, index, 'label')}
                              placeholder="Item Name"
                              required={false}
                            />
                          </Form.Group></Col>
                            <Col  sm={3}> <Form.Group
                            className="mb-3"
                            
                          >
                           
                            <Form.Control
                              type="number"
                              value={field.value}
                              onChange={(e) => handleFieldChange(e.target.value, index, 'value')}
                              placeholder="Item Price"
                              required={false}
                            />
                          </Form.Group>
                          </Col>
                          <Col  sm={3}> <Form.Group
                            className="mb-3"
                            
                          >
                           
                            <Form.Control
                              as="textarea" rows={1}
                              value={field.remark}
                              onChange={(e) => handleFieldChange(e.target.value, index, 'remark')}
                              placeholder="Remark"
                              required={false}
                            />
                               
                          </Form.Group>
                          </Col>
                            <Col  sm={1}> <Button variant="danger" onClick={() => handleRemoveField(index)} >
                            <i class="bi bi-x-circle-fill"></i>
                            </Button></Col>
                            <Col>
                          
                          </Col>
                          </Row>
                           ))}
                           <Col><Button  variant="success" onClick={handleAddField} > Add options <i class="bi bi-plus-circle-fill"></i> </Button></Col>

              </Col>
            </Row>
            <Row className="mt-2">
                      <Col className="col-sm-5">
                      <Button className="w-25 rounded-1" style={{fontSize:16,fontWeight:600}} onClick={() => setRoomTypeModal("")} >Go Back</Button>

                        <Button className="w-50 rounded-1" style={{fontSize:16,fontWeight:600}} onClick={() => saveExtraItemsData()} >Save</Button>
                      </Col>
                      <Col className="col-sm-3">
                        <h4>Total Price</h4>
                      </Col>
                      <Col className="col-sm-2"></Col>
                      <Col className="col-sm-2">
                        <h4>
                          Rs:- {totalValues()}

                        </h4>
                      </Col>
                    </Row>

  </Row>  : null} 
            
           
           

             {/* Bedroom modal */}
             
          </Row>

        </Col>
      </Row>

    </Container>
  );
}
