import React from 'react';
import ExcelJS from 'exceljs';

class ExportExcel extends React.Component {
    exportToExcel = () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Table Data');
    
        // Extract data from HTML table
        const table = document.getElementById('htmlTable');
        const rows = table.querySelectorAll('tr');
        rows.forEach(row => {
          const rowData = [];
          row.querySelectorAll('th, td').forEach(cell => {
            if (cell.tagName === 'IMG') {
              // If the cell is an image, add the base64 encoded data
              const imgBase64 = this.getBase64Image(cell);
              rowData.push({ image: imgBase64 });
            } else {
              rowData.push(cell.textContent);
            }
          });
          worksheet.addRow(rowData);
        });
    
        // Generate Excel file
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'table_data.xlsx';
          a.click();
          window.URL.revokeObjectURL(url);
        });
      };
    
      // Function to convert image to base64
      getBase64Image = (img) => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
    
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
    
        const dataURL = canvas.toDataURL('image/png');
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
      };

  render() {
    return (
      <div>
        <table id="htmlTable">
        <tr>
              <td><img src={require('../assests/sitelogo.png')} alt="Company Logo"  style={{width:'50%',height:'auto'}}/></td>
              <td>Company Name</td>
              {/* Add more table cells as needed */}
            </tr>
        <tr style={{backgroundColor:'#92c3df'}}>
                  <th  colSpan={9} style={{textAlign:'center'}}>General terms</th>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>1</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Chimney, Sink, Hob, Gas Piping, Granite along with the labour cost associated are not included in this quotation unless mentioned
otherwise.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>2</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Light Fittings, Geyser, Bathroom Fittings, Fans and any other electrical fittings are not included in this quotation unless mentioned
otherwise.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>3</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Any product that is not added in this Quotation has to be assumed as Non Tendered Product, a revised quote will be provided for the
same. FABDIZ will only be responsible for work mentioned in the quotation.
</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>4</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Alteration of the existing electrical wiring/points and plumbing wiring/points will be charged extra and for any civil changes like plumbing
and wall chipping we need civil engineers for instructions.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>5</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>FABDIZ execution team takes the responsibility of moving debris to Garbage Room of the apartment premises.
</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>6</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>FABDIZ execution team takes the responsibility of cleaning floor & interiors before moving out of the site (Deep cleaning not included).</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>7</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Sliding Door Track fitting will bring 15-25mm gap between the Sliding Door and loft. As we need that gap during the maintenance. Sliding
Door Track fitting will bring 10mm gap between the door overlapping.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>8</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Product with glossy finish may have very less unevenness look and feel due to lighting effect.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>9</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>This quotation will be valid for 1 month.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>10</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}><b>Booking advance :</b> A non-refundable booking advance to proceed to the design phase (Goes under store credit.)
</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>11</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}><b>Design Sign-off :</b> Payment made after finalising your design with your dedicated FABDIZ designer.
</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>12</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}><b>Before Installation :</b> Once your order has been manufactured, 40% payment is made just before installation PDC of 10%(i.e. before
dispatch).</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>13</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}><b>Interior Design :</b> Starts after initial 40k payment of project value</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>14</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>The 45 Days Time period is computed from the date of next 50% payment received and after availability selected materials by client at
FABDIZ (Design Phase 40K + Production Phase 50% + Dispatch date 40%+Handover 10% = 100%). We are happy to inform you that
we strictly follow 45 working Days Delivery post sign-off (45 Days timeline will be calculated only after material availability selected by
client).</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>15</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}><b>Installation Stage:</b> Starts after 90% payment has been made (Design Phase 40k + Production Phase 50% + Installation Phase 40% =
90%).</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>16</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}><b>Post Installation Stage:</b> % After completion of the work. Client have to provide post dated cheque with signature by mentioning the 10%
value while paying 50% payment. After completion of the work fabdiz will deposit cheque same day of handover. (Approximate date
of handover will be provided by Fabdiz Sales Executive)(Design Phase 40k + Production Phase 50% + Installation Phase 40% + Post
Installation Phase 10% = 100%)
</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>17</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Final coat of paint should be done after completion of interior work from client side We are unable to avoid handmarks, scratches during
installation.
</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>18</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>There will be difference between in handmade furniture and modular furniture. As handmade furniture by carpenters on site with hand
tools so as compared to modular work handmade furniture finishing will differ.
</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>19</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Images shown in the 3d are for reference, please refer actual samples for colour selection and texture / wallpapers or any decor items
which might be shown only for reference</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>20</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Design alterations will not be considered once the design document is signed off or once FADBIZ get confirmation for design document
through mail/electronic media</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>21</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Production date will start as per the schedule that will be shared.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>22</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Any modifications at site should be informed to the designer and project manager prior to production.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>23</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>FABDIZ will not be responsible for products purchased from elsewhere</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>24</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Shutters / Panels can have warpage / bend upto 3 to 4mm per RMT. This is not manufacturing defect as wood have tendency of bend
and no replacement can be given for this We suggest having straightener for any door above 1600mm height to avoid further warping
of the panel in future.
</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>25</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Shutters / Panels can have hairline scratch. This is not manufacturing defect as laminate surface are scratch resistant not scratch proof
and no replacement can be given for this.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>26</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>The Entertainment Cabinet and other modular cabinets more than 2400MM will be having two cabinets in that case the joint at the top
of the cabinet will be visible.</td>
              </tr>
             
              <tr style={{backgroundColor:'#92c3df'}}>
                  <th  colSpan={9} style={{textAlign:'center'}}>Design Process</th>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>1</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}><b>Site Visit :</b> Once booking advance paid Interior Designer will visit the client site with measurement team.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>2</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}><b>Discussion Meeting:</b> Designer will show the modular library, completed project photographs which will match to client requirement</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>3</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}><b>Modeling Presentation:</b> As per client requirement and placement of furniture designer will present modeling. (upto 2 iteration can be
done in the 3d model stage post that timelines may change as per additional design changes.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>4</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}><b>3D Design Presentation:</b> will present 3D design which will have the texture selected by client.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>5</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}><b>Site Validation:</b> Validation will be done at site where FABDIZ will execute project. Designer will visit the site with Project Manager for
Masking Measurement. Masking measurement will be possible only on cleaned floor.
</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>6</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Fabdiz, Need a minimum of 3 working days to mobilise and start the works, post design sign off. This is to arrange needful material
and manpower required and allocate PM for project. Also, the validity of budgets/quote submitted to client will be for 5 working days.</td>
              </tr>


              <tr style={{backgroundColor:'#92c3df'}}>
                  <th  colSpan={9} style={{textAlign:'center'}}>Payment Process ( Final design quote to be considered )</th>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>1</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>10% appointment investment as a designer (Part of final quote).</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>2</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>50% of final design quote value, upon design sign off to start production.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>3</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}> 30% before the dispach of the material is done at site</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>4</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}> 10% on completion of work (PDC to be given before the dispatch of materials)</td>
              </tr>
        </table>
        <button onClick={this.exportToExcel}>Export to Excel</button>
      </div>
    );
  }
}

export default ExportExcel;
