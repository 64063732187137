import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";


const  initialState={
    designNames:[],
    designModal:false,
    designError:{}
}

export const duplicateDesigns = createAsyncThunk('duplicateDesignList',
async (designData) => {
    await db.collection("client").doc(designData.id).collection("Rooms").doc().set(designData);
  });

const duplicationDesignReducer = createSlice({
    name:'duplicateDesign',
    initialState,
    reducers:{},
    extraReducers:{
      [duplicateDesigns.fulfilled]:(state,action)=>{
        state.designModal = true;
      },
      [duplicateDesigns.pending]:(state,action)=>{
        state.designModal = false;
      },
      [duplicateDesigns.rejected]:(state,action)=>{
        state.designModal = false;
      },
    }
});

export default duplicationDesignReducer.reducer