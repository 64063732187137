import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";

const  initialState={
    quoteData:[],
    quoteLoader:true,
    quoteError:"",
}

export const fetchQuotationList = createAsyncThunk('fetchquotes',
async(viewData)=>{
  // db.collection("client").doc('roomData.url').collection("Rooms")
    const projectList = await db
    .collection("client").doc(viewData.Id).collection("Quotations").orderBy('timestamp', 'desc')
    // .collection("Room" +  auth.currentUser.uid)
    // .where("status", "==", "Ongoing")
    // .where("ID", "==", auth.currentUser.uid)
    .get();
  const projectListArray = [];
  for (var snap of projectList.docs) {
    var data = snap.data();
    data.ID = snap.id;
    projectListArray.push({
      ...data,
    });
    if (projectListArray.length === projectList.docs.length) {
      console.log("roomlISt", projectListArray);
      return projectListArray
    }
  }
});

const quotationReducer = createSlice({
    name:'viewquotes',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchQuotationList.fulfilled]:(state,action)=>{
            state.quoteData = action.payload
            state.quoteLoader = false
        },
        [fetchQuotationList.pending]:(state,action)=>{
            state.quoteLoader = true
        },
        [fetchQuotationList.rejected]:(state,action)=>{
            state.quoteError = "refresh the page"
        },
    }
});

export default quotationReducer.reducer