import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";

const  initialState={
    kdata:[],
    kloader:true,
    kerror:"",
}

export const fetchKitchenList = createAsyncThunk('fetchkitchens',
async()=>{
    const projectList = await db
    .collectionGroup("base_cabinet")
    // .where("status", "==", "Ongoing")
    // .where("ID", "==", auth.currentUser.uid)
    .get();
  const projectListArray = [];
  for (var snap of projectList.docs) {
    var data = snap.data();
    data.ID = snap.id;
    projectListArray.push({
      ...data,
    });
    if (projectListArray.length === projectList.docs.length) {
      return projectListArray
    }
  }
});

const kitchenReducer = createSlice({
    name:'kitchens',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchKitchenList.fulfilled]:(state,action)=>{
            state.kdata = action.payload
            state.kloader = false
        },
        [fetchKitchenList.pending]:(state,action)=>{
            state.kloader = true
        },
        [fetchKitchenList.rejected]:(state,action)=>{
            state.kerror = "refresh the page"
        },
    }
});

export default kitchenReducer.reducer