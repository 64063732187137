import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";


const  initialState={
    updateNames2:[],
  updateModal2:false,
  updateError2:{}
}

export const updateRooms2 = createAsyncThunk('updateRoomList2', async (roomDatas) => {
  try {
    await db.collection('client').doc(roomDatas.id).collection('Rooms').doc(roomDatas.id2).update({
      title: roomDatas.title,
      roomTypePrice: roomDatas.roomTypePrice,
    });
  
    return roomDatas; // Return data if needed in the fulfilled action
    
  } catch (error) {
    throw error; // Handle the error in the rejected action
  }
});

const updateRooms2Reducer = createSlice({
    name:'updateRoom2',
    initialState,
    reducers:{},
    extraReducers:{
      [updateRooms2.fulfilled]:(state,action)=>{
        state.updateModal2 = true;
      },
      [updateRooms2.pending]:(state,action)=>{
        state.updateModal2 = false;
      },
      [updateRooms2.rejected]:(state,action)=>{
        state.updateModal2 = false;
      },
    }
});

export default updateRooms2Reducer.reducer