import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";

const  initialState={
    foyerThingsData:[],
    foyerThingsLoader:true,
    foyerThingsError:"",
}

export const fetchFoyerThingsList = createAsyncThunk('fetchfoyerThings',
async()=>{
    const projectList = await db
    .collection("Foyer").orderBy('timestamp', 'asc')
    // .where("status", "==", "Ongoing")
    // .where("ID", "==", auth.currentUser.uid)
    .get();
  const projectListArray = [];
  for (var snap of projectList.docs) {
    var data = snap.data();
    data.ID = snap.id;
    projectListArray.push({
      ...data,
    });
    if (projectListArray.length === projectList.docs.length) {
      return projectListArray
    }
  }
});

const foyerReducer = createSlice({
    name:'foyerThings',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchFoyerThingsList.fulfilled]:(state,action)=>{
            state.foyerThingsData = action.payload
            state.foyerThingsLoader = false
        },
        [fetchFoyerThingsList.pending]:(state,action)=>{
            state.foyerThingsLoader = true
        },
        [fetchFoyerThingsList.rejected]:(state,action)=>{
            state.foyerThingsError = "refresh the page"
        },
    }
});

export default foyerReducer.reducer