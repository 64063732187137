import React,{ useEffect, useState }  from 'react';
import { useSearchParams, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientQuotationList } from '../redux/clientQuotationReducer';
import { Card, Container, Row, Col, ListGroup, Tab, Nav, Button, Accordion, Table } from 'react-bootstrap';
import { Document, Page,  Text, View, StyleSheet, Image  } from '@react-pdf/renderer';
import logo from "../assests/PDFlogo.png";
import logo2 from "../assests/fabc.png";
import scan from "../assests/scanner1.jpeg";
import scan1 from "../assests/scanCopy.jpeg";
import MyTablePDF from './MyTablePDF';

export default function ClientPDF({e}) {


const styles = StyleSheet.create({
  page : {
    paddingTop: 35,
    paddingBottom: 60,
    paddingHorizontal: 30,
    backgroundColor: '#f8f8f8' ,
    // backgroundColor: '#111111' ,
},
  title: {
   
  },
  scan: {
    width: '100%',
    height: 'auto',
  
},
  logo: {
    width: '30%',
    height: 'auto',
    paddingBottom:'1%'
},
  logo12: {
    width: '45%',
    height: 'auto',
    paddingBottom:'1%'
},
logo22: {
  width: '25%',
  height: 'auto',
  paddingBottom:'1%'
},

  row: {
    flexDirection: 'row',
    marginBottom:5,
  },
  block: {
   
  },
  heading:{
    fontSize:12,
    color:'#111111'
  },
  clientName:{
    fontSize:12,
    fontWeight:'bold',
    color:'#000000'
  },
  heading1:{
    fontSize:25,
    color:'#154889',
    marginBottom:5,
  },
  text1: {
    textAlign: 'left',
    fontSize:10,
    fontWeight:100,
    color:'#2F2E2E'
  },
  text1a: {
    textAlign: 'left',
    fontSize:12,
    fontWeight:'bold',
    color:'#000000'
  },
  text2: {
    fontSize:12,
    fontWeight:'100',
    color:'#2F2E2E',
  },
  text: {
    textAlign: 'left',
    textOverflow:'ellipsis',
        fontSize:12,
        fontWeight:500,
       
        color:'#2F2E2E'
  },
  fill1: {

  },
  column:
  {
    flexDirection:'column',
    flex:1,
    borderWidth:1,
    borderColor:'#bdbdbd',
     borderStyle:'solid',
     borderRadius:5,
     padding:8,
     backgroundColor:'#FFFFFF',
  },
  column2:
  {
    flexDirection:'column',
    flex:2,
    borderWidth:1,
    borderColor:'#bdbdbd',
     borderStyle:'solid',
     borderRadius:5,
     padding:8,
     backgroundColor:'#FFFFFF',
  },
  column3:
  {
    flexDirection:'column',
    flex:1,
    borderWidth:1,
    borderColor:'#bdbdbd',
     borderStyle:'solid',
     borderRadius:5,
     padding:4,
     backgroundColor:'#FFFFFF',
  },
  columnFirst:
  {

  },
  headingColumn:
  {
    flexDirection:'column',
   flex:1,
   height:50,
   borderWidth:1,
   borderColor:'#bdbdbd',
    borderStyle:'solid',
    borderRadius:5,
    padding:8,
    backgroundColor:'#FFFFFF'
  },
   logoColumn:
  {
    flexDirection:'column',
    flex:1,
   padding:8,
  },
  table: {

  },
  tableRow: {
 
  },
  tableCell: {
   
  },
  pageNumber: {
    position: 'absolute',
  
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  separator: {
    flexDirection: 'row',
    paddingTop: 5,
    paddingHorizontal: 30,
    borderTopWidth: 1,
    borderTopStyle: 'solid', // React Native may not support `borderTopStyle` on some platforms; consider using `borderStyle` instead
    borderTopColor: '#2f2f2e',
  },

});



  return (
    <Document>
    <Page size="A4"  style={styles.page} wrap>
    <Image style={styles.logo} src={logo} />
    <Text style={[styles.text1,{marginBottom:'4%'}]} >You are about to experience creativity and commitment at its best</Text>

    <View style={styles.row}>
          <View style={[styles.column,{marginRight:8}]}>

          
            {/* <Text style={styles.heading}>Mr./Mrs. Srinivas</Text> */}
                    <Text style={styles.heading}>
                    Hi{' '}
                    <Text style={styles.clientName}>
                      {e.clientDetails.cname}
                    </Text>
                    , welcome to the Fabdiz family!
                  </Text>
            {/* <Text style={styles.heading}>Hi {e.clientDetails.cname}, welcome to the Fabdiz family!</Text> */}
            <Text style={styles.text2}>Thank you for taking the time and having a discussion with me. <Text style={styles.heading}>We have taken into account all your requirements and created a quote for you. We believe it will perfectly suit your style and budget.</Text></Text>
            {/* <Text style={styles.text}>{e.clientDetails.createdBy}</Text> */}
            {/* <Text style={styles.text}>{e.clientDetails.city}</Text> */}
            {/* <Text style={styles.text}>{e.clientDetails.state}</Text> */}
          </View>
          <View style={[styles.column,{marginLeft:8}]}>
          {/* <Image style={styles.logo} src={logo} /> */}
          <Text style={styles.text1a}>{e.userDetials[0].name}</Text> 
          <Text style={styles.text1a}>&nbsp;</Text> 
          <Text style={styles.text}>{e.userDetials[0].designation}</Text>            
                     
            <Text style={styles.text1}>+91 {e.userDetials[0].mobile}</Text>
            <Text style={styles.text1}>{e.userDetials[0].email}</Text>
            <Text style={styles.text1a}>&nbsp;</Text> 
            <Text style={styles.text1}>Date: {new Date().toLocaleDateString()}</Text>
          </View>
          
      </View>



                {/* Forth row start */}
                <View style={styles.row}>
          <View style={styles.headingColumn}>
            <Text style={{fontSize:22,fontWeight:'bold',color:'#2F2E2E',textAlign:'center'}}>ROOMS</Text>
          </View>
        
      </View>
       {/* Forth  row end */}
        {/* fifth row start */}
        {e.quotationData.map((item, index)=>(
          <View style={{flexDirection:'row',marginTop:4,marginBottom:4}}>
          <View style={styles.column}>
          <View  style={{flexDirection:'row'}}>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:21,color:'#000000',fontWeight:'bold',paddingBottom:5}}>{item.roomTypeName}</Text></View>
          </View>
          <View  style={{flexDirection:'row',backgroundColor:'#f8f8f8',borderColor:'#bdbdbd',borderWidth:1,borderStyle:'solid',padding:10}}>
            <View style={{flexDirection:'column',flex:2}}><Text style={{fontSize:9,color:'#000000',fontWeight:'bold'}}>Items / Description</Text></View>
            <View style={{flexDirection:'column',flex:2}}><Text style={{fontSize:9,color:'#000000',fontWeight:'bold'}}>Finish</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:9,color:'#000000',fontWeight:'bold',textAlign:'center'}}>Width {'\n'} <Text style={{fontSize:6,textAlign:'center'}}>(FT)</Text></Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:9,color:'#000000',fontWeight:'bold',textAlign:'center'}}>Height {'\n'} <Text style={{fontSize:6,textAlign:'center'}}>(FT)</Text></Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:9,color:'#000000',fontWeight:'bold',textAlign:'center'}}>Area {'\n'} <Text style={{fontSize:6,textAlign:'center'}}>(Sqft/RFT)</Text></Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:9,color:'#000000',fontWeight:'bold',textAlign:'center'}}>Qty</Text></View>
            {/* <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:9,color:'#000000',fontWeight:'bold',textAlign:'center'}}>Price</Text></View> */}
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:9,color:'#000000',fontWeight:'bold',textAlign:'center'}}>Amount</Text></View>
            <View style={{flexDirection:'column',flex:2}}><Text style={{fontSize:9,color:'#000000',fontWeight:'bold',textAlign:'center'}}>Remark</Text></View>
          </View>

          {/* map function put here for printing data*/}

          <View  style={{flexDirection:'column',padding:10}}>
          {item.title ? item.title.map((item2, index2) =>(
            item2.price > 0 && item2.name == "Wardrobe - Slider" ?   <View style={{flexDirection:'row',paddingBottom:5,paddingTop:5,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed',}}>
            <View style={{flexDirection:'column',flex:2}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold'}}>{item2.name} {item2.label2 ? `  (${item2.label2})` : null}</Text></View>
            <View style={{flexDirection:'column',flex:2}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold'}}>{item2.label}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.width > 0 ? item2.width : null}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.height > 0 ? item2.height : null}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.area > 0 ? item2.area : null} {item2.rft > 0 ? item2.rft : null}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.qnty > 0 ? item2.qnty : null}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.price > 0 ? item2.price : null}</Text></View>
            <View style={{flexDirection:'column',flex:2}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.remark}</Text></View>
            </View> :  item2.price > 0 || item2.name == "Shape Of Kitchen" ?   <View style={{flexDirection:'row',paddingBottom:5,paddingTop:5,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed',}}>
            <View style={{flexDirection:'column',flex:2}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold'}}>{item2.name}</Text></View>
            <View style={{flexDirection:'column',flex:2}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold'}}>{item2.label}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.width > 0 ? item2.width : null}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.height > 0 ? item2.height : null}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.area > 0 ? item2.area : null} {item2.rft > 0 ? item2.rft : null}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.qnty > 0 ? item2.qnty : null}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.price > 0 ? item2.price : null}</Text></View>
            <View style={{flexDirection:'column',flex:2}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.remark}</Text></View>
            </View> :  item2.price > 0 ?   <View style={{flexDirection:'row',paddingBottom:5,paddingTop:5,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed',}}>
            <View style={{flexDirection:'column',flex:2}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold'}}>{item2.name}</Text></View>
            <View style={{flexDirection:'column',flex:2}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold'}}>{item2.label}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.width > 0 ? item2.width : null}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.height > 0 ? item2.height : null}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.area > 0 ? item2.area : null} {item2.rft > 0 ? item2.rft : null}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.qnty > 0 ? item2.qnty : null}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.price > 0 ? item2.price : null}</Text></View>
            <View style={{flexDirection:'column',flex:2}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.remark}</Text></View>
            </View> :  item2.value > 0 ?   <View style={{flexDirection:'row',paddingBottom:5,paddingTop:5,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed',}}>
            <View style={{flexDirection:'column',flex:2}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold'}}>{item2.name}</Text></View>
            <View style={{flexDirection:'column',flex:2}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold'}}>{item2.label}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.width > 0 ? item2.width : null}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}></Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}></Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.qnty > 0 ? item2.qnty : null}</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.value}</Text></View>
            <View style={{flexDirection:'column',flex:2}}><Text style={{fontSize:8,color:'#2F2E2E',fontWeight:'bold',textAlign:'center'}}>{item2.remark}</Text></View>
            </View> : null
          )) : null}
          <View style={{flexDirection:'column',flex:2,marginTop:5}}><Text style={{fontSize:9,color:'#000000',fontWeight:'bold'}}>Total</Text></View>
            <View style={{flexDirection:'column',flex:1}}><Text style={{fontSize:9,color:'#000000',fontWeight:'bold',textAlign:'right'}}>{item.roomTypePrice}</Text></View>
         
          </View>

          </View>
          </View>
        )
        )}
       
        {/* fifth row end */}


     {/* payment breakups start */}
        
     <View style={styles.row} break>
          <View style={styles.headingColumn}>
            <Text style={{fontSize:22,fontWeight:'bold',color:'#2F2E2E',textAlign:'center'}}>GROSS TOTAL</Text>
          </View>
        
      </View>
    
     
      <View style={styles.row}>
          <View style={styles.column}>
        
          <View style={{flexDirection:'row',margin:10}}>
            <View style={{flex:2,flexDirection:'column'}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Total</Text>
            </View>
            <View style={{flex:10,flexDirection:'row-reverse'}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>INR {e.quotationPrice}</Text>
            </View>
          </View>
          <View style={{flexDirection:'row',margin:10,paddingBottom:10,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed'}}>
            <View style={{flex:5,flexDirection:'column'}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Wood Work</Text>
           
            </View>
            <View style={{flex:7,flexDirection:'row-reverse'}}>
           
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>INR {e.quotationWood}</Text>
            </View>
          </View>
          <View style={{flexDirection:'row',margin:10,paddingBottom:10,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed'}}>
            <View style={{flex:5,flexDirection:'column'}}>
            
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Discount ({e.quotationDiscount}%)</Text>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>discount provided on all woodworks</Text>
            </View>
            <View style={{flex:7,flexDirection:'row-reverse'}}>
         
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>INR {Math.round(e.quotationWood * (e.quotationDiscount/100))}</Text>
            </View>
          </View>
          <View style={{flexDirection:'row',margin:10,paddingBottom:10,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed'}}>
            <View style={{flex:2,flexDirection:'column'}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Discounted Price</Text>
            </View>
            <View style={{flex:10,flexDirection:'row-reverse'}}>
          
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>INR {Math.round((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ))}</Text>
            </View>
          </View>
          <View style={{flexDirection:'row',margin:10,paddingBottom:10,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed'}}>
            <View style={{flex:2,flexDirection:'column'}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>GST (18%)</Text>
            
            </View>
            <View style={{flex:10,flexDirection:'row-reverse'}}>
          
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>INR {Math.round((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100)}</Text>
   
            </View>
          </View>
          <View style={{flexDirection:'row',padding:10}}>
            <View style={{flex:2,flexDirection:'column'}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Grand Total (GST)</Text>
            </View>
            <View style={{flex:10,flexDirection:'row-reverse'}}>
          
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>INR {Math.round((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)) + ((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100))}</Text>
            {/* <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>INR {Math.round((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)))}</Text> */}
         
            </View>
          </View>

          </View>
      </View>
     
     

      <View style={styles.row}>
          <View style={styles.headingColumn}>
            <Text style={{fontSize:22,fontWeight:'bold',color:'#2F2E2E',textAlign:'center'}}>PAYMENT BREAKUP</Text>
          </View>
        
      </View>
      <View style={styles.row}>
          <View style={styles.column}>
        
          <View style={{flexDirection:'row',margin:10}}>
            <View style={{flex:7,flexDirection:'column'}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Commitment Advance (10%)</Text>
            </View>
            <View style={{flex:5,flexDirection:'row-reverse'}}>
            
            <Text style={{fontSize:10,color:'#2F2E2E'}}>INR {Math.round(((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)) + ((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100)) * 10/100)}</Text>
            {/* <Text style={{fontSize:10,color:'#2F2E2E'}}>INR {Math.round(((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100))) * 10/100)}</Text> */}
            </View>
          </View>
          <View style={{flexDirection:'row',margin:10,paddingBottom:10,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed'}}>
            <View style={{flex:7,flexDirection:'column'}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Production Advance (40%)</Text>
            </View>
            <View style={{flex:5,flexDirection:'row-reverse'}}>
      
            <Text style={{fontSize:10,color:'#2F2E2E'}}>INR {Math.round(((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)) + ((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100)) * 40/100)}</Text>
            {/* <Text style={{fontSize:10,color:'#2F2E2E'}}>INR {Math.round(((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100))) * 40/100)}</Text> */}
            </View>
          </View>
          <View style={{flexDirection:'row',margin:10,paddingBottom:10,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed'}}>
            <View style={{flex:7,flexDirection:'column'}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Material Delivery (40%)</Text>
            </View>
            <View style={{flex:5,flexDirection:'row-reverse'}}>
          
            <Text style={{fontSize:10,color:'#2F2E2E'}}>INR {Math.round(((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)) + ((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100)) * 40/100)}</Text>
            {/* <Text style={{fontSize:10,color:'#2F2E2E'}}>INR {Math.round(((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100))) * 40/100)}</Text> */}
            </View>
          </View>
          <View style={{flexDirection:'row',padding:10}}>
            <View style={{flex:7,flexDirection:'column'}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>On Handover (10%)</Text>
            </View>
            <View style={{flex:5,flexDirection:'row-reverse'}}>
           
            <Text style={{fontSize:10,color:'#2F2E2E'}}>INR {Math.round(((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)) + ((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100)) * 10/100)}</Text>
            {/* <Text style={{fontSize:10,color:'#2F2E2E'}}>INR {Math.round(((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)) ) * 10/100)}</Text> */}
            </View>
          </View>

          </View>
      </View>


      
      {/* payment breakups end */}


      

  {/* terms & condition       */}
  <View style={styles.row} >
          <View style={styles.headingColumn}>
            <Text style={{fontSize:22,fontWeight:'bold',color:'#2F2E2E',textAlign:'center'}}>Material Specifications</Text>
          </View>
        
      </View>
      <View style={styles.row}>
          <View style={styles.column}>
        
          <View style={{margin:10,paddingBottom:10,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed'}}>
            <View style={{marginBottom:5}}>
            <Text style={{fontSize:12,color:'#2F2E2E',fontWeight:'bold'}}>Laminate:</Text>
            </View>
            <View style={{}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>(Merino, Greenlam, Century, Realtouch, Heritage, Royal Touch). External Matt Laminated Finish 1.0 mm (Any Brand - SF, upto Rs. 1200 per sheet), unless otherwise specified. Internal Matt finish laminate of 0.79-0.8 mm, Hazel Cambric/off white color, unless and otherwise specified.</Text>
            </View>
            
          </View>
          <View style={{margin:10,paddingBottom:10,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed'}}>
            <View style={{marginBottom:5}}>
            <Text style={{fontSize:12,color:'#2F2E2E',fontWeight:'bold'}}>PLY:</Text>
            </View>
            <View style={{}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Choose between Century Sainik & Prelaminated board FABDIZ make from Indiana. Kitchen, Utility and Washroom - Below Boxes in BWP (Water Proof) Ply - 16 mm (Calibrated). Kitchen above boxes, wardrobe, other units, such as crockery/display/loft unit etc. in MR (Moisture Resistant) Ply - 16 mm (Calibrated).</Text>
            </View>
            
          </View>
          <View style={{margin:10,paddingBottom:10,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed'}}>
            <View style={{marginBottom:5}}>
            <Text style={{fontSize:12,color:'#2F2E2E',fontWeight:'bold'}}>Hardware:</Text>
            </View>
            <View style={{}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Hafele/Hettich/EBCO Hinges and other hardware (Tandem Box, Channels, Sliders etc) shall be of regular operations (Soft Close on Demand). Handles, Knobs, shall be of local supply (with basic rate of Rs. 100/pc 8 inch and Rs, 50/Pc 4 inch) or as per selection . Wire-Baskets (Plate, thanli, cutlery or bottle pullout) if any, shall be stainless steel (Local Supply) grade-304. Wicker Basket if any, will be local supply as per IS standards and size customizable. Kitchen Skirting shall be a PVC make of approved color and finish with PVC adjustable legs. 5mm plain glass for glass shutters, unless otherwise specified with wooden frame. (Aluminium shutters can be on demand). 6mm plain glass for Shelves ad per drawings or unless otherwise specified.</Text>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Complimentary :- Handles/Knobs (upto 6 inch) from our selected board.</Text>
            </View>
            
          </View>
          <View style={{margin:10,paddingBottom:10,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed'}}>
            <View style={{marginBottom:5}}>
            <Text style={{fontSize:12,color:'#2F2E2E',fontWeight:'bold'}}>Others:</Text>
            </View>
            <View style={{}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>SS Shelf pins, Clamps, Screw and other fixtures - local supply. Dowels & Mini-fix - Hettich. Glue - Pedilite (Fevicol), Araldite, Jotun, Rehau, woodmaster or equivelent based on application. Edge Banding - Rehau or Equivalent (1mm or 2mm as per application).</Text>
            </View>
            
          </View>
          <View style={{margin:10}}>
            <View style={{marginBottom:5}}>
            <Text style={{fontSize:12,color:'#2F2E2E',fontWeight:'bold'}}>Paint, Polish & Powdercoat:</Text>
            </View>
            <View style={{}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>As per selection (Asian, Dulux, Berger, RAL), only if mentioned in quote.</Text>
            </View>
            
          </View>
         

          </View>
      </View> 

       <View style={styles.row}>
          <View style={styles.headingColumn}>
            <Text style={{fontSize:22,fontWeight:'bold',color:'#2F2E2E',textAlign:'center'}}>TERMS AND CONDITIONS</Text>
          </View>
        
      </View> 
      <View style={styles.row}>
          <View style={styles.column}>
        
          <View style={{margin:10,paddingBottom:10}}>
            <View style={{marginBottom:5}}>
            <Text style={{fontSize:12,color:'#2F2E2E',fontWeight:'bold'}}>Warranty :</Text>
            </View>
            <View style={{}}>
            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>1. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>We provide 10 years warranty for plywood, This safeguards you against any defect in manufacturing or in installation workmanship under normal usage. Hardware warranty will be apllicable based on the brands chosen For custom work, there is no warranty applicable. For more information about warranty policy we suggest you to ask FABDIZ warranty policy to our Design consultant.</Text></View>
            </View>
            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bolder'}}>Note :</Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>1 year of free servicing for our product.</Text></View>
            </View>
            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>2. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>All accessories, hardware and appliances are covered as per the respective Manufacturers.</Text></View>
            </View>

           
            
          </View>

          </View>
          <View style={{margin:10,paddingBottom:10}}>
            <View style={{marginBottom:5}}>
            <Text style={{fontSize:12,color:'#2F2E2E',fontWeight:'bold'}}>General terms :</Text>
            </View>
            <View style={{}}>
            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}> &nbsp;1. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Chimney, Sink, Hob, Gas Piping, Granite along with the labour cost associated are not included in this quotation unless mentioned otherwise.</Text></View>
            </View>
            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bolder'}}> &nbsp;2. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Light Fittings, Geyser, Bathroom Fittings, Fans and any other electrical fittings are not included in this quotation unless mentioned otherwise.</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}> &nbsp;3. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Any product that is not added in this Quotation has to be assumed as Non Tendered Product, a revised quote will be provided for the same. FABDIZ will only be responsible for work mentioned in the quotation.</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}> &nbsp;4. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Alteration of the existing electrical wiring/points and plumbing wiring/points will be charged extra and for any civil changes like plumbing and wall chipping we need civil engineers for instructions.</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}> &nbsp;5. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>FABDIZ execution team takes the responsibility of moving debris to Garbage Room of the apartment premises.</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}> &nbsp;6. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>FABDIZ execution team takes the responsibility of cleaning floor & interiors before moving out of the site (Deep cleaning not included).</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}> &nbsp;7. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Sliding Door Track fitting will bring 15-25mm gap between the Sliding Door and loft. As we need that gap during the maintenance. Sliding Door Track fitting will bring 10mm gap between the door overlapping.</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}> &nbsp;8. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Product with glossy finish may have very less unevenness look and feel due to lighting effect.</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}> &nbsp;9. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>This quotation will be valid for 1 month.</Text></View>
            </View>

           

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>10. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Final coat of paint should be done after completion of interior work from client side We are unable to avoid handmarks, scratches during installation.</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>11. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>There will be difference between in handmade furniture and modular furniture. As handmade furniture by carpenters on site with hand tools so as compared to modular work handmade furniture finishing will differ.</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>12. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Images shown in the 3d are for reference, please refer actual samples for colour selection and texture / wallpapers or any decor items which might be shown only for reference.</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>13. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Design alterations will not be considered once the design document is signed off or once FADBIZ get confirmation for design document through mail/electronic media</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>14. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Production date will start as per the schedule that will be shared.</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>15. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Any modifications at site should be informed to the designer and project manager prior to production.</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>16. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>FABDIZ will not be responsible for products purchased from elsewhere.</Text></View>
            </View>

           

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>17. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>The Entertainment Cabinet and other modular cabinets more than 2400MM will be having two cabinets in that case the joint at the top of the cabinet will be visible.</Text></View>
            </View>

           
            
          </View>

          </View>
          <View style={{margin:10,paddingBottom:10}}>
            <View style={{marginBottom:5}}>
            <Text style={{fontSize:12,color:'#2F2E2E',fontWeight:'bold'}}>Design Process :</Text>
            </View>
            <View style={{}}>
            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>1. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Site Visit : Once booking advance paid Interior Designer will visit the client site with measurement team.</Text></View>
            </View>
            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bolder'}}>2. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Discussion Meeting: Designer will show the modular library, completed project photographs which will match to client requirement.</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>3. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Modeling Presentation: As per client requirement and placement of furniture designer will present modeling. (upto 2 iteration can be done in the 3d model stage post that timelines may change as per additional design changes.</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>4. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>3D Design Presentation: will present 3D design which will have the texture selected by client.</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>5. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Site Validation: Validation will be done at site where FABDIZ will execute project. Designer will visit the site with Project Manager for Masking Measurement. Masking measurement will be possible only on cleaned floor.</Text></View>
            </View>

            

           
            
          </View>

          </View>
          <View style={{margin:10,paddingBottom:10}}>
            <View style={{marginBottom:5}}>
            <Text style={{fontSize:12,color:'#2F2E2E',fontWeight:'bold'}}>Payment Process ( Final design quote to be considered ):</Text>
            </View>
            <View style={{}}>
            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>1. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>10% appointment investment as a designer (Part of final quote).</Text></View>
            </View>
            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bolder'}}>2. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bolder'}}>40% of final design quote value, upon design sign off to start production.</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>3. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>40% before the dispach of the material is done at site</Text></View>
            </View>

            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>4. </Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>10% - PDC to be given after 90% of work done at site</Text></View>
            </View>
            
            <View style={{display:'flex',flexDirection:'row',paddingBottom:3}}>
              <View><Text style={{fontSize:10,color:'#111111',fontWeight:'bold'}}>Note:</Text></View>
              <View><Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Ensure any and all payments are made to the below listed account/QR code.</Text></View>
            </View>

           
            
          </View>

          </View>
      </View> 
      </View> 

{/* ending */}



{/* payment Method start */}

<View style={styles.row} break>
          <View style={styles.headingColumn}>
            <Text style={{fontSize:22,fontWeight:'bold',color:'#2F2E2E',textAlign:'center'}}>PAYMENT METHOD</Text>
          </View>
        
      </View>
      <View style={styles.row}>
          <View style={styles.column2}>
        
          <View style={{flexDirection:'row',margin:10,paddingBottom:10,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed'}}>
            <View style={{flex:7,flexDirection:'column'}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Favouring Name</Text>
            </View>
            <View style={{flex:5,flexDirection:'row-reverse'}}>
            
            <Text style={{fontSize:10,color:'#2F2E2E'}}>INBUILT CONCEPTS</Text>
            </View>
          </View>
          <View style={{flexDirection:'row',margin:10,paddingBottom:10,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed'}}>
            <View style={{flex:7,flexDirection:'column'}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Bank Name</Text>
            </View>
            <View style={{flex:5,flexDirection:'row-reverse'}}>
      
            <Text style={{fontSize:10,color:'#2F2E2E'}}>The Federal Bank Limited</Text>
            </View>
          </View>
          <View style={{flexDirection:'row',margin:10,paddingBottom:10,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed'}}>
            <View style={{flex:7,flexDirection:'column'}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Cash Credit Account No.</Text>
            </View>
            <View style={{flex:5,flexDirection:'row-reverse'}}>
          
            <Text style={{fontSize:10,color:'#2F2E2E'}}>14715500000507</Text>
            </View>
          </View>
           <View style={{flexDirection:'row',margin:10,paddingBottom:10,borderBottomColor:'#bdbdbd',borderBottomWidth:1,borderBottomStyle:'dashed'}}>
            <View style={{flex:7,flexDirection:'column'}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>Branch</Text>
            </View>
            <View style={{flex:5,flexDirection:'row-reverse'}}>
          
            <Text style={{fontSize:10,color:'#2F2E2E'}}>Banaswadi</Text>
            </View>
          </View>
         
          <View style={{flexDirection:'row',padding:10}}>
            <View style={{flex:7,flexDirection:'column'}}>
            <Text style={{fontSize:10,color:'#2F2E2E',fontWeight:'bold'}}>IFSC Code</Text>
            </View>
            <View style={{flex:5,flexDirection:'row-reverse'}}>
           
            <Text style={{fontSize:10,color:'#2F2E2E'}}>FDRL0001471</Text>
            </View>
          </View>

          </View>

          <View style={styles.column3}>
          <Image style={styles.scan} src={scan}/>
          </View>
      </View>

      {/* payment Method End*/}


     <View style={[styles.pageNumber, styles.separator]} fixed>
        <View style={{flexDirection:'column',flex:3,paddingRight:5}}>
        <Image style={styles.logo12} src={logo} />
      <Text  style={{fontSize:7,color:'#2F2E2E',fontWeight:'normal',textAlign:'left'}}>218, 9th Main Rd, HRBR Layout 1st Block, HRBR Layout, Kalyan Nagar, Bengaluru, Karnataka 560043</Text>
        </View>
        <View style={{flexDirection:'column',flex:4,borderLeftWidth:1,borderLeftColor:'#2F2E2E',paddingLeft:5}}>
        <Image style={styles.logo22} src={logo2} />
      <Text  style={{fontSize:7,color:'#2F2E2E',fontWeight:'normal',textAlign:'left'}}>1st Floor, Featherlite Vantage, 4031, 100 Feet Rd, opposite Barbecue Nation, HAL 2nd Stage, Hoysala Nagar, Indiranagar, Bengaluru, Karnataka 560038</Text>
        </View>
        <View style={{flexDirection:'column',flex:1}}>
        <Text  style={{fontSize:8,color:'#111111',fontWeight:600,textAlign:'right'}} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )}  />
        </View>
      </View>
    </Page>
</Document>
   

  )
}
