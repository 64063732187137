import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { firestore as db } from "../config/Config";

const  initialState={
  rnnames:[],
  rnmodal:false,
  rnerrore:{}
}

export const renameRoomName = createAsyncThunk('reNameRoom',
async (newRoomName) => {
    // await db.collection("client").doc(newRoomName.cid).collection("Rooms").doc('58zWDrgLW3nEdqrDAbSY').update({
    //     roomTypeName: newRoomName.roomTypeNewName,
    //   });
      // .where('roomTypeName', '==', newRoomName.preRoomTypeName);
      db.collection("client").doc(newRoomName.cid).collection("Rooms").where('roomTypeName', '==', newRoomName.preRoomTypeName).get().then(response =>{
        response.docs.forEach((doc) =>{
          db.collection("client").doc(newRoomName.cid).collection("Rooms").doc(doc.id).update({
            roomTypeName: newRoomName.roomTypeNewName,
          });
        })
      })
  });
// update and where query for firebase?
const renameRoomReducer = createSlice({
    name:'reNameRoomWithId',
    initialState,
    reducers:{},
    extraReducers:{
      [renameRoomName.fulfilled]:(state,action)=>{
        state.rnmodal = false;
      },
      [renameRoomName.pending]:(state,action)=>{
        state.rnmodal = false;
      },
      [renameRoomName.rejected]:(state,action)=>{
        state.rnerrore = action.payload;
        console.log("error", action.payload);
      },
    }
});

export default renameRoomReducer.reducer