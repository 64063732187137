import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { auth, firestore as db } from '../config/Config';

const initialState = {
  userData: [],
  userLoader: true,
  userError: '',
};

export const fetchUserList = createAsyncThunk('user/fetchUserList', async () => {
  try {
    const projectList = await db.collection('users').doc(auth.currentUser.uid).get();
    
    if (projectList.exists) {
      // Access the data directly from the DocumentSnapshot
      const userData = {
        ...projectList.data(),
        ID: projectList.id,
      };

      console.log('userData', userData);
      return [userData]; // Return an array since your initial state expects an array
    } else {
      console.log('User document not found');
      return []; // Return an empty array or handle this case according to your needs
    }
  } catch (error) {
    console.error('Error fetching user list:', error);
    throw error; // Let the rejection be handled by the extraReducers
  }
});

const userReducer = createSlice({
  name: 'viewusers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserList.fulfilled, (state, action) => {
        state.userData = action.payload;
        state.userLoader = false;
      })
      .addCase(fetchUserList.pending, (state) => {
        state.userLoader = true;
      })
      .addCase(fetchUserList.rejected, (state, action) => {
        state.userLoader = false;
        state.userError = 'Failed to fetch users. Please refresh the page.';
        console.error('fetchUserList rejected:', action.error);
      });
  },
});

export default userReducer.reducer;
