import React from "react";
import './Excel.css';
import {Table} from 'react-bootstrap';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
const ExcelJS = require('exceljs');




export default function ViewProfile  ({e})  {


const logoStyle = {
  backgroundImage: 'url("../assests/sitelogo.png")',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '50px', // Adjust the width as per your requirement
  height: '50px', // Adjust the height as per your requirement
};

        return (
          <div>
          <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table={e.ID}
              filename="tablexls"
              sheet="tablexls"
              buttonText="D/L Xlsx"
              className="btn btn-primary"
              cellStyles={[
          '.your-class {background-color: #ffffff;}',
          // '.logo-cell {background-image: url("../assests/sitelogo.png"); background-size: contain; background-repeat: no-repeat; background-position: center; width: 50px; height: 50px;}',
        ]}
              />

          <Table id={e.ID}  striped bordered hover className="border d-none">
          <tr>
          <td colSpan={2} className="d-flex align-items-center">
    {/* <img src="https://1000logos.net/wp-content/uploads/2016/10/Apple-Logo.png" style={{ width: '50px', height: 'auto', marginRight: '10px' }} /> */}
   </td>
          <td  colSpan={7}></td></tr>
          <tr><td colSpan={9}>You are about to experience creativity and commitment at its best.</td></tr>
          <tr><td colSpan={9}></td></tr>
          <tr>
            <td className="border"  colSpan={4}>
            <p style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Hi <b>{e.clientDetails.cname}</b>, welcome to the Fabdiz family!
              Thank you for taking the time and having a discussion with me. We have taken into account all
              your requirements and created a quote for you.
              We believe it will perfectly suit your style and
              budget.</p>
            </td>
            <td colSpan={1}></td>
            <td  className="border"  colSpan={4}>
              <h5>{e.userDetials[0].designation}</h5>
              <p>{e.userDetials[0].name}<br/>
              +91 {e.userDetials[0].mobile}<br/>
              {e.userDetials[0].email}</p>
            </td>
          </tr>
          <tr style={{backgroundColor:'#d0e0e3'}}>
                  <th  colSpan={9}  style={{textAlign:'center',fontSize:'20px'}}>Rooms</th>
              </tr>
              {e.quotationData.map((item, index)=>(
                <>
                <tr style={{backgroundColor:'#91c2de'}}>
                  
                  <th  colSpan={9}>{item.roomTypeName}</th>
                  
              </tr>
              <tr style={{backgroundColor:'#b5d6a7'}}>
                  
                  <th>Item/Description</th>
                  <th>Finish</th>
                  <th>width(FT)</th>
                  <th>Height(FT)</th>
                  <th>Area(Sqft/RFT)</th>
                  <th>Qty</th>
                  <th>Price</th>
                  <th>Amount</th>
                  <th>Remark</th>
              </tr>
              

             
               {item.title.map((item2, index2) =>( 
                item2.value2 > 0 || item2.name == "Wardrobe - Slider" ? 
                <tr>
                  <td style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>{item2.name} {item2.label2 ? `  (${item2.label2})` : null}</td>
                  <td style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>{item2.label}</td>
                  <td>{item2.width > 0 ? item2.width : null}</td>
                  <td>{item2.height > 0 ? item2.height : null}</td>
                  <td>{item2.area > 0 ? item2.area : null}</td>
                  <td>{item2.qnty > 0 ? item2.qnty : null}</td>
                  <td>{item2.valueIncresed}  {item2.valueIncresed2 ?  `+ ${item2.valueIncresed2}` : null}</td>
                  <td>{item2.price > 0 ? item2.price : null}</td>
                  <td style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>{item2.remark}</td>
              </tr> :  item2.price > 0 || item2.name == "Shape Of Kitchen" ? 
              <tr>
                  <td style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>{item2.name} {item2.label2 ? `  (${item2.label2})` : null}</td>
                  <td style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>{item2.label}</td>
                  <td>{item2.width > 0 ? item2.width : null}</td>
                  <td>{item2.height > 0 ? item2.height : null}</td>
                  <td>{item2.area > 0 ? item2.area : null}</td>
                  <td>{item2.qnty > 0 ? item2.qnty : null}</td>
                  <td>{item2.valueIncresed}</td>
                  <td>{item2.price > 0 ? item2.price : null}</td>
                  <td style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>{item2.remark}</td>
              </tr> :  item2.price > 0 ?
              <tr>
                  <td style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>{item2.name} {item2.label2 ? `  (${item2.label2})` : null}</td>
                  <td style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>{item2.label}</td>
                  <td>{item2.width > 0 ? item2.width : null}</td>
                  <td>{item2.height > 0 ? item2.height : null}</td>
                  <td>{item2.area > 0 ? item2.area : null}</td>
                  <td>{item2.qnty > 0 ? item2.qnty : null}</td>
                  <td>{item2.valueIncresed}</td>
                  <td>{item2.price > 0 ? item2.price : null}</td>
                  <td style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>{item2.remark}</td>
              </tr> :  item2.value > 0 ? 
              <tr>
                  <td style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>{item2.name} {item2.label2 ? `  (${item2.label2})` : null}</td>
                  <td style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>{item2.label}</td>
                  <td>{item2.width > 0 ? item2.width : null}</td>
                  <td></td>
                  <td></td>
                  <td>{item2.qnty > 0 ? item2.qnty : null}</td>
                  <td></td>
                  <td>{item2.value}</td>
                  <td style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>{item2.remark}</td>
              </tr> : null


               ))}
               </>
               ))}
            
               <tr style={{backgroundColor:'#d0e0e3'}}>
                  <th  colSpan={9}  style={{textAlign:'center',fontSize:'20px'}}>GROSS TOTAL</th>
              </tr>
              <tr>
                <td colSpan={4} style={{paddingLeft:'5px'}}>Sub Total</td>
                <td colSpan={5} style={{textAlign:'right',paddingRight:'5px'}}>INR {e.quotationPrice}</td>
              </tr>
              <tr>
                <td colSpan={4} style={{paddingLeft:'5px'}}>Discount ({e.quotationDiscount}) <br/> discount provided on all woodworks</td>
                <td colSpan={5} style={{textAlign:'right',paddingRight:'5px'}}>INR {Math.round(e.quotationWood * (e.quotationDiscount/100))}</td>
              </tr>
              <tr>
                <td colSpan={4} style={{paddingLeft:'5px'}}>GST (18%)</td>
                <td colSpan={5} style={{textAlign:'right',paddingRight:'5px'}}>INR {Math.round((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100)}</td>
              </tr>
              <tr>
                <td colSpan={4} style={{paddingLeft:'5px'}}>Grand Total (GST)</td>
                <td colSpan={5} style={{textAlign:'right',paddingRight:'5px'}}>INR {Math.round((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)) + ((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100))}</td>
              </tr>


              <tr style={{backgroundColor:'#d0e0e3'}}>
                  <th  colSpan={9}  style={{textAlign:'center',fontSize:'20px'}}>PAYMENT BREAKUP</th>
              </tr>
              <tr>
                <td colSpan={4} style={{paddingLeft:'5px'}}>Commitment Advance (10%)</td>
                <td colSpan={5} style={{textAlign:'right',paddingRight:'5px'}}>INR {Math.round(((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)) + ((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100)) * 10/100)}</td>
              </tr>
              <tr>
                <td colSpan={4} style={{paddingLeft:'5px'}}>Production Advance (40%)</td>
                <td colSpan={5} style={{textAlign:'right',paddingRight:'5px'}}>INR {Math.round(((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)) + ((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100)) * 40/100)}</td>
              </tr>
              <tr>
                <td colSpan={4} style={{paddingLeft:'5px'}}>Material Delivery (40%)</td>
                <td colSpan={5} style={{textAlign:'right',paddingRight:'5px'}}>INR {Math.round(((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)) + ((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100)) * 40/100)}</td>
              </tr>
              <tr>
                <td colSpan={4} style={{paddingLeft:'5px'}}>On Handover (10%)</td>
                <td colSpan={5} style={{textAlign:'right',paddingRight:'5px'}}>INR {Math.round(((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)) + ((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100)) * 10/100)}</td>
              </tr>
            



              <tr style={{backgroundColor:'#d0e0e3'}}>
                  <th  colSpan={9}  style={{textAlign:'center',fontSize:'20px'}}>Material Specifications</th>
              </tr>
              
              <tr>
                <td style={{textAlign:'left',fontWeight:'bold'}}>Laminate:</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>(Merino, Greenlam, Century, Realtouch, Heritage, Royal Touch). External Matt Laminated Finish 1.0 mm (Any Brand - SF, upto Rs.
1200 per sheet), unless otherwise specified. Internal Matt finish laminate of 0.79-0.8 mm, Hazel Cambric/off white color, unless and
otherwise specified.
</td>
              </tr>
              <tr>
                <td style={{textAlign:'left',fontWeight:'bold'}}>PLY:</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Choose between Century Sainik & Prelaminated board FABDIZ make from Indiana. Kitchen, Utility and Washroom - Below Boxes in BWP (Water Proof) Ply - 16 mm (Calibrated). Kitchen above boxes, wardrobe, other units, such as crockery/display/loft unit etc. in MR (Moisture Resistant) Ply - 16 mm (Calibrated).</td>
              </tr>
              <tr>
                <td style={{textAlign:'left',fontWeight:'bold'}}>Hardware:</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Hafele/Hettich/EBCO Hinges and other hardware (Tandem Box, Channels, Sliders etc) shall be of regular operations (Soft Close on
Demand). Handles, Knobs, shall be of local supply (with basic rate of Rs. 100/pc 8 inch and Rs, 50/Pc 4 inch) or as per selection .
Wire-Baskets (Plate, thanli, cutlery or bottle pullout) if any, shall be stainless steel (Local Supply) grade-304. Wicker Basket if any, will
be local supply as per IS standards and size customizable. Kitchen Skirting shall be a PVC make of approved color and finish with
PVC adjustable legs. 5mm plain glass for glass shutters, unless otherwise specified with wooden frame. (Aluminium shutters can be
on demand). 6mm plain glass for Shelves ad per drawings or unless otherwise specified.</td>
              </tr>
              <tr>
                <td style={{textAlign:'left',fontWeight:'bold'}}>Others:</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>SS Shelf pins, Clamps, Screw and other fixtures - local supply. Dowels & Mini-fix - Hettich. Glue - Pedilite (Fevicol), Araldite, Jotun,
Rehau, woodmaster or equivelent based on application. Edge Banding - Rehau or Equivalent (1mm or 2mm as per application).
</td>
              </tr>
              <tr>
                <td style={{textAlign:'left',fontWeight:'bold'}}>Paint, Polish & Powdercoat:</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>As per selection (Asian, Dulux, Berger, RAL), only if mentioned in quote.</td>
              </tr>
              





              <tr style={{backgroundColor:'#d0e0e3'}}>
                  <th  colSpan={9} style={{textAlign:'center',fontSize:'20px'}}>TERMS AND CONDITIONS</th>
              </tr>
              <tr style={{backgroundColor:'#92c3df'}}>
                  <th  colSpan={9} style={{textAlign:'left'}}>Warranty</th>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>1</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>We provide 10 years warranty for plywood, This safeguards you against any defect in manufacturing or in installation workmanship
under normal usage. Hardware warranty will be apllicable based on the brands chosen For custom work, there is no warranty applicable.
For more information about warranty policy we suggest you to ask FABDIZ warranty policy to our Design consultant.
</td>
              </tr>
              <tr>
                <td></td>
                <td colSpan={8}><b>Note :</b> 1 year of free servicing for our product</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>2</td>
                <td colSpan={8}> All accessories, hardware and appliances are covered as per the respective Manufacturers.</td>
              </tr>



              <tr style={{backgroundColor:'#92c3df'}}>
                  <th  colSpan={9} style={{textAlign:'left'}}>General terms</th>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>1</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Chimney, Sink, Hob, Gas Piping, Granite along with the labour cost associated are not included in this quotation unless mentioned
otherwise.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>2</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Light Fittings, Geyser, Bathroom Fittings, Fans and any other electrical fittings are not included in this quotation unless mentioned
otherwise.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>3</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Any product that is not added in this Quotation has to be assumed as Non Tendered Product, a revised quote will be provided for the
same. FABDIZ will only be responsible for work mentioned in the quotation.
</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>4</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Alteration of the existing electrical wiring/points and plumbing wiring/points will be charged extra and for any civil changes like plumbing
and wall chipping we need civil engineers for instructions.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>5</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>FABDIZ execution team takes the responsibility of moving debris to Garbage Room of the apartment premises.
</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>6</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>FABDIZ execution team takes the responsibility of cleaning floor & interiors before moving out of the site (Deep cleaning not included).</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>7</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Sliding Door Track fitting will bring 15-25mm gap between the Sliding Door and loft. As we need that gap during the maintenance. Sliding
Door Track fitting will bring 10mm gap between the door overlapping.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>8</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Product with glossy finish may have very less unevenness look and feel due to lighting effect.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>9</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>This quotation will be valid for 1 month.</td>
              </tr>
             
              <tr>
                <td style={{textAlign:'center'}}>10</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Final coat of paint should be done after completion of interior work from client side We are unable to avoid handmarks, scratches during
installation.
</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>11</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>There will be difference between in handmade furniture and modular furniture. As handmade furniture by carpenters on site with hand
tools so as compared to modular work handmade furniture finishing will differ.
</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>12</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Images shown in the 3d are for reference, please refer actual samples for colour selection and texture / wallpapers or any decor items
which might be shown only for reference</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>13</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Design alterations will not be considered once the design document is signed off or once FADBIZ get confirmation for design document
through mail/electronic media</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>14</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Production date will start as per the schedule that will be shared.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>15</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>Any modifications at site should be informed to the designer and project manager prior to production.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>16</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>FABDIZ will not be responsible for products purchased from elsewhere</td>
              </tr>
             
              <tr>
                <td style={{textAlign:'center'}}>17</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>The Entertainment Cabinet and other modular cabinets more than 2400MM will be having two cabinets in that case the joint at the top
of the cabinet will be visible.</td>
              </tr>
             
              <tr style={{backgroundColor:'#92c3df'}}>
                  <th  colSpan={9} style={{textAlign:'left'}}>Design Process</th>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>1</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}><b>Site Visit :</b> Once booking advance paid Interior Designer will visit the client site with measurement team.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>2</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}><b>Discussion Meeting:</b> Designer will show the modular library, completed project photographs which will match to client requirement</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>3</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}><b>Modeling Presentation:</b> As per client requirement and placement of furniture designer will present modeling. (upto 2 iteration can be
done in the 3d model stage post that timelines may change as per additional design changes.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>4</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}><b>3D Design Presentation:</b> will present 3D design which will have the texture selected by client.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>5</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}><b>Site Validation:</b> Validation will be done at site where FABDIZ will execute project. Designer will visit the site with Project Manager for
Masking Measurement. Masking measurement will be possible only on cleaned floor.
</td>
              </tr>
             


              <tr style={{backgroundColor:'#92c3df'}}>
                  <th  colSpan={9} style={{textAlign:'left'}}>Payment Process ( Final design quote to be considered )</th>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>1</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>10% appointment investment as a designer (Part of final quote).</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>2</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>50% of final design quote value, upon design sign off to start production.</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>3</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}> 30% before the dispach of the material is done at site</td>
              </tr>
              <tr>
                <td style={{textAlign:'center'}}>4</td>
                <td colSpan={8} style={{whiteSpace:'pre-wrap',wordWrap:'break-word'}}>10% - PDC to be given after 90% of work done at site</td>
              </tr>
              
          </Table>

      </div>
        );
    
}