import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";

const  initialState={
    data:[],
    loader:true,
    error:"",
}

export const fetchProjectList = createAsyncThunk('fetchprojects',
async()=>{
    const projectList = await db
    .collection("client")
    .where("status", "==", "Ongoing")
    // .where("ID", "==", "Xxlak0QSXCMYCBWHqCD4BrOanXd2")
    .where("ID", "==", auth.currentUser.uid)
    .get();
  const projectListArray = [];
  for (var snap of projectList.docs) {
    var data = snap.data();
    data.ID = snap.id;
    projectListArray.push({
      ...data,
    });
    if (projectListArray.length === projectList.docs.length) {
      return projectListArray
    }
  }
});

const projectReducer = createSlice({
    name:'projects',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchProjectList.fulfilled]:(state,action)=>{
            state.data = action.payload
            state.loader = false
        },
        [fetchProjectList.pending]:(state,action)=>{
            state.loader = true
        },
        [fetchProjectList.rejected]:(state,action)=>{
            state.error = "refresh the page"
            state.loader = false
        },
    }
});

export default projectReducer.reducer