import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";


const  initialState={
    quotationNames:[],
  quotationModal:false,
  quotationError:{}
}

export const createQuotations = createAsyncThunk('createQuotationList',
async (quoteData) => {
    await db.collection("client").doc(quoteData.id).collection("Quotations").doc().set(quoteData);
  });

const addQuotationReducer = createSlice({
    name:'createQuotation',
    initialState,
    reducers:{},
    extraReducers:{
      [createQuotations.fulfilled]:(state,action)=>{
        state.quotationModal = true;
      },
      [createQuotations.pending]:(state,action)=>{
        state.quotationModal = false;
      },
      [createQuotations.rejected]:(state,action)=>{
        state.quotationModal = false;
      },
    }
});

export default addQuotationReducer.reducer