import React,{ useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Nav, Container, Tab} from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { auth } from "../config/Config";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

export default function Inspiration() {
  const navigation = useNavigate();
  
  const handleLogOut =()=>{
    auth.signOut();
    // navigation('/login');
  }
// useEffect(()=>console.log('ffff', data), []);
  useEffect(()=>{
  auth.onAuthStateChanged(user => {
    const routeName = user !== null ? '/inspiration' : '/login';
    navigation(routeName);
  });
}, []);

const { userData, userLoader, userError} = useSelector((state)=>state.viewusers);

const { kitchenThingsData } = useSelector(
  (state) => state.kitchenThings
);

const { bedroomThingsData } = useSelector(
  (state) => state.bedroomThings
);

const { foyerThingsData } = useSelector(
  (state) => state.foyerThings
);

const { livingThingsData } = useSelector(
  (state) => state.livingThings
);

const { otherWorkThingsData } = useSelector(
  (state) => state.OtherWorkThings
);

const { looseThingsData } = useSelector(
  (state) => state.looseThings
);
const { accessoriesThingsData } = useSelector(
  (state) => state.accessories
);
  return (
    <Container fluid>
    <Row>
      <Col sm={1} style={{ backgroundColor: "#fdc771" }}>
        <Tab.Container id="left-tabs-example" defaultActiveKey="Inspiration">
          <Row>
            <Col sm={1} style={{ backgroundColor: "#fdc771" }}>
              <div
                style={{
                  justifyContent: "center",
                  justifyItems: "center",
                  justifySelf: "center",
                }}
              >
                <img
                  src={require("../assests/logo-black.png")}
                  alt="Fabdiz"
                  style={{
                    width: 30,
                    height: 30,
                    alignContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                />
                <p style={{ fontSize: 12 }}>Fabdiz</p>
              </div>
              <Nav variant="tabs" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="Dashboard" href="/dashboard">
                    <img
                      src={require("../assests/dashboard.png")}
                      alt="Dashboard"
                      style={{
                        width: 25,
                        height: 25,
                        alignContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                    />

                    <p style={{ color: "#000000", fontSize: 12 }}>
                      Dashboard
                    </p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Projects" href="/projects">
                    <img
                      src={require("../assests/projects.png")}
                      alt="Projects"
                      style={{ width: 25, height: 25 }}
                    />

                    <p style={{ color: "#000000", fontSize: 12 }}>Projects</p>
                  </Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                  <Nav.Link eventKey="Settings" href="/settings">
                    <img
                      src={require("../assests/settings.png")}
                      alt="Settings"
                      style={{ width: 25, height: 25 }}
                    />

                    <p style={{ color: "#000000", fontSize: 12 }}>Settings</p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Services" href="/services">
                    <img
                      src={require("../assests/services.png")}
                      alt="Services"
                      style={{ width: 25, height: 25 }}
                    />

                    <p style={{ color: "#000000", fontSize: 12 }}>Services</p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Team" href="/team">
                    <img
                      src={require("../assests/team.png")}
                      alt="Team"
                      style={{ width: 25, height: 25 }}
                    />

                    <p style={{ color: "#000000", fontSize: 12 }}>Team</p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Factory" href="/factory">
                    <img
                      src={require("../assests/Factory.png")}
                      alt="Factory"
                      style={{ width: 25, height: 25, alignSelf: "center" }}
                    />

                    <p style={{ color: "#000000", fontSize: 12 }}>Factory</p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Inspiration" href="/inspiration">
                    <img
                      src={require("../assests/inspiration.png")}
                      alt="Pricing"
                      style={{ width: 25, height: 25 }}
                    />

                    <p style={{ color: "#000000", fontSize: 12 }}>
                    Pricing
                    </p>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Render" href="/render">
                    <img
                      src={require("../assests/render.png")}
                      alt="Render"
                      style={{ width: 25, height: 25 }}
                    />

                    <p style={{ color: "#000000", fontSize: 12 }}>Render</p>
                  </Nav.Link>
                </Nav.Item> */}
              </Nav>
            </Col>
          </Row>
        </Tab.Container>
      </Col>
      <Col sm={11} className="m-0">
        <Row>
          <Col style={{
                backgroundColor: "#f5f8f8",
                display: "flex",
                flexDirection: "row",
                padding: "1%",
                justifyContent: "space-between",
              }}>
              <Col sm={10}>
              <p style={{ fontSize: 20, fontWeight: "bold" }}>Pricing</p>
              </Col>
            
              <Col sm={2}>
                <img
                  src={require("../assests/notification.png")}
                  alt="Notification"
                  style={{ width: 26, height: 26 }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Dropdown className="d-inline mx-2">
                  <Dropdown.Toggle id="dropdown-autoclose-true">
                    <img
                      src={require("../assests/user.png")}
                      alt="User"
                      style={{ width: 40, height: 40 }}
                    />{" "}
                    &nbsp;&nbsp; {userData ? userData[0].name : "UNKNOWN"} &nbsp;
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">
                      <Link to={"/viewprofile"}>View Profile</Link>
                    </Dropdown.Item>
                    <Dropdown.Item href="#">FAQ</Dropdown.Item>
                    <Dropdown.Item  onClick={()=>handleLogOut()}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
          </Col>
        </Row>
        <Row className="p-5" style={{minHeight: 380, maxHeight: 580 , overflowY: 'auto'}}>
        <Row>
           <Col>
            <Row><h2>Kitchen</h2></Row>
            {kitchenThingsData.map((item, index) =>(
              <Row style={{display:'flex',flexDirection:'row',marginBottom:'2%',borderBottomWidth:2,borderBottomColor:'whitesmoke',borderBottomStyle:'solid'}}>
              <Col sm={2}><p style={{fontWeight:'bold'}}>{item.name}</p></Col>
              
              {item.material.map((item2, index2) => (
                <Col sm={2}>
                <p>{item2.label}</p>
                <input type='number'  value={item2.value} placeholder='price' className='w-75' disabled/>
              </Col>
              ))}
             
             
             
            </Row>
            ))}
            
           </Col> 
                  </Row>
                  <Row>
           <Col>
            <Row><h2>Bedroom</h2></Row>
            {bedroomThingsData.map((item, index) =>(
              <Row style={{display:'flex',flexDirection:'row',marginBottom:'2%',borderBottomWidth:2,borderBottomColor:'whitesmoke',borderBottomStyle:'solid'}}>
              <Col sm={2}><p style={{fontWeight:'bold'}}>{item.name}</p></Col>
              
              {item.material.map((item2, index2) => (
                <Col sm={2}>
                <p>{item2.label}</p>
                <input type='number'  value={item2.value} placeholder='price' className='w-75' disabled/>
              </Col>
              ))}
              <br/>
              {item.door ? item.door.map((item3, index2) => (
                <Col sm={2}>
                <p>{item3.label}</p>
                <input type='number'  value={item3.value} placeholder='price' className='w-75' disabled/>
              </Col>
              )) : null}
             
             
             
            </Row>
            ))}
            
           </Col>  
            </Row>
            <Row>
                      <Col>
                        <Row><h2>Foyer</h2></Row>
                        {foyerThingsData.map((item, index) =>(
                          <Row style={{display:'flex',flexDirection:'row',marginBottom:'2%',borderBottomWidth:2,borderBottomColor:'whitesmoke',borderBottomStyle:'solid'}}>
                          <Col sm={2}><p style={{fontWeight:'bold'}}>{item.name}</p></Col>
                          
                          {item.material.map((item2, index2) => (
                            <Col sm={2}>
                            <p>{item2.label}</p>
                            <input type='number'  value={item2.value} placeholder='price' className='w-75' disabled/>
                          </Col>
                          ))}
                        
                        
                        
                        </Row>
                        ))}
                        
                      </Col> 
            </Row>
            <Row>
                      <Col>
                        <Row><h2>Living Room</h2></Row>
                        {livingThingsData.map((item, index) =>(
                          <Row style={{display:'flex',flexDirection:'row',marginBottom:'2%',borderBottomWidth:2,borderBottomColor:'whitesmoke',borderBottomStyle:'solid'}}>
                          <Col sm={2}><p style={{fontWeight:'bold'}}>{item.name}</p></Col>
                          
                          {item.material.map((item2, index2) => (
                            <Col sm={2}>
                            <p>{item2.label}</p>
                            <input type='number'  value={item2.value} placeholder='price' className='w-75' disabled/>
                          </Col>
                          ))}
                        
                        
                        
                        </Row>
                        ))}
                        
                      </Col> 
            </Row>
            <Row>
                      <Col>
                        <Row><h2>Other Works</h2></Row>
                        {otherWorkThingsData.map((item, index) =>(
                          <Row style={{display:'flex',flexDirection:'row',marginBottom:'2%',borderBottomWidth:2,borderBottomColor:'whitesmoke',borderBottomStyle:'solid'}}>
                          <Col sm={2}><p style={{fontWeight:'bold'}}>{item.name}</p></Col>
                          
                          {item.material.map((item2, index2) => (
                            <Col sm={2}>
                            <p>{item2.label}</p>
                            <input type='number'  value={item2.value} placeholder='price' className='w-75' disabled/>
                          </Col>
                          ))}
                        
                        
                        
                        </Row>
                        ))}
                        
                      </Col> 
            </Row>
            <Row>
                      <Col>
                        <Row><h2>Loose Furniture</h2></Row>
                        {looseThingsData.map((item, index) =>(
                          <Row style={{display:'flex',flexDirection:'row',marginBottom:'2%',borderBottomWidth:2,borderBottomColor:'whitesmoke',borderBottomStyle:'solid'}}>
                          <Col sm={2}><p style={{fontWeight:'bold'}}>{item.name}</p></Col>
                          
                          {item.material.map((item2, index2) => (
                            <Col sm={2}>
                            <p>{item2.label}</p>
                            <input type='number'  value={item2.value} placeholder='price' className='w-75' disabled/>
                          </Col>
                          ))}
                        
                        
                        
                        </Row>
                        ))}
                        
                      </Col> 
                      </Row>
                      <Row>
                      <Col>
                        <Row><h2>Accessories</h2></Row>
                        {accessoriesThingsData.map((item, index) =>(
                          <Row style={{display:'flex',flexDirection:'row',marginBottom:'2%',borderBottomWidth:2,borderBottomColor:'whitesmoke',borderBottomStyle:'solid'}}>
                          <Col sm={2}><p style={{fontWeight:'bold'}}>{item.name}</p></Col>
                          
                          {item.material.map((item2, index2) => (
                            <Col sm={2}>
                            <p>{item2.label}</p>
                            <input type='number'  value={item2.value} placeholder='price' className='w-75' disabled/>
                          </Col>
                          ))}
                        
                        
                        
                        </Row>
                        ))}
                        
                      </Col> 
                      </Row>
     
         
        </Row>
      </Col>
    </Row>
  </Container>
  );
}


