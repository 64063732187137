import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";


const  initialState={
    materialData:[],
    materialModal:false,
    materialError:{}
}

export const createMaterials = createAsyncThunk('createMaterialList',
async (allData) => {
    await db.collection(allData.table).doc().set(allData);
  });

const addMaterialReducer = createSlice({
    name:'createMaterial',
    initialState,
    reducers:{},
    extraReducers:{
      [createMaterials.fulfilled]:(state,action)=>{
        state.materialModal = true;
      },
      [createMaterials.pending]:(state,action)=>{
        state.materialModal = false;
      },
      [createMaterials.rejected]:(state,action)=>{
        state.materialError = "Something went wrong";
      },
    }
});

export default addMaterialReducer.reducer