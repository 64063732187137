import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Nav, Container, Tab, Button, Accordion, Modal, Form } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { auth } from "../config/Config";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

export default function Services() {
  const navigation = useNavigate();

  const handleLogOut = () => {
    auth.signOut();
    // navigation('/login');
  }
  // useEffect(()=>console.log('ffff', data), []);
  useEffect(() => {
    auth.onAuthStateChanged(user => {
      const routeName = user !== null ? '/services' : '/login';
      navigation(routeName);
    });
  }, []);

  const { userData, userLoader, userError} = useSelector((state)=>state.viewusers);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return (
    <Container fluid>
      <Row>
        <Col sm={1} style={{ backgroundColor: "#fdc771" }}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="Services">
            <Row>
              <Col sm={1} style={{ backgroundColor: "#fdc771" }}>
                <div
                  style={{
                    justifyContent: "center",
                    justifyItems: "center",
                    justifySelf: "center",
                  }}
                >
                  <img
                    src={require("../assests/logo-black.png")}
                    alt="Fabdiz"
                    style={{
                      width: 30,
                      height: 30,
                      alignContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                  />
                  <p style={{ fontSize: 12 }}>Fabdiz</p>
                </div>
                <Nav variant="tabs" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="Dashboard" href="/dashboard">
                      <img
                        src={require("../assests/dashboard.png")}
                        alt="Dashboard"
                        style={{
                          width: 25,
                          height: 25,
                          alignContent: "center",
                          alignItems: "center",
                          alignSelf: "center",
                        }}
                      />

                      <p style={{ color: "#000000", fontSize: 12 }}>
                        Dashboard
                      </p>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Projects" href="/projects">
                      <img
                        src={require("../assests/projects.png")}
                        alt="Projects"
                        style={{ width: 25, height: 25 }}
                      />

                      <p style={{ color: "#000000", fontSize: 12 }}>Projects</p>
                    </Nav.Link>
                  </Nav.Item>
                  {/* <Nav.Item>
                    <Nav.Link eventKey="Settings" href="/settings">
                      <img
                        src={require("../assests/settings.png")}
                        alt="Settings"
                        style={{ width: 25, height: 25 }}
                      />

                      <p style={{ color: "#000000", fontSize: 12 }}>Settings</p>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Services" href="/services">
                      <img
                        src={require("../assests/services.png")}
                        alt="Services"
                        style={{ width: 25, height: 25 }}
                      />

                      <p style={{ color: "#000000", fontSize: 12 }}>Services</p>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Team" href="/team">
                      <img
                        src={require("../assests/team.png")}
                        alt="Team"
                        style={{ width: 25, height: 25 }}
                      />

                      <p style={{ color: "#000000", fontSize: 12 }}>Team</p>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Factory" href="/factory">
                      <img
                        src={require("../assests/Factory.png")}
                        alt="Factory"
                        style={{ width: 25, height: 25, alignSelf: "center" }}
                      />

                      <p style={{ color: "#000000", fontSize: 12 }}>Factory</p>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Inspiration" href="/inspiration">
                      <img
                        src={require("../assests/inspiration.png")}
                        alt="Pricing"
                        style={{ width: 25, height: 25 }}
                      />

                      <p style={{ color: "#000000", fontSize: 12 }}>
                        Pricing
                      </p>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Render" href="/render">
                      <img
                        src={require("../assests/render.png")}
                        alt="Render"
                        style={{ width: 25, height: 25 }}
                      />

                      <p style={{ color: "#000000", fontSize: 12 }}>Render</p>
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
        <Col sm={11} className="m-0">
          <Row>
            <Col style={{
              backgroundColor: "#f5f8f8",
              display: "flex",
              flexDirection: "row",
              padding: "1%",
              justifyContent: "space-between",
            }}>
              <Col sm={10}>
                <p style={{ fontSize: 20, fontWeight: "bold" }}>Services</p>
              </Col>

              <Col sm={2}>
                <img
                  src={require("../assests/notification.png")}
                  alt="Notification"
                  style={{ width: 26, height: 26 }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Dropdown className="d-inline mx-2">
                  <Dropdown.Toggle id="dropdown-autoclose-true">
                    <img
                      src={require("../assests/user.png")}
                      alt="User"
                      style={{ width: 40, height: 40 }}
                    />{" "}
                    &nbsp;&nbsp; {userData ? userData[0].name : "UNKNOWN"} &nbsp;
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#">
                      <Link to={"/viewprofile"}>View Profile</Link>
                    </Dropdown.Item>
                    <Dropdown.Item href="#">FAQ</Dropdown.Item>
                    <Dropdown.Item onClick={() => handleLogOut()}>Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Col>
          </Row>
          <Row className="p-5">
            <Row>
              <Col className='md-6'><h3>Item list</h3></Col>
              <Col className='md-6 d-flex flex-row-reverse'><Button className="w-50" onClick={handleShow}>Add Items</Button></Col>
            </Row>
            <Row className='mt-4'>
              <Col>
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Accordion Item #1</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                      minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                      aliquip ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Accordion Item #2</Accordion.Header>
                    <Accordion.Body>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                      eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                      minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                      aliquip ex ea commodo consequat. Duis aute irure dolor in
                      reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                      pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                      culpa qui officia deserunt mollit anim id est laborum.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

              </Col>
            </Row>
          </Row>
        </Col>
        <Modal size='xl' show={show} onHide={handleClose}>

          <Modal.Header closeButton>
            <Modal.Title
              style={{
                fontSize: 25,
                fontWeight: "bold",
              }}
            >
              Add Things
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: 33 }}>

            <Form>


              <Form.Group>
                <Row>
                  <Col
                    sm={3}
                    style={{
                      borderRightWidth: 1,
                      borderRightColor:
                        "#e9ecef",
                      borderRightStyle: "solid",
                    }}
                  >
                    <Form.Group>
                      <Form.Label
                        style={{
                          fontSize: 20,
                          fontWeight: "600",
                        }}
                      >
                        Room Types
                      </Form.Label>

                      <Form.Select
                        aria-label="Default select example"
                      >
                        <option value="0">
                          Select a Room
                        </option>
                        <option value="Bedroom">
                          Bedroom
                        </option>
                        <option value="Kitchen">
                          Kitchen
                        </option>
                        <option value="Living Room">
                          Living Room
                        </option>
                        <option value="Puja">
                          Puja
                        </option>

                      </Form.Select>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label
                        style={{
                          fontSize: 20,
                          fontWeight: "600",
                        }}
                      >
                        Material
                      </Form.Label>

                      <Form.Select

                        aria-label="Default select example"
                      >
                        <option value="0">
                          Select a Material
                        </option>

                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-3">
                      <Row>
                        <Col sm={4}>
                          <Form.Label
                            style={{
                              fontSize: 15,
                              fontWeight: "600",
                            }}
                          >
                            Width
                          </Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                            type="text"

                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Row>
                        <Col sm={4}>
                          <Form.Label
                            style={{
                              fontSize: 15,
                              fontWeight: "600",
                            }}
                          >
                            Height
                          </Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                            type="text"

                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group className="mb-3">
                      <Row>
                        <Col sm={4}>
                          <Form.Label
                            style={{
                              fontSize: 15,
                              fontWeight: "600",
                            }}
                          >
                            Quantity
                          </Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                            type="text"

                          />
                        </Col>
                      </Row>
                    </Form.Group>
                    <Form.Group className="mt-5">
                      <Row>
                        <Col>
                          <Form.Label
                            style={{
                              fontSize: 20,
                              fontWeight: "600",
                            }}
                          >
                            Price
                          </Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Control
                            type="text"
                          />
                        </Col>
                      </Row>
                    </Form.Group>
                  </Col>
                  <Col sm={9}>
                    <h4>Accessories</h4>

                    <Row className="mx-2 my-3" >
                      <Col sm={4}>
                        <Form.Group>
                          <Form.Label
                            style={{
                              fontSize: 16,
                              fontWeight: "500",
                            }}
                          >Name
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group>
                          <Form.Select

                            aria-label="Default select example"
                          >
                            <option value="0">
                              None
                            </option>

                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col sm={2}>
                        <Form.Group>
                          <Form.Control
                            type="number"
                            placeholder="Qty"
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={3}>
                        <Form.Group>
                          <Form.Control
                            type="number"
                            placeholder="Price"
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>


                    <Row className="mx-2 my-3">
                      <Col
                        sm={{
                          span: 4,
                          offset: 2,
                        }}
                      >
                        <Form.Group>
                          <Form.Label
                            style={{
                              fontSize: 20,
                            }}
                          >
                            Price
                          </Form.Label>
                        </Form.Group>
                      </Col>
                      <Col
                        sm={{
                          span: 3,
                          offset: 3,
                        }}
                      >
                        <Form.Group>
                          <Form.Control
                            type="number"

                            placeholder="price"
                            disabled
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className="col-sm-5"></Col>
                  <Col className="col-sm-3">
                    <h4>Total Price</h4>
                  </Col>
                  <Col className="col-sm-2"></Col>
                  <Col className="col-sm-2">
                    <h4>
                      Rs:-{" "}

                    </h4>
                  </Col>
                </Row>
              </Form.Group>


            </Form>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"

            >
              Close
            </Button>
            <Button
              variant="primary"

            >
              Add
            </Button>
          </Modal.Footer>

        </Modal>
      </Row>
    </Container>
  );
}


