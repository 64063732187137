import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";

const  initialState={
    kitchenDustbinBasicData:[],
    kitchenDustbinBasicLoader:true,
    kitchenDustbinBasicError:"",
}

export const fetchKitchenDustbinBasicList = createAsyncThunk('fetchkitchenDustbinBasic',
async()=>{
    const projectList = await db
    .collectionGroup("dustbin_basic")
    // .where("status", "==", "Ongoing")
    // .where("ID", "==", auth.currentUser.uid)
    .get();
  const projectListArray = [];
  for (var snap of projectList.docs) {
    var data = snap.data();
    data.ID = snap.id;
    projectListArray.push({
      ...data,
    });
    if (projectListArray.length === projectList.docs.length) {
      return projectListArray
    }
  }
});

const kitchenDustbinBasicReducer = createSlice({
    name:'kitchenDustbinBasic',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchKitchenDustbinBasicList.fulfilled]:(state,action)=>{
            state.kitchenDustbinBasicData = action.payload
            state.kitchenDustbinBasicLoader = false
        },
        [fetchKitchenDustbinBasicList.pending]:(state,action)=>{
            state.kitchenDustbinBasicLoader = true
        },
        [fetchKitchenDustbinBasicList.rejected]:(state,action)=>{
            state.kitchenDustbinBasicError = "refresh the page"
        },
    }
});

export default kitchenDustbinBasicReducer.reducer