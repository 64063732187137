import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";

const  initialState={
    otherWorkThingsData:[],
    otherWorkThingsLoader:true,
    otherWorkThingsError:"",
}

export const fetchOtherWorksList = createAsyncThunk('fetchotherworkThings',
async()=>{
    const projectList = await db
    .collection("OtherWorks").orderBy('timestamp', 'asc')
    // .where("status", "==", "Ongoing")
    // .where("ID", "==", auth.currentUser.uid)
    .get();
  const projectListArray = [];
  for (var snap of projectList.docs) {
    var data = snap.data();
    data.ID = snap.id;
    projectListArray.push({
      ...data,
    });
    if (projectListArray.length === projectList.docs.length) {
      return projectListArray
    }
  }
});

const otherWorkReducer = createSlice({
    name:'OtherWorkThings',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchOtherWorksList.fulfilled]:(state,action)=>{
            state.otherWorkThingsData = action.payload
            state.otherWorkThingsLoader = false
        },
        [fetchOtherWorksList.pending]:(state,action)=>{
            state.otherWorkThingsLoader = true
        },
        [fetchOtherWorksList.rejected]:(state,action)=>{
            state.otherWorkThingsError = "Close the tab and start with new tab"
        },
    }
});

export default otherWorkReducer.reducer