import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";

const  initialState={
    accessoriesThingsData:[],
    accessoriesLoader:true,
    accessoriesError:"",
}

export const fetchAccessoriesList = createAsyncThunk('fetchAccessories',
async()=>{
    const projectList = await db
    .collection("Accessories").orderBy('timestamp', 'asc')
    .get();
  const projectListArray = [];
  for (var snap of projectList.docs) {
    var data = snap.data();
    data.ID = snap.id;
    projectListArray.push({
      ...data,
    });
    if (projectListArray.length === projectList.docs.length) {
      return projectListArray
    }
  }
});

const accessoriesReducer = createSlice({
    name:'accessories',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchAccessoriesList.fulfilled]:(state,action)=>{
            state.accessoriesThingsData = action.payload
            state.accessoriesLoader = false
        },
        [fetchAccessoriesList.pending]:(state,action)=>{
            state.accessoriesLoader = true
        },
        [fetchAccessoriesList.rejected]:(state,action)=>{
            state.accessoriesError = "refresh the page"
        },
    }
});

export default accessoriesReducer.reducer