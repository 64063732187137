import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Row, Nav, Container, Tab, Accordion, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
import './PriceList.css';

function PriceList() {
    const customBsPrefix = 'custom-accordion';
    const { kitchenThingsData } = useSelector(
        (state) => state.kitchenThings
      );
      
      const { bedroomThingsData } = useSelector(
        (state) => state.bedroomThings
      );
      
      const { foyerThingsData } = useSelector(
        (state) => state.foyerThings
      );
      
      const { livingThingsData } = useSelector(
        (state) => state.livingThings
      );
      
      const { otherWorkThingsData } = useSelector(
        (state) => state.OtherWorkThings
      );
      
      const { looseThingsData } = useSelector(
        (state) => state.looseThings
      );
      const { accessoriesThingsData } = useSelector(
        (state) => state.accessories
      );
  return (
    <Container fluid>
    <Row>
      <Col className='px-5 pb-5'>
      <img src={require('../assests/sitelogo.png')} alt='Dashboard' className='logoImg'/>

      </Col>
    </Row>
    <Row>
    <Accordion >
    <Accordion.Item eventKey="0">
    <Accordion.Header>Kitchen</Accordion.Header>
        <Accordion.Body>
        {kitchenThingsData.map((item, index) =>(
              <Row style={{display:'flex',flexDirection:'row',marginBottom:'2%',borderBottomWidth:2,borderBottomColor:'whitesmoke',borderBottomStyle:'solid'}}>
              <Col sm={2}><p style={{fontWeight:'bold'}}>{item.name}</p></Col>
              
              {item.material.map((item2, index2) => (
                <Col sm={1}>
                <p className='w-100'>{item2.label}</p>
                <input type='number'  value={item2.value} placeholder='price' className='w-100' disabled/>
              </Col>
              ))}
             
             
             
            </Row>
            ))}
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="1">
    <Accordion.Header>Bedroom</Accordion.Header>
        <Accordion.Body>
        {bedroomThingsData.map((item, index) =>(
              <Row style={{display:'flex',flexDirection:'row',marginBottom:'2%',borderBottomWidth:2,borderBottomColor:'whitesmoke',borderBottomStyle:'solid'}}>
              <Col sm={2}><p style={{fontWeight:'bold'}}>{item.name}</p></Col>
              
              {item.material.map((item2, index2) => (
                <Col sm={1}>
                <p className='w-100'>{item2.label}</p>
                <input type='number'  value={item2.value} placeholder='price' className='w-100' disabled/>
              </Col>
              ))}
             
             
             
            </Row>
            ))}
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="2">
    <Accordion.Header>Foyer</Accordion.Header>
        <Accordion.Body>
        {foyerThingsData.map((item, index) =>(
              <Row style={{display:'flex',flexDirection:'row',marginBottom:'2%',borderBottomWidth:2,borderBottomColor:'whitesmoke',borderBottomStyle:'solid'}}>
              <Col sm={2}><p style={{fontWeight:'bold'}}>{item.name}</p></Col>
              
              {item.material.map((item2, index2) => (
                <Col sm={1}>
                <p className='w-100'>{item2.label}</p>
                <input type='number'  value={item2.value} placeholder='price' className='w-100' disabled/>
              </Col>
              ))}
             
             
             
            </Row>
            ))}
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="3">
    <Accordion.Header>Living Room</Accordion.Header>
        <Accordion.Body>
        {livingThingsData.map((item, index) =>(
              <Row style={{display:'flex',flexDirection:'row',marginBottom:'2%',borderBottomWidth:2,borderBottomColor:'whitesmoke',borderBottomStyle:'solid'}}>
              <Col sm={2}><p style={{fontWeight:'bold'}}>{item.name}</p></Col>
              
              {item.material.map((item2, index2) => (
                <Col sm={1}>
                <p className='w-100'>{item2.label}</p>
                <input type='number'  value={item2.value} placeholder='price' className='w-100' disabled/>
              </Col>
              ))}
             
             
             
            </Row>
            ))}
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="4">
    <Accordion.Header>Other Works</Accordion.Header>
        <Accordion.Body>
        {otherWorkThingsData.map((item, index) =>(
              <Row style={{display:'flex',flexDirection:'row',marginBottom:'2%',borderBottomWidth:2,borderBottomColor:'whitesmoke',borderBottomStyle:'solid'}}>
              <Col sm={2}><p style={{fontWeight:'bold'}}>{item.name}</p></Col>
              
              {item.material.map((item2, index2) => (
                <Col sm={1}>
                <p className='w-100'>{item2.label}</p>
                <input type='number'  value={item2.value} placeholder='price' className='w-100' disabled/>
              </Col>
              ))}
             
             
             
            </Row>
            ))}
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="5">
    <Accordion.Header>Loose Furniture</Accordion.Header>
        <Accordion.Body>
        {looseThingsData.map((item, index) =>(
              <Row style={{display:'flex',flexDirection:'row',marginBottom:'2%',borderBottomWidth:2,borderBottomColor:'whitesmoke',borderBottomStyle:'solid'}}>
              <Col sm={2}><p style={{fontWeight:'bold'}}>{item.name}</p></Col>
              
              {item.material.map((item2, index2) => (
                <Col sm={1}>
                <p className='w-100'>{item2.label}</p>
                <input type='number'  value={item2.value} placeholder='price' className='w-100' disabled/>
              </Col>
              ))}
             
             
             
            </Row>
            ))}
        </Accordion.Body>
    </Accordion.Item>
    <Accordion.Item eventKey="6">
    <Accordion.Header>Accessories</Accordion.Header>
        <Accordion.Body>
        {accessoriesThingsData.map((item, index) =>(
              <Row style={{display:'flex',flexDirection:'row',marginBottom:'2%',borderBottomWidth:2,borderBottomColor:'whitesmoke',borderBottomStyle:'solid'}}>
              <Col sm={2}><p style={{fontWeight:'bold'}}>{item.name}</p></Col>
              
              {item.material.map((item2, index2) => (
                <Col sm={1}>
                <p className='w-100'>{item2.label}</p>
                <input type='number'  value={item2.value} placeholder='price' className='w-100' disabled/>
              </Col>
              ))}
             
             
             
            </Row>
            ))}
        </Accordion.Body>
    </Accordion.Item>
    </Accordion>
      
    </Row>
  </Container>
  )
}

export default PriceList