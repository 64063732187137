import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";

const  initialState={
    kitchenBaseCabinetIMagesData:[],
    kitchenBaseCabinetIMagesLoader:true,
    kitchenBaseCabinetIMagesError:"",
}

export const fetchKitchenBaseCabinetImagesList = createAsyncThunk('fetchKitchenBaseCabinetIMages',
async()=>{
    const projectList = await db
    .collectionGroup("base_cabinet_images")
    // .where("status", "==", "Ongoing")
    // .where("ID", "==", auth.currentUser.uid)
    .get();
  const projectListArray = [];
  for (var snap of projectList.docs) {
    var data = snap.data();
    data.ID = snap.id;
    projectListArray.push({
      ...data,
    });
    if (projectListArray.length === projectList.docs.length) {
      return projectListArray
    }
  }
});

const kitchenBaseCabinetIMagesReducer = createSlice({
    name:'kitchenBaseCabinetIMages',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchKitchenBaseCabinetImagesList.fulfilled]:(state,action)=>{
            state.kitchenBaseCabinetIMagesData = action.payload
            state.kitchenBaseCabinetIMagesLoader = false
        },
        [fetchKitchenBaseCabinetImagesList.pending]:(state,action)=>{
            state.kitchenBaseCabinetIMagesLoader = true
        },
        [fetchKitchenBaseCabinetImagesList.rejected]:(state,action)=>{
            state.kitchenBaseCabinetIMagesError = "refresh the page"
        },
    }
});

export default kitchenBaseCabinetIMagesReducer.reducer