import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from './app/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import { PersistGate } from 'redux-persist/integration/react';
import { fetchKitchenList } from './redux/kitchenReducer';
import { fetchKitchenBaseHandleList } from './redux/kitchenBaseHandleReducer';
import { fetchKitchenThaliBasketList } from './redux/kitchenThaliBasketReducer';
import { fetchKitchenThaliInletList } from './redux/kitchenThaliInletReducer';
import { fetchKitchenDustbinBasicList } from './redux/kitchenDustbinBasicReducer';
import { fetchKitchenWickerBasketBasicList } from './redux/kitchenWickerBasketBasicReducer';
import { fetchKitchenDetergentHolderBasicList } from './redux/kitchenDetergentHolderBasicReducer';
import { fetchKitchenBaseCabinetImagesList } from './redux/kitchenBaseCabinetIMagesReducer';
import { fetchRoomList } from './redux/viewRoomReducer';
import { fetchKitchenThingsList } from './redux/kitchenThingsReducer';
import { fetchLooseThingsList } from './redux/looseFurnitureReducer';
import { fetchOtherWorksList } from './redux/otherWorkReducer';
import { fetchBedroomList } from './redux/bedroomReducer';
import { fetchFoyerThingsList } from './redux/foyerReducer';
import { fetchLivingThingsList } from './redux/livingRoomReducer';
import { fetchAccessoriesList } from './redux/accessoriesReducer.js';

const container = document.getElementById('root');
const root = createRoot(container);
// store.dispatch(fetchKitchenList());
// store.dispatch(fetchKitchenBaseCabinetImagesList());
// store.dispatch(fetchKitchenBaseHandleList());
// store.dispatch(fetchKitchenThaliBasketList());
// store.dispatch(fetchKitchenThaliInletList());
// store.dispatch(fetchKitchenDustbinBasicList());
// store.dispatch(fetchKitchenWickerBasketBasicList());
// store.dispatch(fetchKitchenDetergentHolderBasicList());
store.dispatch(fetchKitchenThingsList());
store.dispatch(fetchLooseThingsList());
store.dispatch(fetchOtherWorksList());
store.dispatch(fetchBedroomList());
store.dispatch(fetchFoyerThingsList());
store.dispatch(fetchLivingThingsList());
store.dispatch(fetchAccessoriesList());
root.render(
 
    <Provider store={store}>
    
    <BrowserRouter>
    <PersistGate persistor={persistor}>
      <App />
      </PersistGate>
      </BrowserRouter>
    </Provider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
