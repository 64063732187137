import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { auth, firestore as db } from "../config/Config";

const  initialState={
    kitchenThingsData:[],
    kitchenThingsLoader:true,
    kitchenThingsError:"",
}

export const fetchKitchenThingsList = createAsyncThunk('fetchkitchenThings',
async()=>{
    const projectList = await db
    .collection("kitchen").orderBy('timestamp', 'asc')
    .get();
  const projectListArray = [];
  for (var snap of projectList.docs) {
    var data = snap.data();
    data.ID = snap.id;
    projectListArray.push({
      ...data,
    });
    if (projectListArray.length === projectList.docs.length) {
      return projectListArray
    }
  }
});

const kitchenThingsReducer = createSlice({
    name:'kitchenThings',
    initialState,
    reducers:{},
    extraReducers:{
        [fetchKitchenThingsList.fulfilled]:(state,action)=>{
            state.kitchenThingsData = action.payload
            state.kitchenThingsLoader = false
        },
        [fetchKitchenThingsList.pending]:(state,action)=>{
            state.kitchenThingsLoader = true
        },
        [fetchKitchenThingsList.rejected]:(state,action)=>{
            state.kitchenThingsError = "refresh the page"
        },
    }
});

export default kitchenThingsReducer.reducer