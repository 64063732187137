import { createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import { firestore as db } from "../config/Config";

const  initialState={
  designName:[],
  designModal:false,
  designError:{}
}

export const renameDesignName = createAsyncThunk('reNameDesign',
async (newRoomName) => {
    // await db.collection("client").doc(newRoomName.cid).collection("Rooms").doc('58zWDrgLW3nEdqrDAbSY').update({
    //     roomTypeName: newRoomName.roomTypeNewName,
    //   });
      // .where('roomTypeName', '==', newRoomName.preRoomTypeName);
      // db.collection("client").doc(newRoomName.cid).collection("Rooms").where('roomTypeName', '==', newRoomName.preRoomTypeName).get().then(response =>{
      //   response.docs.forEach((doc) =>{
          db.collection("client").doc(newRoomName.cid).collection("Rooms").doc(newRoomName.id).update({
            roomTypeDesign: newRoomName.roomTypeNewName,
          });
      //   })
      // })
  });
// update and where query for firebase?
const renameDesignReducer = createSlice({
    name:'reNameDesignWithId',
    initialState,
    reducers:{},
    extraReducers:{
      [renameDesignName.fulfilled]:(state,action)=>{
        state.designModal = false;
      },
      [renameDesignName.pending]:(state,action)=>{
        state.designModal = false;
      },
      [renameDesignName.rejected]:(state,action)=>{
        state.designError = action.payload;
        console.log("error", action.payload);
      },
    }
});

export default renameDesignReducer.reducer