import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/database';

// Your web app's Firebase configuration
const config = {
  apiKey: "AIzaSyCuI6EjBUXZz2mUlpSpdtiyKdoYZx7zt7E",
  authDomain: "fabdiz-4a354.firebaseapp.com",
  projectId: "fabdiz-4a354",
  storageBucket: "fabdiz-4a354.appspot.com",
  messagingSenderId: "815424291234",
  appId: "1:815424291234:web:52528eb1b98180a2b3f2fb",
  measurementId: "G-M98FHCZNEQ"
};


firebase.initializeApp(config);

export const firestore = firebase.firestore();
export const auth = firebase.auth();
// const db = firebase.firestore();
// export const storage = firebase.storage();

// export {auth,db,storage}


export default firebase;