import React, { useEffect, useState } from 'react';
import { useSearchParams, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClientQuotationList } from '../redux/clientQuotationReducer';
import { Card, Container, Row, Col, ListGroup, Tab, Nav, Button, Accordion, Table } from 'react-bootstrap';
import './ClientQuotation.css';
import { auth, firestore as db } from "../config/Config";
import { fetchLinkQuotation } from '../redux/clientLinkReducer';
import { Triangle } from  'react-loader-spinner';


export default function ClientQuotation() {

  const dispatch = useDispatch();
  // const { clientIDD } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const firstID = searchParams.get("cqifds");
  const secondID = searchParams.get("cqid");

  useEffect(()=>{
    const linkData1 = {
      Id:secondID,
      Id2:firstID,
    }
    dispatch(fetchLinkQuotation(linkData1));
  }, []);
  const { linkData, linkLoader, linkError} = useSelector((state)=>state.linkQuote);

  console.log('adasd',linkData);

  return (
    <Container style={{ backgroundColor: '#f8f8f8' }} className='px-4 px-md-5'>
    {linkLoader ? 
     <center><Triangle
                    height="120"
                    width="120"
                    color="#ff5100"
                    ariaLabel="triangle-loading"
                    radius="1"
                    wrapperStyle={{position: "absolute",top: "50%",left: "50%",}}
                    wrapperClass=""
                    visible={true}
                  /> </center>  : 
    <>
      <Row className='py-5'>
        <Col>
          <img src={require('../assests/PDFlogo.png')} className='img-Logo' />
          <p className=" text-muted fw-semibold">You are about to experience creativity and commitment at its best.</p>
        </Col>
      </Row>
      {linkData.map((e)=>(
        e.ID === firstID ? 
        <>
        <Row className=''>
        <Col sm={12} md={4} className=''>
          <Card>
            <Card.Body>
              {/* <Card.Subtitle className="mb-2 text-muted">Hi {e.clientDetails.cname}</Card.Subtitle> */}
              <Card.Subtitle className="mb-2 text-dark">Hi {e.clientDetails['cname']}, welcome to the Fabdiz family!</Card.Subtitle>
              <Card.Text>
                Thank you for taking the time and having a discussion with me. <span style={{ fontWeight: '500' }}>We have taken into account all your requirements and created a quote for you. We believe it will perfectly suit your style and budget.</span>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4}>
          {/* <div>ClientQuotation
         {mainID}
         </div> */}
        </Col>
        <Col sm={12} md={4} style={{ height: 'auto' }} className='mt-4 mt-md-0'>
          <Card>
            <Card.Body>
              <Card.Subtitle className="mb-2 text-dark">{e.userDetials[0].designation}</Card.Subtitle>
              <Card.Text className="text-dark">
                <span style={{ fontWeight: '500' }}> {e.userDetials[0].name} </span> <br /> 
                 <span style={{ fontWeight: '500' }}> {e.userDetials[0].mobile} </span> <br /> 
                <span style={{ fontWeight: '500' }}> {e.userDetials[0].email} </span>

              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>


      <Row className='p-3'>
        <Col sm={12} className='border rounded p-2 bg-light-subtle'>
          <p className='text-center fw-semibold pt-1 fs-3 text-dark'>Rooms</p>
        </Col>
      </Row>
      <Row className='px-3' style={{overflowX:'auto'}}>
      
        <Col sm={12} className='border rounded p-2 bg-light-subtle'>
        <Accordion defaultActiveKey="0">

        {e.quotationData.map((item, index) => (
          <Accordion.Item eventKey={item.ID}>
        <Accordion.Header><b>{item.roomTypeName}</b></Accordion.Header>
        <Accordion.Body >
        <Table responsive>
          <thead>
          <tr className="table-secondary">
            <th>Items / Description</th>
            <th>Finish</th>
            <th>Width (FT)</th>
            <th>Height (FT)</th>
            <th>Area</th>
            <th>Qty</th>
        
            <th>Amount</th>
            <th>Remark</th>
            </tr>
          </thead>
          <tbody>
          {item.title.map((item2, index2) =>(
          item2.value2 > 0 || item2.name == "Wardrobe - Slider" ?
          <tr>
          <td>{item2.name}</td>
          <td>{item2.label}</td>
          <td>{item2.width > 0 ? item2.width : null}</td>
          <td>{item2.height > 0 ? item2.height : null}</td>
          <td>{item2.area > 0 ? item2.area : null}</td>
          <td>{item2.qnty > 0 ? item2.qnty : null}</td>
       
          <td>{item2.price > 0 ? item2.price : null}</td>
          <td>{item2.remark}</td>
          </tr>
          :  item2.price > 0 || item2.name == "Shape Of Kitchen" ?
          <tr>
          <td>{item2.name}</td>
          <td>{item2.label}</td>
          <td>{item2.width > 0 ? item2.width : null}</td>
          <td>{item2.height > 0 ? item2.height : null}</td>
          <td>{item2.area > 0 ? item2.area : null}</td>
          <td>{item2.qnty > 0 ? item2.qnty : null}</td>
        
          <td>{item2.price}</td>
          <td>{item2.remark}</td>
          </tr>
          :  item2.price > 0 ?
          <tr>
          <td>{item2.name}</td>
          <td>{item2.label}</td>
          <td>{item2.width > 0 ? item2.width : null}</td>
          <td>{item2.height > 0 ? item2.height : null}</td>
          <td>{item2.area > 0 ? item2.area : null}</td>
          <td>{item2.qnty > 0 ? item2.qnty : null}</td>
         
          <td>{item2.price}</td>
          <td>{item2.remark}</td>
          </tr>
          :  item2.value > 0 ? 
          <tr>
            <td>{item2.name}</td>
            <td>{item2.label}</td>
          <td>{item2.width > 0 ? item2.width : null}</td>
          <td>{item2.height > 0 ? item2.height : null}</td>
          <td>{item2.area > 0 ? item2.area : null}</td>
          <td>{item2.qnty > 0 ? item2.qnty : null}</td>
         
          <td>{item2.price}</td>
          <td>{item2.remark}</td>
        </tr>
           :  null
        
        ))}
          
          </tbody>
        </Table>
        {/* <Row className='bg-secondary-subtle border pt-2' style={{}}>
          <Col className='col-2'><p>Items / Description</p></Col>
          <Col className='col-2'><p>Finish</p></Col>
          <Col className='col-1 text-center'><p>Width (FT) </p></Col>
          <Col className='col-1 text-center'><p>Height (FT)</p></Col>
          <Col className='col-1 text-center'><p>Area (Sqft/RFT)</p></Col>
          <Col className='col-1 text-center'><p>Qty</p></Col>
          <Col className='col-1 '><p>Price</p></Col>
          <Col className='col-1 '><p>Amount </p></Col>
          <Col className='col-2 '><p>Remark</p></Col>
        </Row>
        {item.title.map((item2, index2) =>(
          item2.value2 > 0 || item2.name == "Wardrobe - Slider" ?
          <Row className='border-bottom pt-2'>
          <Col className='col-2'><p>{item2.name}</p></Col>
          <Col className='col-2'><p>{item2.label}</p></Col>
          <Col className='col-1 text-center'><p>{item2.width > 0 ? item2.width : null}</p></Col>
          <Col className='col-1 text-center'><p>{item2.height > 0 ? item2.height : null}</p></Col>
          <Col className='col-1 text-center'><p>{item2.area > 0 ? item2.area : null}</p></Col>
          <Col className='col-1 text-center'><p>{item2.qnty > 0 ? item2.qnty : null}</p></Col>
          <Col className='col-1 '><p>{item2.valueIncresed}</p></Col>
          <Col className='col-1 '><p>{item2.price > 0 ? item2.price : null}</p></Col>
          <Col className='col-2 '><p>{item2.remark}</p></Col>
        </Row>
          :  item2.price > 0 || item2.name == "Shape Of Kitchen" ?
          <Row className='border-bottom pt-2'>
          <Col className='col-2'><p>{item2.name}</p></Col>
          <Col className='col-2'><p>{item2.label}</p></Col>
          <Col className='col-1 text-center'><p>{item2.width > 0 ? item2.width : null}</p></Col>
          <Col className='col-1 text-center'><p>{item2.height > 0 ? item2.height : null}</p></Col>
          <Col className='col-1 text-center'><p>{item2.area > 0 ? item2.area : null}</p></Col>
          <Col className='col-1 text-center'><p>{item2.qnty > 0 ? item2.qnty : null}</p></Col>
          <Col className='col-1 '><p>1549 </p></Col>
          <Col className='col-1 '><p>{item2.value}</p></Col>
          <Col className='col-2 '><p>{item2.remark}</p></Col>
        </Row>
          :  item2.price > 0 ?
          <Row className='border-bottom pt-2'>
          <Col className='col-2'><p>{item2.name}</p></Col>
          <Col className='col-2'><p>{item2.label}</p></Col>
          <Col className='col-1 text-center'><p>{item2.width > 0 ? item2.width : null}</p></Col>
          <Col className='col-1 text-center'><p>{item2.height > 0 ? item2.height : null}</p></Col>
          <Col className='col-1 text-center'><p>{item2.area > 0 ? item2.area : null}</p></Col>
          <Col className='col-1 text-center'><p>{item2.qnty > 0 ? item2.qnty : null}</p></Col>
          <Col className='col-1 '><p>1549 </p></Col>
          <Col className='col-1 '><p>{item2.value}</p></Col>
          <Col className='col-2 '><p>{item2.remark}</p></Col>
        </Row>
          :  item2.value > 0 ? 
          <Row className='border-bottom pt-2'>
          <Col className='col-2'><p>{item2.name}</p></Col>
          <Col className='col-2'><p>{item2.label}</p></Col>
          <Col className='col-1 text-center'><p>{item2.width > 0 ? item2.width : null}</p></Col>
          <Col className='col-1 text-center'><p>{item2.height > 0 ? item2.height : null}</p></Col>
          <Col className='col-1 text-center'><p>{item2.area > 0 ? item2.area : null}</p></Col>
          <Col className='col-1 text-center'><p>{item2.qnty > 0 ? item2.qnty : null}</p></Col>
          <Col className='col-1 '><p>1549 </p></Col>
          <Col className='col-1 '><p>{item2.value}</p></Col>
          <Col className='col-2 '><p>{item2.remark}</p></Col>
        </Row>
          : null
        
        ))} */}
      
        </Accordion.Body>
      </Accordion.Item>
      )) }
     
      
      
    </Accordion>
        </Col>
      </Row>



      <Row className='p-3'>
        <Col sm={12} className='border rounded p-2 bg-light-subtle'>
          <p className='text-center fw-semibold pt-1 fs-3 text-dark'>Grand Total</p>
        </Col>
      </Row>

      <Row className='px-3'>
        <Col sm={12} md={12} className='border rounded p-4 bg-light-subtle '>



          <Row className=' pt-2'>
            <Col><p className='fw-semibold'>Total</p></Col>
            <Col className='d-flex flex-row-reverse'><p className='fw-semibold'>INR {e.quotationPrice}</p></Col>
          </Row>
          <Row className='border-bottom pb-1'>
            <Col><p className='fw-semibold'>Wood Work</p>

            </Col>
            <Col className='d-flex flex-row-reverse'><p className='fw-semibold'>INR {e.quotationWood}</p></Col>
          </Row>
          <Row className='border-bottom pb-1'>
            <Col><p className='fw-semibold'>Discount ({e.quotationDiscount}%) <br /><span className='text-dark fw-light'>discount provided on all woodworks</span></p>

            </Col>
            <Col className='d-flex flex-row-reverse'><p className='fw-semibold'>INR {Math.round(e.quotationWood * (e.quotationDiscount/100))}</p></Col>
          </Row>
          <Row className='border-bottom pb-1'>
            <Col><p className='fw-semibold'>Discounted Price</p>

            </Col>
            <Col className='d-flex flex-row-reverse'><p className='fw-semibold'>INR {Math.round((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ))}</p></Col>
          </Row>
          <Row className='pt-2'>
            <Col><p className='fw-semibold'>GST (18%)</p></Col>
            <Col className='d-flex flex-row-reverse'><p className='fw-semibold'>INR {Math.round((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100)}</p></Col>
          </Row>
          <Row className='border-top border-bottom py-2'>
            <Col><p className='fw-semibold'>Grand Total (GST)</p></Col>
            <Col className='d-flex flex-row-reverse'><p className='fw-semibold'>INR {Math.round((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)) + ((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100))}</p></Col>
          </Row>
          <Row className='py-2'>

            <Col className='d-flex flex-row-reverse'><p class="fst-italic">*All prices are inclusive of GST</p></Col>
          </Row>
        </Col>

      </Row>

      <Row className='p-3'>
        <Col sm={12} className='border rounded p-2 bg-light-subtle'>
          <p className='text-center fw-semibold pt-1 fs-3 text-dark'>Payment Breakups</p>
        </Col>
      </Row>

      <Row className='px-3'>
        <Col sm={12} md={12} className='border rounded p-4 bg-light-subtle '>



          <Row className=' pt-2'>
            <Col><p className='fw-semibold'>Commitment Advance (10%)</p></Col>
            <Col className='d-flex flex-row-reverse'><p className='fw-semibold'>INR {Math.round(((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)) + ((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100)) * 10/100)}</p></Col>
          </Row>
          <Row className='border-bottom pb-1'>
            <Col><p className='fw-semibold'>Production Advance (40%)</p>

            </Col>
            <Col className='d-flex flex-row-reverse'><p className='fw-semibold'>INR {Math.round(((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)) + ((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100)) * 40/100)}</p></Col>
          </Row>
          <Row className='pt-2'>
            <Col><p className='fw-semibold'>Material Delivery (40%)</p></Col>
            <Col className='d-flex flex-row-reverse'><p className='fw-semibold'>INR {Math.round(((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)) + ((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100)) * 40/100)}</p></Col>
          </Row>
          <Row className='border-top  py-2'>
            <Col><p className='fw-semibold'>On Handover (10%)</p></Col>
            <Col className='d-flex flex-row-reverse'><p className='fw-semibold'>INR {Math.round(((e.quotationPrice  - (e.quotationWood * e.quotationDiscount/100)) + ((e.quotationPrice  - (e.quotationWood * (e.quotationDiscount/100)) ) * 18/100)) * 10/100)}</p></Col>
          </Row>

        </Col>

      </Row>
</>
      : null
            
            )) }
      <Row className='p-3'>
        <Col sm={12} className='border rounded p-2 bg-light-subtle'>
          <p className='text-center fw-semibold pt-1 fs-3 text-dark'>Material Specifications</p>
        </Col>
      </Row>

      <Row className='px-3'>
        <Col sm={12} md={12} className='border rounded p-4 bg-light-subtle '>



          <Row className='border-bottom pt-2'>
            <Col><p className='fw-semibold'>Laminate:</p>
              <p>(Merino, Greenlam, Century, Realtouch, Heritage, Royal Touch). External Matt Laminated Finish 1.0 mm (Any Brand - SF, upto Rs.
                1200 per sheet), unless otherwise specified. Internal Matt finish laminate of 0.79-0.8 mm, Hazel Cambric/off white color, unless and
                otherwise specified.</p>
            </Col>
          </Row>

          <Row className='border-bottom pt-2'>
            <Col><p className='fw-semibold'>PLY:</p>
              <p>Choose between Century Sainik & Prelaminated board FABDIZ make from Indiana. Kitchen, Utility and Washroom - Below Boxes in BWP (Water Proof) Ply - 16 mm (Calibrated). Kitchen above boxes, wardrobe, other units, such as crockery/display/loft unit etc. in MR (Moisture Resistant) Ply - 16 mm (Calibrated).</p>
            </Col>
          </Row>

          <Row className='border-bottom pt-2'>
            <Col><p className='fw-semibold'>Hardware:</p>
              <p>Hafele/Hettich/EBCO Hinges and other hardware (Tandem Box, Channels, Sliders etc) shall be of regular operations (Soft Close on
                Demand). Handles, Knobs, shall be of local supply (with basic rate of Rs. 100/pc 8 inch and Rs, 50/Pc 4 inch) or as per selection .
                Wire-Baskets (Plate, thanli, cutlery or bottle pullout) if any, shall be stainless steel (Local Supply) grade-304. Wicker Basket if any, will
                be local supply as per IS standards and size customizable. Kitchen Skirting shall be a PVC make of approved color and finish with
                PVC adjustable legs. 5mm plain glass for glass shutters, unless otherwise specified with wooden frame. (Aluminium shutters can be
                on demand). 6mm plain glass for Shelves ad per drawings or unless otherwise specified.</p>
            </Col>
          </Row>

          <Row className='border-bottom pt-2'>
            <Col><p className='fw-semibold'>Others:</p>
              <p>SS Shelf pins, Clamps, Screw and other fixtures - local supply. Dowels & Mini-fix - Hettich. Glue - Pedilite (Fevicol), Araldite, Jotun,
                Rehau, woodmaster or equivelent based on application. Edge Banding - Rehau or Equivalent (1mm or 2mm as per application).</p>
            </Col>
          </Row>

          <Row className='pt-2'>
            <Col><p className='fw-semibold'>Paint, Polish & Powdercoat:</p>
              <p>As per selection (Asian, Dulux, Berger, RAL), only if mentioned in quote.</p>
            </Col>
          </Row>


        </Col>

      </Row>


      <Row className='p-3'>
        <Col sm={12} className='border rounded p-2 bg-light-subtle'>
          <p className='text-center fw-semibold pt-1 fs-3 text-dark'>Terms & Conditions</p>
        </Col>
      </Row>

      <Row className='px-3 pb-3'>
        <Col sm={12} md={12} className='border rounded p-4 bg-light-subtle '>



          <Row className='border-bottom pt-2'>
            <Col><p className='fw-semibold'>Warranty :</p>
              <ol>
                <li> We provide 10 years warranty for plywood, This safeguards you against any defect in manufacturing or in installation workmanship
                  under normal usage. Hardware warranty will be apllicable based on the brands chosen For custom work, there is no warranty applicable.
                  For more information about warranty policy we suggest you to ask FABDIZ warranty policy to our Design consultant. <br />  <b> Note: </b>1 year of free servicing for our product</li>

                <li>All accessories, hardware and appliances are covered as per the respective Manufacturers.</li>
              </ol>
            </Col>
          </Row>

          <Row className='border-bottom pt-2'>
            <Col><p className='fw-semibold'>General terms :</p>
              <ol>
                <li>Chimney, Sink, Hob, Gas Piping, Granite along with the labour cost associated are not included in this quotation unless mentioned
                  otherwise.
                </li>
                <li> Light Fittings, Geyser, Bathroom Fittings, Fans and any other electrical fittings are not included in this quotation unless mentioned
                  otherwise.
                </li>
                <li>Any product that is not added in this Quotation has to be assumed as Non Tendered Product, a revised quote will be provided for the
                  same. FABDIZ will only be responsible for work mentioned in the quotation.</li>
                <li>Alteration of the existing electrical wiring/points and plumbing wiring/points will be charged extra and for any civil changes like plumbing
                  and wall chipping we need civil engineers for instructions.</li>
                <li>FABDIZ execution team takes the responsibility of moving debris to Garbage Room of the apartment premises</li>
                <li>FABDIZ execution team takes the responsibility of cleaning floor & interiors before moving out of the site (Deep cleaning not included).</li>
                <li>Sliding Door Track fitting will bring 15-25mm gap between the Sliding Door and loft. As we need that gap during the maintenance. Sliding
                  Door Track fitting will bring 10mm gap between the door overlapping.
                </li>
                <li>Product with glossy finish may have very less unevenness look and feel due to lighting effect</li>
                <li>This quotation will be valid for 1 month.</li>
                
                <li>Final coat of paint should be done after completion of interior work from client side We are unable to avoid handmarks, scratches during
                  installation.</li>
                <li>There will be difference between in handmade furniture and modular furniture. As handmade furniture by carpenters on site with hand
                  tools so as compared to modular work handmade furniture finishing will differ.
                </li>
                <li>Images shown in the 3d are for reference, please refer actual samples for colour selection and texture / wallpapers or any decor items
                  which might be shown only for reference.</li>
                <li>Design alterations will not be considered once the design document is signed off or once FADBIZ get confirmation for design document
                  through mail/electronic media
                </li>
                <li>Production date will start as per the schedule that will be shared.</li>
                <li>Any modifications at site should be informed to the designer and project manager prior to production.</li>
                <li>FABDIZ will not be responsible for products purchased from elsewhere.</li>
               
                <li>The Entertainment Cabinet and other modular cabinets more than 2400MM will be having two cabinets in that case the joint at the top
                  of the cabinet will be visible.</li>
              </ol>
            </Col>
          </Row>

          <Row className='border-bottom pt-2'>
            <Col><p className='fw-semibold'>Design Process :</p>
              <ol>
                <li><strong>Site Visit :</strong> Once booking advance paid Interior Designer will visit the client site with measurement team.</li>
                <li><strong>Discussion Meeting:</strong> Designer will show the modular library, completed project photographs which will match to client requirement.</li>
                <li><strong>Modeling Presentation:</strong> As per client requirement and placement of furniture designer will present modeling. (upto 2 iteration can be
                  done in the 3d model stage post that timelines may change as per additional design changes.</li>
                <li><strong>3D Design Presentation:</strong> will present 3D design which will have the texture selected by client.</li>
                <li><strong>Site Validation:</strong> Validation will be done at site where FABDIZ will execute project. Designer will visit the site with Project Manager for
                  Masking Measurement. Masking measurement will be possible only on cleaned floor.</li>
                
              </ol>
            </Col>
          </Row>

          <Row className='border-bottom pt-2'>
            <Col><p className='fw-semibold'>Payment Process ( Final design quote to be considered ):</p>
              <ol>
                <li>10% appointment investment as a designer (Part of final quote).</li>
                <li>40% of final design quote value, upon design sign off to start production</li>
                <li>40% before the dispach of the material is done at site</li>
                <li>10% - PDC to be given after 90% of work done at site</li>
              </ol>
            </Col>
          </Row>




        </Col>

      </Row>
     
   
   </>   }



    </Container>


  )
}
